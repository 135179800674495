import React from 'react'
import domainName from '../../domainName'
import { useEffect, useState } from 'react'
import { COLORS, FONTS, SIZES } from '../../colorTheme/colorTheme'
import { Container, Row, Col, Button, NavDropdown, Modal, Image } from 'react-bootstrap';
import ReactStarRatings from 'react-star-ratings';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import AWS from 'aws-sdk';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { Tooltip } from "react-tooltip";

const RateAndReview = () => {

  const [t] = useTranslation("global");

  // const accessKey = process.env.REACT_APP_S3_ACCESS_KEY;
  // const secretKey = process.env.REACT_APP_S3_SECRET_ACCESS_KEY;

  // AWS.config.update({
  //   accessKeyId: accessKey,
  //   secretAccessKey: secretKey, // 3rd 
  // });

  // const s3 = new AWS.S3({
  //   params: { Bucket: process.env.REACT_APP_S3_BUCKET },
  //   region: "ap-southeast-1",
  // })

  const location = useLocation();
  const navigate = useNavigate();

  const { token, UserData, UserId } = useSelector((state) => state.login);


  const [item, setItem] = useState();
  const [bookingId, setBookingId] = useState()

  //
  const [profilePicture, setProfilePicture] = useState()
  const [advisorName, setAdvisorName] = useState()
  const [targetUserId, setTargetUserId] = useState()

  const [likes, setLikes] = useState(0)
  const [reviewText, setReviewText] = useState('');
  const [selectedPhoto, setSelectedPhoto] = useState(null);

  // for modal
  const [loading, setLoading] = useState(true);
  const [showRateSuccess, setShowRateSuccess] = useState(false)
  const [showSucessImage, setShowSuccessImage] = useState(false)
  const [modalMessage, setModalMessage] = useState(t('referRewards.Processing for the uploaded content')) //Thank you for your review!

  const [attachmentPhotos, setAttachmentPhotos] = useState([])
  const [attachmentPhotosURL, setAttachmentPhotosURL] = useState([])

  // const handleDeletePhoto = () => {
  //   setSelectedPhoto(null);
  // };

  const handleSubmit = async () => {
    //console.log("selectedPhoto,reviewText,likes", selectedPhoto, reviewText, likes)
    let errors = [];

    if (reviewText == "") {
      errors.push(t("errorPush.- Please leave your comment!"));
    }
    if (likes <= 0) {
      errors.push(t("errorPush.- Please give a star rating!"));
    }

    if (errors.length > 0) {
      alert(errors.join("\n"))
    } else {

      setShowRateSuccess(true)

      if (item == undefined) {

        //console.log('item == undefined ')
        //edit review
        if (selectedPhoto == null || selectedPhoto == undefined || selectedPhoto == "") {

          //console.log("editReview(attachmentPhotos)", attachmentPhotos)
          await editReview(attachmentPhotos)

        } else {

          //console.log('edit review and inserted photo')
          const imageFromS3 = await uploadFile(selectedPhoto)
          //console.log(" const imageFromS3 = await uploadFile(selectedPhoto)", imageFromS3)
          await editReview([imageFromS3])

        }

      } else {

        //post new review
        if (selectedPhoto == null || selectedPhoto == undefined || selectedPhoto == "") {
          await insertReview(undefined)

          //console.log(`await insertReview(selectedPhoto)`, selectedPhoto)
        } else {

          const imageFromS3 = await uploadFile(selectedPhoto)
          //console.log(" const imageFromS3 = await uploadFile(selectedPhoto)", imageFromS3)
          await insertReview(imageFromS3)

        }
      }



    }

  }

  const uploadFile = async (file) => {
    try {
      // Extract the file extension and MIME type
      const fileExtension = file.type.split('/').pop();
      const mimeType = file.type;

      // Create a FormData object
      const formData = new FormData();
      formData.append('files', file); // Append the file
      formData.append('FolderName', 'review'); // Specify the folder name

      // Send the request to the backend API
      const response = await domainName.sendBackendUploadImage(
        `${domainName.debugAPI}/service/commonUploadS3`,
        formData
      );

      if (response && response.ReturnCode === 200 && response.Data.length) {
        // Return the uploaded file path
        return response.Data[0];
      } else {
        throw new Error('Failed to upload file');
      }
    } catch (error) {
      console.error('Error in uploadFile:', error);
      return null;
    }
  };




  // const uploadFileX = async (file) => {
  //   return new Promise((resolve, reject) => {
  //     const fileExtension = file.type.split('/').pop();
  //     //console.log("fileExtension", fileExtension)

  //     const milliseconds = new Date().getTime();
  //     const fileName = `${milliseconds}.${fileExtension}`;

  //     const params = {
  //       region: "ap-southeast-1",
  //       ACL: 'public-read',
  //       Bucket: process.env.REACT_APP_S3_BUCKET,
  //       Key: 'review/' + fileName,
  //       // Key: 'review/' + file.name,
  //       Body: file,
  //       ContentType: file.type,
  //     };
  //     //console.log("s3", s3)

  //     s3.upload(params, (err, data) => {
  //       if (err) {
  //         console.error(t('error.Error uploading picture:'), err);
  //         reject(err);
  //       } else {
  //         //console.log('File uploaded successfully:', data.Location);
  //         resolve(data.Location.split('/').pop());
  //       }
  //     });

  //   });
  // };

  async function editReview(photos) {

    await domainName
      .serverAPICall(
        domainName.debugAPI + "/user/userEditPostReview",
        "POST",
        JSON.stringify({
          "UserId": UserId,
          "TargetUserId": targetUserId,
          "Likes": likes,
          "ReviewMessage": reviewText,
          "ReviewTag": null,
          "Photos": photos
        }),
        // userState.UserId, // user id, wait binglun settle backend
        //userState.latitude, // latitude
        //userState.longitude // longitude
      )
      .then(domainName.handleServerAPICallErrors)
      .then((responseJson => {
        //console.log('/user/userEditPostReview : response : ', responseJson)
        if (responseJson.ReturnCode == 400) {
          alert(responseJson.ReturnDesc)
        } else if (responseJson.ReturnCode == 200) {
          // mean success
          // here to modify modal message
          setShowSuccessImage(true)
          setModalMessage(t('rateAndReview.Thank you for your review !'))
          setLoading(false)

        } else {
          //console.log('/user/userGiveReview -> got error, pls check')
        }
      }))
      .catch((error) => {
        //console.log('/user/userEditPostReview : error : ', error)
      });
  }




  async function insertReview(imageFromS3) {
    await domainName
      .serverAPICall(
        domainName.debugAPI + "/user/userGiveReview",
        "POST",
        JSON.stringify({
          "UserId": UserId,
          // "TargetUserId": '64102a7bf94cfe19c0f55329', // hard code target userid
          "TargetUserId": targetUserId,
          "Likes": likes,
          "ReviewMessage": reviewText,
          "ReviewTag": [],
          "Photos": imageFromS3 ? imageFromS3 : []
        }), //body, no need to pass in body when method is GET
        //userState.UserId, // user id, wait binglun settle backend
        //userState.latitude, // latitude
        //userState.longitude // longitude
      )
      .then(domainName.handleServerAPICallErrors)
      .then((responseJson => {

        //console.log('/user/userGiveReview -> response : ', responseJson)
        if (responseJson.ReturnCode == 400) {
          alert(responseJson.ReturnDesc)
        } else if (responseJson.ReturnCode == 200) {
          // mean success
          // here to modify modal message
          setShowSuccessImage(true)
          setModalMessage(t('rateAndReview.Thank you for your review !'))
          setLoading(false)

        } else {
          //console.log('/user/userGiveReview -> got error, pls check')
        }

      }))
      .catch((error) => {
        //console.log('/user/userGiveReview -> error : ', error)
      });
  }

  async function getBookingDetailsByIdAdvisor(bookingid) {
    await domainName
      .serverAPICall(
        domainName.debugAPI + "/service/exploreUserBookingServiceDetails",
        "POST",
        JSON.stringify({
          "BookingServiceId": bookingId ? bookingId : bookingid,
          "UserRole": "Advisor"
        }),
        // user id, wait binglun settle backend
        //userState.latitude, // latitude
        //userState.longitude // longitude
      )
      .then(domainName.handleServerAPICallErrors)
      .then((responseJson => {
        //console.log("/service/exploreUserBookingServiceDetails response here", responseJson)
        // route.params.bookingid = undefined
        setItem(responseJson.Data)
        setBookingId(responseJson.Data._id)

        setProfilePicture(responseJson.Data.UserData.ImageDisplay)
        setAdvisorName(responseJson.Data.UserData.Name)
        setTargetUserId(responseJson.Data.UserData._id)
      }))
      .catch((error) => {
        //console.log("/service/exploreUserBookingServiceDetails error here", error)
      });
  }

  async function getBookingDetailsById(bookingid) {
    await domainName
      .serverAPICall(
        domainName.debugAPI + "/service/exploreUserBookingServiceDetails",
        "POST",
        JSON.stringify({
          "BookingServiceId": bookingId ? bookingId : bookingid,
          "UserRole": "User"
        }),
        // user id, wait binglun settle backend
        //userState.latitude, // latitude
        //userState.longitude // longitude
      )
      .then(domainName.handleServerAPICallErrors)
      .then((responseJson => {
        //console.log("/service/exploreUserBookingServiceDetails response here", responseJson)
        // route.params.bookingid = undefined
        setItem(responseJson.Data)
        setBookingId(responseJson.Data._id)

        setProfilePicture(responseJson.Data.AdvisorData.ImageDisplay)
        setAdvisorName(responseJson.Data.AdvisorData.Name)
        setTargetUserId(responseJson.Data.AdvisorData._id)
      }))
      .catch((error) => {
        //console.log("/service/exploreUserBookingServiceDetails error here", error)
      });
  }

  function RateSuccessfulModal() {
    return (
      <Modal show={showRateSuccess} backdrop="static" keyboard={false} fade={false}
      // onHide={() => {
      //   setShowRateSuccess(false)
      //   navigate("/mybookings")
      // }}
      >
        <Modal.Header>
          <Modal.Title> </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {
            showSucessImage &&
            <Row style={{ paddingLeft: '2%' }} ><div style={{ textAlign: 'center' }}>  <Image src={require('../../img/icons/successful.png')} width={30} height={30} /></div></Row>
          }
          <Row style={{ paddingLeft: '2%' }} ><h4 style={{ textAlign: 'center' }}>{modalMessage}</h4></Row>
        </Modal.Body>
        <Modal.Footer>
          {/* <Button variant="secondary" onClick={() => setShowRateSuccess(false)}>
            Cancel
          </Button> */}
          <Button onClick={() => {
            setShowRateSuccess(false)
            navigate(`/myReview?tab=1`)
          }} disabled={loading}>
            {loading ? t('referRewards.Loading...') : t('referRewards.View')}
          </Button>
        </Modal.Footer>
      </Modal>
    )
  }


  useEffect(() => {
    // Get the URLSearchParams object from the current URL
    const searchParams = new URLSearchParams(window.location.search);

    // Get the value of the 'serviceid' parameter
    const bookingId = searchParams.get('bookingid');
    const fromscreen = searchParams.get('fromscreen');
    const review = searchParams.get('description');

    // Do something with the serviceId value
    //console.log("bookingId in bookingdetailsuser page", bookingId);
    //console.log("review in bookingdetailsuser page", review);

    if (bookingId) {
      if (fromscreen) {
        //console.log('fromscreen', fromscreen)
        getBookingDetailsByIdAdvisor(bookingId)
      } else {
        getBookingDetailsById(bookingId)
      }

    } else {
      if (review) {
        let queryParams = queryString.parse(location.search);

        setItem(undefined)
        setProfilePicture(queryParams.imageDisplay)
        setAdvisorName(queryParams.name)
        setLikes(parseInt(queryParams.ratingStar))
        setReviewText(queryParams.description)

        setTargetUserId(queryParams.targetUserId)

        //console.log("queryParams", queryParams)
        if (queryParams.attachmentPhotos) {
          setAttachmentPhotos(queryParams.attachmentPhotos)
        }

        if (queryParams.attachmentPhotosURL) {
          setAttachmentPhotosURL([queryParams.attachmentPhotosURL])
        }

      }
    }


  }, [])


  return (
    <div className="greybk-div p-lg-5" style={{ height: 'auto', minHeight: 300 }}>
      <Container>
        <Row className="justify-content-center align-items-center">
          <Col className="col-lg-12">
            <Container className="box-container p-3">
              <Row>
                <Col>
                  <h5 className="fw-bold">{t('rateAndReview.Write Review')}</h5>
                </Col>
              </Row>
              <Row className='mt-2'>
                <Col className="d-flex justify-content-center">
                  <Image src={profilePicture} width={150} height={150} style={{ borderRadius: 100 }} />
                </Col>
              </Row>
              <Row className='mt-3'>
                <Col className="d-flex justify-content-center">
                  <h3>{advisorName}</h3>
                </Col>
              </Row>
              <Row className='mt-1'>
                <Col className="d-flex justify-content-center">
                  <ReactStarRatings
                    rating={likes}  // Set the initial rating value
                    starRatedColor={COLORS.orange}  // Customize the color of the filled-in stars
                    numberOfStars={5}  // Set the total number of stars to display
                    starDimension="40px"  // Set the size of each star
                    starSpacing="1px"  // Set the spacing between each sta
                    changeRating={(rate) => setLikes(rate)}
                  />
                </Col>
              </Row>

              <div className='row mt-5'>
                <span>{t('rateAndReview.Review')}</span>
                <div className='col d-flex justify-content-center'>
                  <textarea
                    className='form-control'
                    value={reviewText}
                    onChange={(e) => setReviewText(e.target.value)}
                    placeholder={t('placeholder.Leave your comment here')}
                    style={{
                      height: 250
                    }}
                  />
                </div>
              </div>

              <Row className='mt-2'>
                <div className='col d-flex align-items-center'>

                  <div>
                    <label htmlFor='photoUpload'>{t('rateAndReview.Photo')}</label>
                    {/* Tooltip Icon */}
                    <span
                        data-tooltip-id="coverListingTooltip"
                        data-tooltip-html={`
                            <div style="text-align: left; padding: 5px; font-weight: bold;">
                                <div>${t("serviceManagement.Tooltip title")}</div>
                                <div>${t("serviceManagement.Tooltip Content 1")} 10MB</div>
                                <div>${t("serviceManagement.Tooltip Content 2")}</div>
                                <div>${t("serviceManagement.Tooltip Content 3")}</div>
                            </div>`}
                        style={{
                            display: "inline-flex",
                            justifyContent: "center",
                            alignItems: "center",
                            width: "16px",
                            height: "16px",
                            backgroundColor: "white",
                            border: "1px solid black",
                            borderRadius: "50%",
                            cursor: "pointer",
                            marginLeft: "5px",
                            marginBottom: "5px"
                        }}
                    >
                        <div
                            style={{
                                width: "15px",
                                height: "15px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                border: "1px solid gray",
                                borderRadius: "50%",
                                backgroundColor: COLORS.white,
                                color: COLORS.gray,
                                fontSize: "12px",
                                fontWeight: "bold",
                            }}
                        >
                            i
                        </div>
                    </span>

                    {/* Tooltip Definition */}
                    <Tooltip
                        id="coverListingTooltip"
                        place="right"
                        style={{
                            backgroundColor: COLORS.white,
                            color: COLORS.black,
                            border: "1px solid white",
                            borderRadius: "5px",
                            boxShadow: "0px 6px 12px rgba(0, 0, 0, 0.2)",
                            padding: "5px",
                            zIndex: 100,
                        }}
                    />
                    <br />
                    <input
                      type='file'
                      id='photoUpload'
                      accept='image/*'
                      onChange={(e) => {
                        const file = e.target.files[0];
                    
                        // Define the max size in bytes (10 MB)
                        const MAX_SIZE = 10 * 1024 * 1024;
                    
                        if (file) {
                          // Check if file size exceeds the limit
                          if (file.size > MAX_SIZE) {
                            alert(
                              t("errorPush.File too large") +
                              "\n" +
                              t("errorPush.File size must be less than") +
                              " 10MB"
                            );
                            // Clear the file input to prevent further processing
                            e.target.value = null;
                            return;
                          }
                    
                          // If the file size is valid, proceed to set it
                          setSelectedPhoto(file);
                          setAttachmentPhotosURL(""); // Reset previous photo URL if any
                        }
                      }}
                      style={{ display: 'none' }}
                    />
                    <button className="btn-white text-dark  fw-bold btn"
                      style={{ borderColor: COLORS.gray }} onClick={() => document.getElementById('photoUpload').click()}>
                      {t('rateAndReview.Upload Photo')}
                    </button>
                  </div>
                </div>
                <div style={{ marginTop: '1%' }}>
                  {selectedPhoto && (
                    <div className='position-relative me-3'>
                      <img
                        src={URL.createObjectURL(selectedPhoto)}
                        alt='Uploaded Photo'
                        style={{ height: 'auto', width: 'auto', maxWidth: '300px', maxHeight: '300px' }}
                      />
                      {/* <button
                        className='position-absolute top-0 end-0 btn btn-outline-danger btn-sm'
                        onClick={handleDeletePhoto}
                      >
                        x
                      </button> */}
                    </div>
                  )}
                  {
                    attachmentPhotosURL.length > 0 &&
                    <Image
                      src={attachmentPhotosURL[0]}
                      style={{
                        width: 100, height: 100
                      }}
                    />
                  }
                </div>
              </Row>

              <Row className='m-1 mt-3'>
                <Button onClick={() => { handleSubmit() }}>{t('rateAndReview.Submit')}</Button>
              </Row>

            </Container>
          </Col>
        </Row>
        <RateSuccessfulModal />
      </Container>
    </div>
  )
}

export default RateAndReview