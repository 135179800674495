import { useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js';
import domainName from '../domainName'
import { useNavigate } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import React, { useState } from 'react';
import loadingGif from '../img/loading.gif';
import { useTranslation } from "react-i18next";


const CheckoutForm = ({ uid, planId, listing, referralCode, isUpdateCard, onCardUpdated, lang }) => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const stripe = useStripe();
  const elements = useElements();

  const [t] = useTranslation("global");

  const handleSubmit = async (event) => {
    setIsLoading(true);
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    // console.log(elements)

    const result = await stripe.confirmSetup({
      elements,
      // confirmParams: {
      //   return_url: "https://dev.adviser2u.com/subscriptionSuccessful",
      // },
      redirect: "if_required",
    });


    if (result.error) {
      setIsLoading(false);
      alert(
        result.error.message
      );
    } else {

      if (result.setupIntent.payment_method) {
        // here call /user/setUserPaymentAccountToDefault

        await domainName
          .serverAPICall(
            domainName.debugAPI + "/user/setUserPaymentAccountToDefault",
            "POST",
            JSON.stringify({
              "UserId": uid,
              "PaymentMethodsId": result.setupIntent.payment_method
            })
          )
          .then(domainName.handleServerAPICallErrors)
          .then((responseJson => {
            console.log('setUserPaymentAccountToDefault response?')
            if (responseJson.ReturnCode === 200) {
              // checkout successful
              console.log('setUserPaymentAccountToDefault ::', responseJson)
            } else {
              console.log('ERROR - setUserPaymentAccountToDefault')
            }

          }))
          .catch((error) => {
            console.log('error1', error)

            alert(
              t("error.Oops!"),
              t("error.An error occurred. Please try again later."),
              error
            );
          });
      }


      if (isUpdateCard == 1) {
        onCardUpdated(true)
      } else {

        await domainName
          .serverAPICall(
            domainName.debugAPI + "/adviser_plan/firstTimeCreateAdvisorWithNoFreeTrial", //This is the first time to spend money to create an advisory account.
            "POST",
            JSON.stringify({
              "UserId": uid,
              "PlanId": planId,
              "TotalPostService": listing,
              "ReferralCode": referralCode == '' ? null : referralCode
            })
          )
          .then(domainName.handleServerAPICallErrors)
          .then((responseJson => {
            console.log('firstTimeCreateAdvisorWithNoFreeTrial response?')
            if (responseJson.ReturnCode === 200) {
              // checkout successful
              navigate(`/subscriptionSuccessful?userId=${uid}&lang=${lang}`);

              // console.log('responseJson3 ::', responseJson.Data)
            } else {
              
              alert(
                t("error.Checkout failed")
              );
            }

          }))
          .catch((error) => {
            console.log('error2', error)
            alert(
              t("error.Oops!"),
              t("error.An error occurred. Please try again later."),
              error
            );
          });
      }

      setIsLoading(false);

    }



  };

  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement />
      <Button
        type="submit"
        className={`orange-btn text-center ${isLoading ? 'loading' : ''}`}
        disabled={!stripe || isLoading}
      >
        {isLoading ? (
          <img src={loadingGif} alt="Loading" style={{ width: '20px' }} />
        ) : (
          isUpdateCard ? 'Update Card' : 'Start your free trial now'
        )}
      </Button>
    </form>

  )
};

export default CheckoutForm;