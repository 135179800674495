import React, { useState, useEffect } from 'react'
import domainName from '../../domainName'
import { useSelector } from 'react-redux';
import './newRequestDetails.css'

import { Container, Row, Col, Image, Button, Modal } from 'react-bootstrap';
import { COLORS } from "../../colorTheme/colorTheme";
import { useNavigate } from 'react-router-dom';
import { SHA256 } from 'crypto-js';
import { useTranslation } from "react-i18next";
import loadingGif from '../../img/loading.gif';


const NewRequestDetails = () => {

    const { token, UserData, UserId, latitude, longitude } = useSelector((state) => state.login);

    const navigate = useNavigate();

    const [isModalVisible, setModalVisible] = useState(false);
    const [isInsufficientModalVisible, setInsufficientModalVisible] = useState(false);

    const [requestId, setRequestId] = useState();
    const [requestType, setRequestType] = useState();
    //data from api
    const [haveRequestData, setHaveRequestData] = useState(false);
    const [requestData, setRequestData] = useState([]);
    const [haveSpentCredit, setHaveSpentCredit] = useState(false);
    const [statusRequest, setStatusRequest] = useState(true);
    const [enoughCredit, setEnoughCredit] = useState(false);
    const [targetUserId, setTargetUserId] = useState(false);

    const [t] = useTranslation("global");

    const statusMapping = {
        ACTIVE: { text: t('NewRequestListings.Active'), backgroundColor: COLORS.lightGreen, textColor: COLORS.green2 },
        DELETE: { text: t('NewRequestListings.Deleted'), backgroundColor: COLORS.lightOrange4, textColor: COLORS.orange2 },
        USER_WITHDRAWAL: { text: t('NewRequestListings.User Withdrawal'), backgroundColor: COLORS.lightOrange4, textColor: COLORS.orange2 },
        EXPIRED: { text: t('NewRequestListings.Expired'), backgroundColor: COLORS.lightGray4, textColor: COLORS.gray4 },
    };

    const currentStatus = statusMapping[requestData?.Status] || {
        text: t('NewRequestListings.Unknown'),
        backgroundColor: COLORS.lightGray4,
        textColor: COLORS.gray4
    };

    async function getRequestDetails(requestid, requesttype) {
        await domainName
            .serverAPICall(
                domainName.debugAPI + "/user_request/getRequestDetailById", //advisorSelectUserRequestDetailsById
                "POST",
                JSON.stringify({
                    "UserId": UserId,
                    "RequestId": requestid ? requestid : requestId,
                    "RequestType": requesttype
                }),
            )
            .then(domainName.handleServerAPICallErrors)
            .then((responseJson => {

                if (responseJson.ReturnCode == 200 && responseJson.Data != null) {
                    setHaveRequestData(true);

                    setRequestData(responseJson.Data)
                    setHaveSpentCredit(responseJson.Data.HaveSpentCredit);
                    setEnoughCredit(responseJson.Data.IsMyCreditEnough);
                    setTargetUserId(responseJson.Data.PosterUserId)
                    if (responseJson.Data?.Status?.toUpperCase() === 'ACTIVE') {
                        setStatusRequest(true);
                    } else {
                        setStatusRequest(false);
                    }

                    console.log('detail', responseJson.Data)
                    console.log(statusRequest)
                } else {
                    alert(responseJson.ReturnDesc)
                }
            }))
            .catch((error) => {
                console.log('/getRequestDetailById : error : ', error)
            });
    }

    function greyLine() {
        return (
            <div
                style={{
                    borderBottom: '1px solid #D8D8D8',
                    marginBottom: '1.8%',
                    marginTop: '1%',
                }}
            />
        );
    }

    useEffect(() => {
        // Get the URLSearchParams object from the current URL
        const searchParams = new URLSearchParams(window.location.search);

        // Get the value of the 'serviceid' parameter
        const reqId = searchParams.get('requestId');
        const reqType = searchParams.get('requestType');

        // Do something with the serviceId value
        // console.log("requestId in advisordetails page", reqId, reqType);
        setRequestId(reqId)
        setRequestType(reqType)
        getRequestDetails(reqId, reqType)
    }, [])

    const handleNavigateToBecomeAnAdvisor = () => {
        navigate(`/BecomeAnAdvisor?uid=${UserId}`)
    };

    async function handleConfirmSpentCredit() {

        await domainName
            .serverAPICall(
                domainName.debugAPI + "/adviser_plan/useCredit",
                "POST",
                JSON.stringify({
                    "UserId": UserId,
                    "RequestType": requestType,
                    "RequestId": requestId
                })
            )
            .then(domainName.handleServerAPICallErrors)
            .then((responseJson => {
                //return data here
                // console.log(" /adviser_plan/useCredit responseJson here oh !", responseJson);
                if (responseJson.ReturnCode == 200) {
                    setModalVisible(false);
                    setHaveSpentCredit(true);
                } else {
                    alert(responseJson.ReturnDesc)
                }
            }))
            .catch((error) => {
                console.log("/adviser_plan/useCredit error here oh !", error);
            });
    }

    return (
        <div className="greybk-div p-lg-5">
            <Container
                style={{ maxWidth: '800px' }}
                className='px-5 py-5 bg-white shadow-lg rounded border-light mx-auto'
            >
                {!haveRequestData ? (
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 'auto' }}>
                        <img src={loadingGif} alt="Loading" style={{ width: '20px' }} />
                    </div>

                ) : haveSpentCredit ? (
                    <div>
                        {/* If haveSpentCredit is true */}
                        <div className="p-3" style={{ backgroundColor: COLORS.lightGrayAccount, borderRadius: 5, flexDirection: 'column' }}>
                            {/* Status Box */}
                            <div
                                style={{
                                    backgroundColor: currentStatus.backgroundColor,
                                    color: currentStatus.textColor,
                                    padding: '5px 10px',
                                    borderRadius: '10px',
                                    textAlign: 'center',
                                    marginBottom: '5px',
                                    display: 'inline-block',
                                }}
                            >
                                <span style={{ fontSize: '0.85em', fontWeight: 'bold' }}>{currentStatus.text}</span>
                            </div>

                            {/* Request Information */}
                            <div style={{ fontSize: '1em', fontWeight: 'bold' }}>
                                {t('NewRequestListings.Request No')} {requestData?.RequestIdNo || 'N/A'}
                            </div>
                            <div style={{ fontSize: '1em', fontWeight: 'bold' }}>
                                {t('NewRequestListings.Request date')} {requestData?.RequestDate || 'N/A'}
                            </div>
                        </div>

                        {/* Request Details */}
                        <div style={{ marginTop: '20px' }}>
                            <div className="feature-item">
                                <img
                                    src={require('../../img/icons/user.png')}
                                    alt="user icon"
                                    className="feature-icon"
                                />
                                {requestData?.PosterName || 'N/A'}
                            </div>
                            <div className="feature-item">
                                <img
                                    src={require('../../img/icons/categories.png')}
                                    alt="category icon"
                                    className="feature-icon"
                                />
                                {requestData?.Category || 'N/A'}
                            </div>
                            <div className="feature-item">
                                <img
                                    src={require('../../img/icons/location.png')}
                                    alt="location icon"
                                    className="feature-icon"
                                />
                                {requestData?.Address || 'N/A'}
                            </div>
                            <div className="feature-item">
                                <img
                                    src={require('../../img/icons/description.png')}
                                    alt="description icon"
                                    className="feature-icon"
                                />
                                {requestData?.Description || 'N/A'}
                            </div>
                        </div>

                        <Row className="mt-2" style={{ marginBottom: '3%', paddingTop: '3%' }}>
                            <Col>
                                <button
                                    type="button"
                                    className="input-group btn"
                                    style={{
                                        backgroundColor: COLORS.white,
                                        color: COLORS.black,
                                        borderWidth: 1,
                                        borderColor: COLORS.black,
                                        fontWeight: 'bold',
                                        fontSize: '1em',
                                        padding: '10px',
                                    }}
                                    onClick={() => {
                                        navigate(`/myProfile?userId=${targetUserId}`)
                                    }}
                                >
                                    {t('NewRequestDetails.View Profile')}
                                </button>
                            </Col>

                            <Col>
                                <button
                                    type="button"
                                    className="input-group btn"
                                    style={{
                                        backgroundColor: COLORS.orange,
                                        color: COLORS.white,
                                        borderWidth: 1,
                                        borderColor: COLORS.orange,
                                        fontWeight: 'bold',
                                        fontSize: '1em',
                                        padding: '10px',
                                    }}
                                    onClick={() => {
                                        let currentTime = new Date().getTime(); //epoch timestamp now
                                        const resultEncrypted = SHA256(UserId + process.env.REACT_APP_COMMUNITY_APP_KEY + "Adviser2u" + currentTime);
                                        navigate(`/message?userId=${UserId}&messageId=${targetUserId}&uid=${resultEncrypted}&tid=${currentTime}`)
                                    }}
                                >
                                    {t('NewRequestDetails.Chat')}
                                </button>
                            </Col>
                        </Row>
                    </div>
                ) : (
                    statusRequest ? (
                        <div>
                            {/* If haveSpentCredit is false */}
                            <div className="p-3" style={{ backgroundColor: COLORS.lightGrayAccount, borderRadius: 5, flexDirection: 'column' }}>
                                {/* Status Box */}
                                <div
                                    style={{
                                        backgroundColor: currentStatus.backgroundColor,
                                        color: currentStatus.textColor,
                                        padding: '5px 10px',
                                        borderRadius: '10px',
                                        textAlign: 'center',
                                        marginBottom: '5px',
                                        display: 'inline-block',
                                    }}
                                >
                                    <span style={{ fontSize: '0.85em', fontWeight: 'bold' }}>{currentStatus.text}</span>
                                </div>

                                {/* Request Information */}
                                <div style={{ fontSize: '1em', fontWeight: 'bold', opacity: 0.8 }}>
                                    {t('NewRequestListings.Request No')} {requestData?.RequestIdNo || 'N/A'}
                                </div>
                                <div style={{ fontSize: '1em', fontWeight: 'bold', opacity: 0.8 }}>
                                    {t('NewRequestListings.Request date')} {requestData?.RequestDate || 'N/A'}
                                </div>
                            </div>

                            {/* Hide Request Details */}
                            <div style={{ marginTop: '20px' }}>
                                <div className="feature-item" style={{ opacity: 0.8 }}>
                                    <img
                                        src={require('../../img/icons/user.png')}
                                        alt="user icon"
                                        className="feature-icon"
                                    />
                                    <span>{requestData?.PosterName || 'N/A'}</span>
                                </div>

                                <div className="feature-item" style={{ opacity: 0.6 }}>
                                    <img
                                        src={require('../../img/icons/categories.png')}
                                        alt="category icon"
                                        className="feature-icon"
                                    />
                                    <span>{requestData?.Category || 'N/A'}</span>
                                </div>

                                <div
                                    className="feature-item"
                                    style={{
                                        opacity: 0.4,
                                        overflow: 'hidden',
                                        whiteSpace: 'nowrap',
                                        textOverflow: 'ellipsis', // Add ellipsis (...) if text overflows
                                    }}
                                >
                                    <img
                                        src={require('../../img/icons/location.png')}
                                        alt="location icon"
                                        className="feature-icon"
                                    />
                                    <span>{requestData?.Address || 'N/A'}</span>
                                </div>
                            </div>


                            {/* Request Details */}
                            <div style={{ marginTop: '35px' }}>
                                <Row className="mb-3">
                                    <Col style={{ fontSize: '1.2em', fontWeight: 'bold' }}>
                                        {t('NewRequestListings.Use 1 Credit to View the Request')} {requestData?.PosterName || 'N/A'}{t('NewRequestListings.From')}
                                    </Col>
                                </Row>
                                <Row className="mb-2">
                                    <Col style={{ fontSize: '1em' }}>
                                        <img
                                            src={require('../../img/icons/check2.png')}
                                            alt="check icon"
                                            style={{
                                                width: '20px',
                                                height: '20px',
                                                marginRight: '8px',
                                            }}
                                        />
                                        {t('NewRequestListings.Request Location')}
                                    </Col>
                                </Row>
                                <Row className="mb-2">
                                    <Col style={{ fontSize: '1em', display: 'flex', alignItems: 'center' }}>
                                        <img
                                            src={require('../../img/icons/check2.png')}
                                            alt="check icon"
                                            style={{
                                                width: '20px',
                                                height: '20px',
                                                marginRight: '8px',
                                            }}
                                        />
                                        {t('NewRequestListings.View1')} {requestData?.PosterName || 'N/A'} {t('NewRequestListings.Profile')}
                                    </Col>
                                </Row>
                                <Row className="mb-5">
                                    <Col style={{ fontSize: '1em' }}>
                                        <img
                                            src={require('../../img/icons/check2.png')}
                                            alt="check icon"
                                            style={{
                                                width: '20px',
                                                height: '20px',
                                                marginRight: '8px',
                                            }}
                                        />
                                        {t('NewRequestListings.Chatting')} {requestData?.PosterName || 'N/A'}{t('NewRequestListings.With')}
                                    </Col>
                                </Row>
                            </div>


                            {/* Unlock Section */}
                            <Row style={{ textAlign: 'center', margin: '0' }}>
                                <Button
                                    style={{
                                        display: 'flex', // Flexbox for layout
                                        alignItems: 'center', // Vertically align icon and text
                                        justifyContent: 'center', // Center icon and text within the button
                                        borderRadius: '5px',
                                        padding: '10px 15px',
                                        fontSize: '1em',
                                        fontWeight: 'bold',
                                        border: 'none',
                                        gap: '10px', // Space between the icon and text
                                    }}
                                    onClick={() => {
                                        if (enoughCredit) {
                                            setModalVisible(true);
                                        } else {
                                            setInsufficientModalVisible(true);
                                        }
                                    }}
                                >
                                    <img
                                        src={require('../../img/icons/unlock.png')}
                                        alt="unlock icon"
                                        style={{
                                            width: '20px', // Adjust icon size
                                            height: '20px', // Maintain aspect ratio
                                        }}
                                    />
                                    {t('NewRequestListings.Unlock Now with 1 Credit')}
                                </Button>
                            </Row>

                            {/* Confirm Modal */}
                            <Modal show={isModalVisible} onHide={() => setModalVisible(false)} centered>
                                <Modal.Body style={{ textAlign: "start", padding: "30px" }}>

                                    <div style={{ fontWeight: "bold", marginBottom: "20px", fontSize: '1.1em', color: COLORS.darkGray }}>
                                        {t('NewRequestListings.Confirm Title')}
                                    </div>

                                    <p style={{ fontSize: "1em", marginBottom: "30px", color: COLORS.darkGray }}>
                                        {t("NewRequestListings.Confirm Message")}
                                    </p>

                                    <div style={{ display: "flex", justifyContent: "flex-end" }}>
                                        <Button
                                            variant="secondary"
                                            onClick={() => setModalVisible(false)}
                                            style={{
                                                backgroundColor: COLORS.white,
                                                color: COLORS.darkGray,
                                                border: "none",
                                                padding: "10px 20px",
                                                fontWeight: "bold",
                                                fontSize: '1em',
                                            }}
                                        >
                                            {t("NewRequestListings.Cancel")}
                                        </Button>
                                        <Button
                                            variant="primary"
                                            onClick={() => {
                                                handleConfirmSpentCredit()
                                            }}
                                            style={{
                                                backgroundColor: COLORS.orange,
                                                color: COLORS.white,
                                                border: "none",
                                                padding: "10px 20px",
                                                fontWeight: "bold",
                                                fontSize: '1em',
                                            }}
                                        >
                                            {t("NewRequestListings.Confirm")}
                                        </Button>
                                    </div>
                                </Modal.Body>
                            </Modal>

                            {/* Insufficient Credit Modal */}
                            <Modal show={isInsufficientModalVisible} onHide={() => setInsufficientModalVisible(false)} centered>
                                <Modal.Body style={{ textAlign: "start", padding: "30px" }}>

                                    <div style={{ fontWeight: "bold", marginBottom: "20px", fontSize: '1.1em', color: COLORS.darkGray }}>
                                        {t('NewRequestListings.Insufficient Credit Title')}
                                    </div>

                                    <p style={{ fontSize: "1em", marginBottom: "30px", color: COLORS.darkGray }}>
                                        {t("NewRequestListings.Insufficient Credit Message")}
                                    </p>

                                    <div style={{ display: "flex", justifyContent: "flex-end" }}>
                                        <Button
                                            variant="secondary"
                                            onClick={() => setInsufficientModalVisible(false)}
                                            style={{
                                                backgroundColor: COLORS.white,
                                                color: COLORS.darkGray,
                                                border: "none",
                                                padding: "10px 20px",
                                                fontWeight: "bold",
                                                fontSize: '1em',
                                            }}
                                        >
                                            {t("NewRequestListings.Cancel")}
                                        </Button>
                                        <Button
                                            variant="primary"
                                            onClick={handleNavigateToBecomeAnAdvisor}
                                            style={{
                                                backgroundColor: COLORS.orange,
                                                color: COLORS.white,
                                                border: "none",
                                                padding: "10px 20px",
                                                fontWeight: "bold",
                                                fontSize: '1em',
                                            }}
                                        >
                                            {t("NewRequestListings.Upgrade Now")}
                                        </Button>
                                    </div>
                                </Modal.Body>
                            </Modal>
                        </div>
                    ) : (
                        /* (haveSpentCredit === false && statusRequest === false) */
                        <div style={{ textAlign: 'left' }}>

                            <div style={{
                                fontSize: '1.5em',
                                fontWeight: 'bold',
                                marginBottom: '10px',
                                color: COLORS.darkGray,
                                textAlign: 'left'
                            }}>
                                {t('NewRequestListings.Request Details')}
                            </div>

                            {greyLine()}

                            <div
                                style={{
                                    backgroundColor: COLORS.lightGrayAccount,
                                    padding: '20px',
                                    borderRadius: '5px',
                                    color: COLORS.darkGray,
                                    fontSize: '1.2em',
                                    fontWeight: 'bold',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    textAlign: 'center'
                                }}
                            >
                                {t('NewRequestListings.Request Has Been Deleted')}
                            </div>
                        </div>
                    )
                )}
            </Container>
        </div>
    );

}

export default NewRequestDetails