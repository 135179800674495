import React from 'react'
import domainName from '../../domainName'
import { COLORS, FONTS, SIZES } from '../../colorTheme/colorTheme'
import { Container, Row, Col, Button, Modal, Image, Dropdown } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import queryString from 'query-string';
import { SHA256 } from 'crypto-js';
import { useTranslation } from "react-i18next";
import AdvisorPanel from '../../components/AdvisorPanel';
import ConsumerPanel from '../../components/ConsumerPanel';

const MyListings = () => {

  const smsKey = process.env.REACT_APP_SMS_SECRET_KEY;
  const apiKey = process.env.REACT_APP_API_KEY;
  const advisorState = JSON.parse(localStorage.getItem('advisorState'));

  const navigate = useNavigate();

  const { token, UserData, UserId } = useSelector((state) => state.login);

  const [activeStatus, setActiveStatus] = useState('Inactive')
  const [advisorData, setAdvisorData] = useState()
  const [postedServiceNumber, setPostedServiceNumber] = useState()
  const [postedServicesData, setPostedServicesData] = useState([])
  const [notYetCompletedServiceNumber, setNotYetCompletedServiceNumber] = useState([]);

  // for modal verifying whatsapp 
  const [showVerifyWhatsapp, setShowVerifyWhatsapp] = useState(false)
  const [frontCountryPhoneNumber, setFrontCountryPhoneNumber] = useState('+60');
  const [resendMessage, setResendMessage] = useState(false)
  const [showEdit, setShowEdit] = useState(false)
  const [phoneInputDisabled, setPhoneInputDisabled] = useState(false)
  const [showOtpInput, setShowOtpInput] = useState(false)
  const [numberInput, setNumberInput] = useState('')
  const [whatsappNumber, setWhatsappNumber] = useState('')

  const [t] = useTranslation("global");


  // for country code selector 
  const countryData = [
    { code: '+93', name: 'Afghanistan' },
    { code: '+355', name: 'Albania' },
    { code: '+213', name: 'Algeria' },
    { code: '+1-684', name: 'American Samoa' },
    { code: '+376', name: 'Andorra' },
    { code: '+244', name: 'Angola' },
    { code: '+1-264', name: 'Anguilla' },
    { code: '+672', name: 'Antarctica' },
    { code: '+1-268', name: 'Antigua and Barbuda' },
    { code: '+54', name: 'Argentina' },
    { code: '+374', name: 'Armenia' },
    { code: '+297', name: 'Aruba' },
    { code: '+61', name: 'Australia' },
    { code: '+43', name: 'Austria' },
    { code: '+994', name: 'Azerbaijan' },
    { code: '+1-242', name: 'Bahamas' },
    { code: '+973', name: 'Bahrain' },
    { code: '+880', name: 'Bangladesh' },
    { code: '+1-246', name: 'Barbados' },
    { code: '+375', name: 'Belarus' },
    { code: '+32', name: 'Belgium' },
    { code: '+501', name: 'Belize' },
    { code: '+229', name: 'Benin' },
    { code: '+1-441', name: 'Bermuda' },
    { code: '+975', name: 'Bhutan' },
    { code: '+591', name: 'Bolivia' },
    { code: '+387', name: 'Bosnia and Herzegovina' },
    { code: '+267', name: 'Botswana' },
    { code: '+55', name: 'Brazil' },
    { code: '+246', name: 'British Indian Ocean Territory' },
    { code: '+1-284', name: 'British Virgin Islands' },
    { code: '+673', name: 'Brunei' },
    { code: '+359', name: 'Bulgaria' },
    { code: '+226', name: 'Burkina Faso' },
    { code: '+257', name: 'Burundi' },
    { code: '+855', name: 'Cambodia' },
    { code: '+237', name: 'Cameroon' },
    { code: '+1', name: 'Canada' },
    { code: '+238', name: 'Cape Verde' },
    { code: '+1-345', name: 'Cayman Islands' },
    { code: '+236', name: 'Central African Republic' },
    { code: '+235', name: 'Chad' },
    { code: '+56', name: 'Chile' },
    { code: '+86', name: 'China' },
    { code: '+61', name: 'Christmas Island' },
    { code: '+61', name: 'Cocos Islands' },
    { code: '+57', name: 'Colombia' },
    { code: '+269', name: 'Comoros' },
    { code: '+682', name: 'Cook Islands' },
    { code: '+506', name: 'Costa Rica' },
    { code: '+385', name: 'Croatia' },
    { code: '+53', name: 'Cuba' },
    { code: '+599', name: 'Curacao' },
    { code: '+357', name: 'Cyprus' },
    { code: '+420', name: 'Czech Republic' },
    { code: '+243', name: 'Democratic Republic of the Congo' },
    { code: '+45', name: 'Denmark' },
    { code: '+253', name: 'Djibouti' },
    { code: '+1-767', name: 'Dominica' },
    { code: '+1-809, +1-829, +1-849', name: 'Dominican Republic' },
    { code: '+670', name: 'East Timor' },
    { code: '+593', name: 'Ecuador' },
    { code: '+20', name: 'Egypt' },
    { code: '+503', name: 'El Salvador' },
    { code: '+240', name: 'Equatorial Guinea' },
    { code: '+291', name: 'Eritrea' },
    { code: '+372', name: 'Estonia' },
    { code: '+251', name: 'Ethiopia' },
    { code: '+500', name: 'Falkland Islands' },
    { code: '+298', name: 'Faroe Islands' },
    { code: '+679', name: 'Fiji' },
    { code: '+358', name: 'Finland' },
    { code: '+33', name: 'France' },
    { code: '+689', name: 'French Polynesia' },
    { code: '+241', name: 'Gabon' },
    { code: '+220', name: 'Gambia' },
    { code: '+995', name: 'Georgia' },
    { code: '+49', name: 'Germany' },
    { code: '+233', name: 'Ghana' },
    { code: '+350', name: 'Gibraltar' },
    { code: '+30', name: 'Greece' },
    { code: '+299', name: 'Greenland' },
    { code: '+1-473', name: 'Grenada' },
    { code: '+1-671', name: 'Guam' },
    { code: '+502', name: 'Guatemala' },
    { code: '+44-1481', name: 'Guernsey' },
    { code: '+224', name: 'Guinea' },
    { code: '+245', name: 'Guinea-Bissau' },
    { code: '+592', name: 'Guyana' },
    { code: '+509', name: 'Haiti' },
    { code: '+504', name: 'Honduras' },
    { code: '+852', name: 'Hong Kong' },
    { code: '+36', name: 'Hungary' },
    { code: '+354', name: 'Iceland' },
    { code: '+91', name: 'India' },
    { code: '+62', name: 'Indonesia' },
    { code: '+98', name: 'Iran' },
    { code: '+964', name: 'Iraq' },
    { code: '+353', name: 'Ireland' },
    { code: '+44-1624', name: 'Isle of Man' },
    { code: '+972', name: 'Israel' },
    { code: '+39', name: 'Italy' },
    { code: '+225', name: 'Ivory Coast' },
    { code: '+1-876', name: 'Jamaica' },
    { code: '+81', name: 'Japan' },
    { code: '+44-1534', name: 'Jersey' },
    { code: '+962', name: 'Jordan' },
    { code: '+7', name: 'Kazakhstan' },
    { code: '+254', name: 'Kenya' },
    { code: '+686', name: 'Kiribati' },
    { code: '+383', name: 'Kosovo' },
    { code: '+965', name: 'Kuwait' },
    { code: '+996', name: 'Kyrgyzstan' },
    { code: '+856', name: 'Laos' },
    { code: '+371', name: 'Latvia' },
    { code: '+961', name: 'Lebanon' },
    { code: '+266', name: 'Lesotho' },
    { code: '+231', name: 'Liberia' },
    { code: '+218', name: 'Libya' },
    { code: '+423', name: 'Liechtenstein' },
    { code: '+370', name: 'Lithuania' },
    { code: '+352', name: 'Luxembourg' },
    { code: '+853', name: 'Macao' },
    { code: '+389', name: 'Macedonia' },
    { code: '+261', name: 'Madagascar' },
    { code: '+265', name: 'Malawi' },
    { code: '+60', name: 'Malaysia' },
    { code: '+960', name: 'Maldives' },
    { code: '+223', name: 'Mali' },
    { code: '+356', name: 'Malta' },
    { code: '+692', name: 'Marshall Islands' },
    { code: '+222', name: 'Mauritania' },
    { code: '+230', name: 'Mauritius' },
    { code: '+262', name: 'Mayotte' },
    { code: '+52', name: 'Mexico' },
    { code: '+691', name: 'Micronesia' },
    { code: '+373', name: 'Moldova' },
    { code: '+377', name: 'Monaco' },
    { code: '+976', name: 'Mongolia' },
    { code: '+382', name: 'Montenegro' },
    { code: '+1-664', name: 'Montserrat' },
    { code: '+212', name: 'Morocco' },
    { code: '+258', name: 'Mozambique' },
    { code: '+95', name: 'Myanmar' },
    { code: '+264', name: 'Namibia' },
    { code: '+674', name: 'Nauru' },
    { code: '+977', name: 'Nepal' },
    { code: '+31', name: 'Netherlands' },
    { code: '+599', name: 'Netherlands Antilles' },
    { code: '+687', name: 'New Caledonia' },
    { code: '+64', name: 'New Zealand' },
    { code: '+505', name: 'Nicaragua' },
    { code: '+227', name: 'Niger' },
    { code: '+234', name: 'Nigeria' },
    { code: '+683', name: 'Niue' },
    { code: '+850', name: 'North Korea' },
    { code: '+1-670', name: 'Northern Mariana Islands' },
    { code: '+47', name: 'Norway' },
    { code: '+968', name: 'Oman' },
    { code: '+92', name: 'Pakistan' },
    { code: '+680', name: 'Palau' },
    { code: '+970', name: 'Palestine' },
    { code: '+507', name: 'Panama' },
    { code: '+675', name: 'Papua New Guinea' },
    { code: '+595', name: 'Paraguay' },
    { code: '+51', name: 'Peru' },
    { code: '+63', name: 'Philippines' },
    { code: '+870', name: 'Pitcairn' },
    { code: '+48', name: 'Poland' },
    { code: '+351', name: 'Portugal' },
    { code: '+1-787, +1-939', name: 'Puerto Rico' },
    { code: '+974', name: 'Qatar' },
    { code: '+242', name: 'Republic of the Congo' },
    { code: '+262', name: 'Reunion' },
    { code: '+40', name: 'Romania' },
    { code: '+7', name: 'Russia' },
    { code: '+250', name: 'Rwanda' },
    { code: '+590', name: 'Saint Barthelemy' },
    { code: '+290', name: 'Saint Helena' },
    { code: '+1-869', name: 'Saint Kitts and Nevis' },
    { code: '+1-758', name: 'Saint Lucia' },
    { code: '+590', name: 'Saint Martin' },
    { code: '+508', name: 'Saint Pierre and Miquelon' },
    { code: '+1-784', name: 'Saint Vincent and the Grenadines' },
    { code: '+685', name: 'Samoa' },
    { code: '+378', name: 'San Marino' },
    { code: '+239', name: 'Sao Tome and Principe' },
    { code: '+966', name: 'Saudi Arabia' },
    { code: '+221', name: 'Senegal' },
    { code: '+381', name: 'Serbia' },
    { code: '+248', name: 'Seychelles' },
    { code: '+232', name: 'Sierra Leone' },
    { code: '+65', name: 'Singapore' },
    { code: '+1-721', name: 'Sint Maarten' },
    { code: '+421', name: 'Slovakia' },
    { code: '+386', name: 'Slovenia' },
    { code: '+677', name: 'Solomon Islands' },
    { code: '+252', name: 'Somalia' },
    { code: '+27', name: 'South Africa' },
    { code: '+82', name: 'South Korea' },
    { code: '+211', name: 'South Sudan' },
    { code: '+34', name: 'Spain' },
    { code: '+94', name: 'Sri Lanka' },
    { code: '+249', name: 'Sudan' },
    { code: '+597', name: 'Suriname' },
    { code: '+47', name: 'Svalbard and Jan Mayen' },
    { code: '+268', name: 'Swaziland' },
    { code: '+46', name: 'Sweden' },
    { code: '+41', name: 'Switzerland' },
    { code: '+963', name: 'Syria' },
    { code: '+886', name: 'Taiwan' },
    { code: '+992', name: 'Tajikistan' },
    { code: '+255', name: 'Tanzania' },
    { code: '+66', name: 'Thailand' },
    { code: '+228', name: 'Togo' },
    { code: '+690', name: 'Tokelau' },
    { code: '+676', name: 'Tonga' },
    { code: '+1-868', name: 'Trinidad and Tobago' },
    { code: '+216', name: 'Tunisia' },
    { code: '+90', name: 'Turkey' },
    { code: '+993', name: 'Turkmenistan' },
    { code: '+1-649', name: 'Turks and Caicos Islands' },
    { code: '+688', name: 'Tuvalu' },
    { code: '+1-340', name: 'U.S. Virgin Islands' },
    { code: '+256', name: 'Uganda' },
    { code: '+380', name: 'Ukraine' },
    { code: '+971', name: 'United Arab Emirates' },
    { code: '+44', name: 'United Kingdom' },
    { code: '+1', name: 'United States' },
    { code: '+598', name: 'Uruguay' },
    { code: '+998', name: 'Uzbekistan' },
    { code: '+678', name: 'Vanuatu' },
    { code: '+379', name: 'Vatican' },
    { code: '+58', name: 'Venezuela' },
    { code: '+84', name: 'Vietnam' },
    { code: '+681', name: 'Wallis and Futuna' },
    { code: '+212', name: 'Western Sahara' },
    { code: '+967', name: 'Yemen' },
    { code: '+260', name: 'Zambia' },
    { code: '+263', name: 'Zimbabwe' },
  ];

  // for otp count down
  const [isRequestingOTP, setIsRequestingOTP] = useState(false);
  const cooldownDuration = 30; // cooldown period in seconds

  async function sendOTPtoVerifyWhatsapp(phoneNumber) {

    const smsid = SHA256(phoneNumber + "Adviser2u" + apiKey + smsKey)

    await domainName
      .serverAPICall(
        domainName.debugAPI + "/user/sendTwilioOtp",
        "POST",
        JSON.stringify({
          "MobileNo": phoneNumber,
          "SMS_ID": smsid.toString()
        })
      )
      .then(domainName.handleServerAPICallErrors)
      .then((responseJson => {
        // console.log('/user/sendTwilioOtp responseJson here', responseJson)

      }))
      .catch((error) => {
        // console.log('/user/sendTwilioOtp error here', error)
      });

  }

  async function updateWhatsappNumberWithAPI() {

    await domainName
      .serverAPICall(
        domainName.debugAPI + "/user/updateUserAccount",
        "POST",
        JSON.stringify({
          "UserId": UserData._id,
          "Name": UserData.Name,
          "Email": UserData.Email,
          "WhatappsMobileNo": whatsappNumber,
          "IsUpdateWhatappsMobileNo": true,
          "MobileNo": UserData.MobileNo,
          "Photos": []
        })
      )
      .then(domainName.handleServerAPICallErrors)
      .then((responseJson => {
        // console.log('/user/updateUserAccount whatsapp number update responseJson here', responseJson)
        if (responseJson.ReturnCode == 200) {
          setShowVerifyWhatsapp(false)
          window.location.reload();
        } else {
          alert(t('An error occurred. Please contact administrator and report this issue.'))
        }
      }))
      .catch((error) => {
        // console.log('/user/updateUserAccount whatsapp number update error here', error)
      });
  }

  async function getAdvisorServiceInfo() {
    await domainName
      .serverAPICall(
        domainName.debugAPI + "/user/findAdvisorService",
        "POST",
        JSON.stringify({
          "UserId": UserId
          // "UserId": "64128aa13777c7fbb49a4c57"
        }),
      )
      .then(domainName.handleServerAPICallErrors)
      .then((responseJson => {
        // console.log('/user/findAdvisorService : response : ', responseJson.Data.ServiceData)
        setPostedServiceNumber(responseJson.Data.TotalPost)
        setPostedServicesData(responseJson.Data.ServiceData)
      }))
      .catch((error) => {
        // console.log('/user/findAdvisorService : error : ', error)
      });
  }

  async function checkAdvisorActive() {

    await domainName
      .serverAPICall(
        domainName.debugAPI + "/adviser_plan/checkAdviserIsExpiredAndPostService",
        "POST",
        JSON.stringify({
          "UserId": UserId,
          // "UserId": "6406ac6ad3ab42d2395e625b",
        }),
        // userState.UserId,
        // userState.latitude, // latitude
        // userState.longitude // longitude
      )
      .then(domainName.handleServerAPICallErrors)
      .then((responseJson => {

        // console.log('/adviser_plan/checkAdviserIsExpiredAndPostService -> response : ', responseJson)
        setActiveStatus(responseJson.IsSubscribed.DisplayStatus)
        setAdvisorData(responseJson.Data)

      }))
      .catch((error) => {
        // console.log('/adviser_plan/checkAdviserIsExpiredAndPostService -> error : ', error)
      });

  }

  function greyLine() {
    return (
      <div
        style={{
          borderBottom: '1px solid #D8D8D8',
          marginBottom: '1.8%',
          marginTop: '1%',
        }}
      />
    );
  }

  function renderListingSection(item, onpress) {
    return (
      <div className='container'>
        <div className='row mt-2'>
          <div className='col-md-2 col-xs-3'
            style={{
              position: 'relative',
              height: '120px',
              borderRadius: '10px',
              backgroundColor: COLORS.black,
              marginBottom: '1em'
            }}
          >
            <div
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                height: '100%',
                backgroundImage: `url(${item?.CoverImageDisplay != undefined
                  ? item.CoverImageDisplay
                  : require('../../img/icons/noimage.png')
                  })`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                borderRadius: '8px',
                opacity: item != undefined ? (item.isDisplayListed == true ? '0.9' : '0.3') : '',
              }}
            />
            <p
              style={{
                position: 'absolute',
                top: '40%',
                left: '0',
                right: '0',
                textAlign: 'center',
                color: COLORS.white,
                fontWeight: 'bold',
                flexWrap: 'wrap',
              }}
            >
              {item != undefined ? (item.isDisplayListed == true ? '' : item?.TitleStatusDisplay) : ''}
            </p>
          </div>



          <div className='col-md-10 col-xs-9'>
            <h4 style={{ color: COLORS.black, fontWeight: 'bold', marginBottom: '1%', width: '80%' }}>
              {item?.ServiceName != undefined ? item?.ServiceName : t('MyListings.Untitled')}
            </h4>
            <div style={{ flexDirection: 'row', flexWrap: 'wrap' }}>
              <p style={{ color: COLORS.gray, marginBottom: '1%', width: '80%' }}>
                {t('MyListings.Category')}:{' '}
                {item?.CategoryDisplayList != undefined
                  ? item.CategoryDisplayList.map((category, index) => (
                    <span style={{ color: COLORS.gray, flexWrap: 'wrap' }}>
                      {category.CategoryName}
                      {index + 1 === item.CategoryDisplayList.length ? '' : ','}{' '}
                    </span>
                  ))
                  : '-'}
              </p>
            </div>
            <p style={{ color: COLORS.gray }}>{t('MyListings.Status')}: {item?.StatusDisplay != undefined ? item.StatusDisplay : '-'}</p>
          </div>
        </div>



        <div className='row mt-2'>
          <button
            style={{
              borderColor: COLORS.lightGray1,
              backgroundColor: COLORS.white,
              borderWidth: '1px',
              borderStyle: 'solid',
              alignItems: 'center',
              width: '100%',
              padding: '10px',
              borderRadius: '5px'
            }}
            onClick={onpress}
          >
            {item != undefined ? t('MyListings.Edit') : t('MyListings.ClickToComplete')}
          </button>
        </div>

        <div className='row mt-3'>
          {greyLine()}
        </div>


      </div>
    );
  }

  function VerifyWhatsappModal() {
    return (
      <Modal show={showVerifyWhatsapp} backdrop="static" keyboard={false} fade={false}>
        <Modal.Header>
          <Modal.Title>{t('MyListings.Confirm your whatsapp')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row style={{ paddingLeft: '2%' }}>{t('MyListings.Whatsapp No')} * </Row>
          <Row>
            <Col className="col-3">
              <Dropdown className="mt-2">
                <Dropdown.Toggle className="form-control" variant="light" id="countryDropdown" style={{ backgroundColor: COLORS.white, borderColor: COLORS.lightGray1 }}>
                  {frontCountryPhoneNumber}
                </Dropdown.Toggle>
                <Dropdown.Menu alignRight>
                  <div style={{ maxHeight: '200px', overflowY: 'auto' }}>
                    {/* <Dropdown.Item onClick={() => setFrontCountryPhoneNumber("+60")}>+60</Dropdown.Item>
                                                                <Dropdown.Item onClick={() => setFrontCountryPhoneNumber("+65")}>+65</Dropdown.Item> */}
                    {countryData.map((country) => (
                      <Dropdown.Item key={country.code} onClick={() => setFrontCountryPhoneNumber(country.code)}>
                        {country.name} ({country.code})
                      </Dropdown.Item>
                    ))}
                  </div>
                </Dropdown.Menu>
              </Dropdown>
            </Col>
            <Col className="col-6">
              <div className="input-group mt-2">
                <input
                  className="form-control"
                  type="tel"
                  id="numberInput"
                  name="numberInput"
                  placeholder={numberInput}
                  onKeyPress={(e) => {
                    if (!/[0-9]/.test(e.key)) {
                      e.preventDefault();
                    }
                  }}
                  disabled={phoneInputDisabled}
                />
              </div>
            </Col>


            {
              showEdit
                ?
                <Col className="col-2">
                  <Button className="mt-2" onClick={() => {
                    setResendMessage(false)

                    setShowEdit(false)
                    setPhoneInputDisabled(false)
                    setShowOtpInput(false)


                  }}>{t('MyListings.Edit')}</Button>
                </Col>
                :
                <Col className="col-3">
                  <Button className="mt-2" onClick={() => {

                    const inputValue = document.querySelector('input[name="numberInput"]').value;

                    if (inputValue == "") {
                      alert(t('error.Whatsapp number cannot be emtpy !'))
                    } else {

                      if (!isRequestingOTP) {
                        setIsRequestingOTP(true);
                        // Proceed with OTP request
                        setResendMessage(true)
                        setWhatsappNumber(`${frontCountryPhoneNumber}${inputValue}`)
                        setNumberInput(inputValue)
                        setShowEdit(true)
                        setPhoneInputDisabled(true)
                        setShowOtpInput(true)
                        sendOTPtoVerifyWhatsapp(`${frontCountryPhoneNumber}${inputValue}`)
                        // Start cooldown timer

                        setTimeout(() => {
                          setIsRequestingOTP(false);
                        }, cooldownDuration * 1000);

                      } else {
                        alert(t('error.Oops! Please wait 30 seconds before requesting OTP again.'))
                      }

                      // setResendMessage(true)
                      // setWhatsappNumber(`${frontCountryPhoneNumber}${inputValue}`)
                      // setNumberInput(inputValue)
                      // setShowEdit(true)
                      // setPhoneInputDisabled(true)
                      // setShowOtpInput(true)
                      // sendOTPtoVerifyWhatsapp(`${frontCountryPhoneNumber}${inputValue}`)
                    }

                  }}>{t('MyListings.Get otp')}</Button>
                </Col>

            }



          </Row>
          {
            resendMessage &&
            <Row style={{ paddingLeft: '2%' }}>
              {t('MyListings.Enter your otp here')}
            </Row>
          }

          {
            showOtpInput &&
            <Row>
              <div className="input-group mt-2">
                <input
                  className="form-control"
                  type="tel"
                  id="otpInput"
                  name="otpInput"
                  onKeyPress={(e) => {
                    if (!/[0-9]/.test(e.key)) {
                      e.preventDefault();
                    }
                  }}
                />
              </div>
            </Row>
          }



        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => {
            setShowVerifyWhatsapp(false)
            // dispatch(switchToUser())
          }}
          >
            {t('MyListings.Cancel')}
          </Button>
          <Button variant="primary" onClick={async () => {
            const otpInputValue = document.querySelector('input[name="otpInput"]').value;

            // console.log(JSON.stringify({
            //     "MobileNo": whatsappNumber,
            //     "OTP": otpInputValue
            // }))

            await domainName
              .serverAPICall(
                domainName.debugAPI + "/user/verifyVerificationTwilioOtp",
                "POST",
                JSON.stringify({
                  "MobileNo": whatsappNumber,
                  "OTP": otpInputValue
                })
              )
              .then(domainName.handleServerAPICallErrors)
              .then((responseJson => {
                // console.log('/user/verifyVerificationTwilioOtp responseJson here', responseJson)

                if (responseJson.ReturnCode == 200) {
                  updateWhatsappNumberWithAPI()
                } else {
                  alert(responseJson.ReturnDesc);
                }

              }))
              .catch((error) => {
                // console.log('/user/verifyVerificationTwilioOtp error here', error)
              });


          }}
          //disabled={loading}
          >
            {t('MyListings.Confirm')}
          </Button>
        </Modal.Footer>
      </Modal>
    )
  }

  useEffect(() => {
    getAdvisorServiceInfo();
    checkAdvisorActive();
  }, [])

  useEffect(() => {
    const result = parseInt(advisorData?.SubscribeHistory[0]?.TotalPostService) - parseInt(postedServiceNumber)
    setNotYetCompletedServiceNumber(Array.from({ length: result }, (_, index) => index + 1))
  }, [advisorData, postedServiceNumber])

  return (
    <Container fluid style={{ backgroundColor: COLORS.lightGray4, padding: "20px" }}>
      <Row className="justify-content-center">
        {/* Left Panel */}
        <Col xs={12} lg={3} style={{ paddingTop: "20px" }} className="d-none d-lg-block" >
          <div
            style={{
              backgroundColor: COLORS.white,
              borderRadius: "8px",
              padding: "20px",
              boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
              minHeight: "80vh",
            }}
          >
            {advisorState.advisor == true && (
              <AdvisorPanel
                userId={UserId}
                activePage="myListings"
              />
            )}
            {advisorState.advisor == false && (
              <ConsumerPanel
                userId={UserId}
                activePage="myListings"
              />
              // <div
              //     style={{
              //         display: "flex",
              //         justifyContent: "center", 
              //         alignItems: "center",   
              //         height: "80vh",      
              //         textAlign: "center",   
              //     }}
              // >
              //     {t("subscriptionStepOne.Sign up now to become an Adviser!")}
              // </div>
            )}
          </div>
        </Col>

        {/* Right Panel */}
        <Col xs={12} lg={8} style={{ paddingTop: "20px" }}>
          <div
            style={{
              backgroundColor: COLORS.white,
              borderRadius: "5px",
              boxShadow: "0 2px 5px rgba(0,0,0,0.1)",
              minHeight: "90vh",
              height: 'auto',
              paddingTop: "5px",
              paddingLeft: "15px",
              paddingRight: "15px"
            }}
          >
            <Row>
              <Col className="p-2">
                <div style={{ paddingLeft: "10px", paddingTop: "15px", paddingBottom: "10px", fontSize: "1.2em", fontWeight: 'bold' }}>{t('MyListings.My Listings')}</div>
              </Col>
            </Row>

            <Row style={{ paddingLeft: "1em", paddingRight: "1em" }}>
              <Col className="p-3" style={{ backgroundColor: COLORS.lightOrange }}>
                {t('MyListings.You can add up to')} {advisorData?.SubscribeHistory[0]?.TotalPostService} {t('MyListings.listings. Change your plan if you wish to add more.')}
                <span style={{ color: COLORS.orange, cursor: 'pointer', paddingLeft:'0.5em'}} onClick={() => navigate(`/BecomeAnAdvisor?uid=` + (UserData && UserData._id ? UserData._id : ''))} >
                  {t("MyListings.Change plan")}
                </span>
              </Col>
            </Row>


            <Row className='mt-2 p-3'>
              {postedServicesData.map((item, index) => (
                renderListingSection(item, async () => {

                  await domainName
                    .serverAPICall(
                      domainName.debugAPI + `/adviser_plan/selectAdviserPlanDetailsByUserId?UserId=${UserId}`,
                      "GET",
                      // JSON.stringify({
                      //     "MobileNo": phoneNumber,
                      // })
                    )
                    .then(domainName.handleServerAPICallErrors)
                    .then((responseJson => {
                      console.log('/adviser_plan/selectAdviserPlanDetailsByUserId  responseJson.Data here', responseJson.Data)
                      if (responseJson.Data.WhatappsMobileNo == null || responseJson.Data.WhatappsMobileNo == undefined || responseJson.Data.WhatappsMobileNo == "") {
                        setShowVerifyWhatsapp(true)
                      } else {
                        navigate(`/manageservice?${queryString.stringify({
                          service: JSON.stringify(item)
                        })}`)
                      }
                    }))
                    .catch((error) => {
                      console.log('got error meh?');
                    });

                })
              ))}
            </Row>
            <Row className='mt-2 p-3'>
              {notYetCompletedServiceNumber.map((item, index) => (
                // <Text key={index} style={{color:COLORS.black}}>{`Item ${index + 1}`}</Text>
                renderListingSection(undefined, async () => {

                  await domainName
                    .serverAPICall(
                      domainName.debugAPI + `/adviser_plan/selectAdviserPlanDetailsByUserId?UserId=${UserId}`,
                      "GET",
                      // JSON.stringify({
                      //     "MobileNo": phoneNumber,
                      // })
                    )
                    .then(domainName.handleServerAPICallErrors)
                    .then((responseJson => {
                      console.log('/adviser_plan/selectAdviserPlanDetailsByUserId  responseJson.Data here', responseJson.Data)

                      if (!responseJson.Data.WhatappsMobileNo || responseJson.Data.WhatappsMobileNo == null || responseJson.Data.WhatappsMobileNo == undefined || responseJson.Data.WhatappsMobileNo == "") {
                        setShowVerifyWhatsapp(true)
                        console.log('GOT DO HERE??');
                      } else {
                        navigate(`/manageservice`)
                      }
                    }))
                    .catch((error) => {
                    });
                })
              ))}
            </Row>
            {/* hardcode for pop out insert service */}
            {/* {renderListingSection(undefined, () => { navigate(`/manageservice`) })} */}
          </div>
        </Col>
      </Row>
      <VerifyWhatsappModal />
    </Container>
  );
}

export default MyListings