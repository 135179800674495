import React, { useEffect, useState } from 'react'
import { Link, json, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import domainName from '../domainName';
import { Container, Row, Col, Image, Button, Card, Modal } from 'react-bootstrap';
import { COLORS, FONTS, SIZES } from '../colorTheme/colorTheme';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from "react-i18next";

const PostRequest = () => {

    const [t] = useTranslation("global");

    const navigate = useNavigate();

    const { token, UserData, UserId } = useSelector((state) => state.login);

    //categories
    const [categories, setCategories] = useState([]);
    const [categoryName, setCategoryName] = useState()
    const [SubCategoryList, setSubcategoryList] = useState([])
    const [selectedCategoryID, setSelectedCategoryID] = useState()
    const [selectedCategoryKey, setSelectedCategoryKey] = useState()
    const [selectedSubCategoryList, setSelectedSubcategoryList] = useState([])

    // for address location
    const [address, setAddress] = useState('');
    const [addressValid, setAddressValid] = useState(false);
    const [locationList, setLocationList] = useState(null)

    const [tab10KM, setTab10KM] = useState(true);
    const [tab20KM, setTab20KM] = useState(false);

    // describe request
    const [describeRequest, setDescribeRequest] = useState('')

    //for modal
    const [showSuccessPostRequest, setShowSuccessPostRequest] = useState(false)
    const [loading, setLoading] = useState(true);

    //for address checking based on IP
    const [countryCodeFromIP, setCountryCodeFromIP] = useState('SG') //'SG' //'MY'

    const handleSelect = async (selectedAddress) => {
        try {
            const results = await geocodeByAddress(selectedAddress);
            const latLng = await getLatLng(results[0]);

            // Extract relevant address components from the results
            const addressComponents = results[0].address_components;
            const addressDetails = {
                Address: selectedAddress,
                UnitNo: null,
                City: null,
                Region: null,
                Country: null,
                Lat: latLng.lat,
                Lng: latLng.lng,
            };

            // Loop through address components to find specific details
            addressComponents.forEach((component) => {
                if (component.types.includes('street_number')) {
                    // Set unit number if available
                    addressDetails.UnitNo = component.long_name;
                } else if (component.types.includes('locality')) {
                    // Set city if available
                    addressDetails.City = component.long_name;
                } else if (component.types.includes('administrative_area_level_1')) {
                    // Set region if available
                    addressDetails.Region = component.long_name;
                } else if (component.types.includes('country')) {
                    // Set country if available
                    addressDetails.Country = component.long_name;
                    addressDetails.CountryCode = component.short_name;
                }
            });



            // Do something with the selected address and its coordinates (latLng)
            //////console.log('Selected Address:', selectedAddress);
            //////console.log('Coordinates:', latLng);
            //////console.log('addressDetails', addressDetails)

            setAddress(selectedAddress)
            setLocationList(addressDetails);

            setAddressValid(true)

        } catch (error) {
            console.error(t('error.Error selecting address:'), error);
        }
    };

    async function handlePostButton() {

        let errors = [];

        if (addressValid == false) {
            errors.push(t("errorPush.- Please re-select address and do not change any words"));
        }

        if (describeRequest == false) {
            errors.push(t("errorPush.- Please leave your describe request message"));
        }

        if (errors.length > 0) {
            alert(errors.join("\n"))
        } else {

            ////console.log(JSON.stringify({
            //     "MaxDistance": tab10KM ? 10 : 20,
            //     "ExtraInfo": describeRequest,
            //     "LocationList": [locationList],
            //     "PosterUserId": UserId,
            //     "CategoryList": [
            //         {
            //             "CategoryKey": selectedCategoryKey,
            //             "CategoryId": selectedCategoryID,
            //             "Sub_Enabled": true,
            //             "SubCategoryList":
            //                 // {
            //                 //     "Sub_CategoryKey": selectedSubcategoryKey,
            //                 //     "Sub_CategoryId": selectedSubcategoryID,
            //                 //     "Sub_Enabled": true
            //                 // }
            //                 selectedSubCategoryList

            //         },

            //     ]
            // }))

            await domainName
                .serverAPICall(
                    domainName.debugAPI + "/user_request/insertUserRequest",
                    "POST",
                    JSON.stringify({
                        "MaxDistance": tab10KM ? 10 : 20,
                        "ExtraInfo": describeRequest,
                        "LocationList": [locationList],
                        "PosterUserId": UserId,
                        "CategoryList": [
                            {
                                "CategoryKey": selectedCategoryKey,
                                "CategoryId": selectedCategoryID,
                                "Sub_Enabled": true,
                                "SubCategoryList":
                                    // {
                                    //     "Sub_CategoryKey": selectedSubcategoryKey,
                                    //     "Sub_CategoryId": selectedSubcategoryID,
                                    //     "Sub_Enabled": true
                                    // }
                                    selectedSubCategoryList
                            },

                        ]
                    })
                )
                .then(domainName.handleServerAPICallErrors)
                .then((responseJson => {
                    //return data here
                    ////console.log("responseJson here oh !", responseJson);
                    if (responseJson.ReturnCode == 200) {
                        //show modal here and bring user to request listing page
                        setShowSuccessPostRequest(true);
                        setLoading(false)
                    } else {
                        console.error("API returned error:", responseJson);
                        alert(t("errorPush.An error occurred while uploading your request. Please try again later."));
                        setLoading(false);
                    }
                }))
                .catch((error) => {
                    //console.log("error here oh !", error);
                    console.error("Network or runtime error:", error);
                    alert(t("errorPush.An unexpected error occurred. Please try again later.") + "\n" + error.message);
                    setLoading(false);
                });
        }

    }


    async function getCategories() {
        await domainName
            .serverAPICall(
                domainName.debugAPI + "/category/listAllCategory",
                "GET"
            )
            .then(domainName.handleServerAPICallErrors)
            .then((responseJson => {
                ////console.log('/category/listAllCategory responseJson.Data : ', responseJson.Data)

                setSelectedCategoryID(responseJson.Data[0]._id)
                setCategoryName(responseJson.Data[0].CategoryName)
                setSubcategoryList(responseJson.Data[0].SubCategoryList)
                // setSelectedSubcategoryID(responseJson.Data[0].SubCategoryList[0]._id)
                // ////console.log("  setSelectedSubcategoryList(responseJson.Data[0].SubCategoryList[0])", responseJson.Data[0].SubCategoryList[0])
                setSelectedSubcategoryList([{
                    "Sub_CategoryKey": responseJson.Data[0].SubCategoryList[0].Sub_CategoryKey,
                    "Sub_CategoryId": responseJson.Data[0].SubCategoryList[0]._id,
                    "Sub_Enabled": responseJson.Data[0].SubCategoryList[0].Sub_Enabled
                }])
                setCategories(responseJson.Data)
            }))
            .catch((error) => {
                ////console.log('/category/listAllCategory error : ', error)
            });
    }

    function renderDescribeRequest() {

        return (
            <div style={{ paddingTop: '5%' }}>
                <h6>
                    <span style={{ color: 'red' }}>*</span> <strong>{t('postRequest.Describe your request')}</strong>
                </h6>
                <div className='col d-flex justify-content-center position-relative'>
                    <textarea
                        className='form-control'
                        value={describeRequest}
                        onChange={(e) => setDescribeRequest(e.target.value)}
                        // placeholder='Leave your comment here'
                        style={{
                            height: 250,
                            resize: 'none', // Disable textarea resizing
                        }}
                        maxLength={200}
                    />
                    <div
                        className='position-absolute bottom-0 end-0 p-3 text-muted'
                        style={{
                            fontSize: '0.8rem',
                        }}
                    >
                        {describeRequest.length}/200
                    </div>
                </div>
            </div>
        )
    }
    
    function renderDistanceSelection() {
        return (
            <div style={{ paddingTop: '5%' }}>
                <h6 style={{ color: COLORS.black }}>
                    <span style={{ color: 'red' }}>*</span> <strong>{t('postRequest.Distance of advisors from your location')}</strong>
                </h6>
                <Row style={{ paddingTop: '2%', backgroundColor: COLORS.white }}>
                    <Col className="d-flex justify-content-center">
                        {/* Button for ≤ 10km */}
                        <button
                            style={{
                                width: '100%',
                                border: tab10KM ? `2px solid ${COLORS.orange}` : `2px solid ${COLORS.lightGray1}`, // Uniform border
                                backgroundColor: tab10KM ? COLORS.lightOrange : COLORS.white,
                                borderRadius: 5,
                                paddingTop: '4%',
                                paddingBottom: '3%',
                                color: tab10KM ? COLORS.orange : 'black'
                            }}
                            onClick={() => {
                                setTab10KM(true);
                                setTab20KM(false);
                            }}
                        >
                            ≤ 10km
                        </button>
                    </Col>
                    <Col className="d-flex justify-content-center">
                        {/* Button for ≤ 20km */}
                        <button
                            style={{
                                width: '100%',
                                border: tab20KM ? `2px solid ${COLORS.orange}` : `2px solid ${COLORS.lightGray1}`, // Uniform border
                                backgroundColor: tab20KM ? COLORS.lightOrange : COLORS.white,
                                borderRadius: 5,
                                paddingTop: '4%',
                                paddingBottom: '3%',
                                color: tab20KM ? COLORS.orange : 'black'
                            }}
                            onClick={() => {
                                setTab10KM(false);
                                setTab20KM(true);
                            }}
                        >
                            ≤ 20km
                        </button>
                    </Col>
                </Row>
            </div>
        )
    }

    function renderLocation() {
        return (
            <div style={{ paddingTop: '5%' }}>
                <h6>
                    <span style={{ color: 'red' }}>*</span> <strong>{t('postRequest.Your location')}</strong>
                </h6>
                <div style={{ position: 'relative' }}>
                    <PlacesAutocomplete
                        value={address}
                        onChange={(a) => {
                            setAddress(a);
                            setAddressValid(false);
                        }}
                        onSelect={handleSelect}
                        searchOptions={{ componentRestrictions: { country: countryCodeFromIP } }} // 'MY' is the country code for Malaysia
                    >
                        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                            <div style={{ position: 'relative' }}>
                                <input
                                    {...getInputProps({
                                        placeholder: t('placeholder.Enter your address...'),
                                        className: 'form-control',
                                    })}
                                    style={{
                                        paddingRight: '32px', // Enough space for the GPS icon
                                    }}
                                />
                                {/* Dropdown suggestions */}
                                <div
                                    style={{
                                        position: 'absolute',
                                        top: '100%', // Below the input
                                        left: '0',
                                        width: '100%',
                                        zIndex: '999', // Ensure it shows above other elements
                                        backgroundColor: 'white',
                                    }}
                                >
                                    {loading && <div>{t('postRequest.Loading...')}</div>}
                                    {suggestions.map((suggestion, index) => {
                                        const { description } = suggestion;
                                        return (
                                            <div
                                                key={index}
                                                {...getSuggestionItemProps(suggestion)}
                                                style={{
                                                    backgroundColor: 'white',
                                                    cursor: 'pointer',
                                                    padding: '5px', // Adjust padding for suggestion items
                                                }}
                                            >
                                                {description}
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        )}
                    </PlacesAutocomplete>
                    <img
                        src={require("../img/icons/gps-icon.png")} // Use your image path
                        style={{
                            position: 'absolute',
                            right: '10px',
                            top: '50%',
                            transform: 'translateY(-50%)', // Center the icon vertically
                            width: '20px',
                            height: '20px',
                            cursor: 'pointer',
                        }}
                        alt="GPS Icon"
                    />
                </div>
            </div>

        );
    }  
    
    function renderSelectionForMultipleSelect(
        name,
        onPress,
        currentSubCategoryID,
        selectedSubCategoryList
    ) {
        const isSelected = selectedSubCategoryList.some(
            (item) => item.Sub_CategoryId === currentSubCategoryID
        );
    
        return (
            <Container className="d-inline-block me-1 mt-1">  
                <Button
                    onClick={onPress}
                    variant="light" // Use 'light' variant for unselected state
                    style={{
                        backgroundColor: isSelected ? '#C0281C1A' : COLORS.lightGray5, // Custom light orange if selected
                        borderRadius: '30px',
                        border: isSelected ? '2px solid #C0281C' : '2px solid transparent',
                        color: isSelected ? COLORS.orange : COLORS.darkGray, // Custom text color for selected
                        padding: '6px 12px', // Padding for button
                        fontSize: '1.10rem', 
                    }}
                >
                    {name}
                </Button>
            </Container>
        );
    }


    function renderSelection(name, onPress, currentCategoryID, selectedID) {
        const isSelected = currentCategoryID === selectedID;

        return (
            <Container className="d-inline-block me-1 mt-1">  
                <Button
                    onClick={onPress}
                    variant="light" // Keep variant light for non-selected state
                    style={{
                        backgroundColor: isSelected ? '#C0281C1A' : COLORS.lightGray5, // Custom light orange if selected
                        borderRadius: '30px',
                        border: isSelected ? '2px solid #C0281C' : '2px solid transparent',
                        color: isSelected ? COLORS.orange : COLORS.darkGray, // Custom text color for selected
                        padding: '6px 12px', // Padding for button
                        fontSize: '1.10rem', 
                    }}
                >
                    {name}
                </Button>
            </Container>
        );
    }

    function renderSubCategories() {
        return (
            <div style={{ paddingTop: '5%' }}>
                <h6 style={{ color: COLORS.black }}>
                    <span style={{ color: 'red' }}>*</span> <strong>{categoryName}</strong>
                </h6>
                <div style={{ paddingTop: '2%', backgroundColor: COLORS.white, display: 'flex', flexWrap: 'wrap'}}>
                    {SubCategoryList.map((item, index) => (
                        <div key={index} style={{
                            marginBottom: '1.5%',
                            marginLeft: '-13px',
                            display: 'flex',
                            alignItems: 'center',
                        }} >
                            {renderSelectionForMultipleSelect(
                                item.Sub_CategoryName,
                                () => {
                                    const containedOrNot = selectedSubCategoryList.some(
                                        (category) => category.Sub_CategoryId === item._id
                                    );

                                    if (containedOrNot) {
                                        const updatedList = selectedSubCategoryList.filter(
                                            (category) => category.Sub_CategoryId !== item._id
                                        );
                                        setSelectedSubcategoryList(updatedList);
                                    } else {
                                        setSelectedSubcategoryList([
                                            {
                                                Sub_CategoryKey: item.Sub_CategoryKey,
                                                Sub_CategoryId: item._id,
                                                Sub_Enabled: item.Sub_Enabled,
                                            },
                                            ...selectedSubCategoryList,
                                        ]);
                                    }
                                },
                                item._id,
                                selectedSubCategoryList
                            )}
                        </div>
                    ))}
                </div>
            </div>
        );
    }

    function renderCategory() {
        return (
            <div style={{ paddingTop: '3%' }}>
                <h6>
                    <span style={{ color: 'red' }}>*</span> <strong>{t('postRequest.What service are you looking for?')}</strong>
                </h6>

                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                    {categories.map((item, index) => (
                        <div
                            key={index}
                            style={{
                                marginBottom: '1.5%',
                                marginLeft: '-13px',
                                display: 'flex',
                                alignItems: 'center',
                            }}
                        >
                            {renderSelection(
                                item.CategoryName,
                                () => {
                                    setSelectedCategoryID(item._id);
                                    setSelectedCategoryKey(item.CategoryKey);
                                    setCategoryName(item.CategoryName);
                                    setSubcategoryList(item.SubCategoryList);
                                    setSelectedSubcategoryList([
                                        {
                                            Sub_CategoryKey: item.SubCategoryList[0].Sub_CategoryKey,
                                            Sub_CategoryId: item.SubCategoryList[0]._id,
                                            Sub_Enabled: item.SubCategoryList[0].Sub_Enabled,
                                        },
                                    ]);
                                },
                                item._id,
                                selectedCategoryID
                            )}
                        </div>
                    ))}
                </div>
            </div>
        );
    }


    function SuccessPostRequestModal() {
        return (
            <Modal show={showSuccessPostRequest} onHide={() => {
                setShowSuccessPostRequest(false)
                navigate("/requestlisting", { state: { activeTab: "request" } })
            }} fade={false}>
                <Modal.Header >
                    <Modal.Title style={{ textAlign: 'center' }}>
                        {t("postRequest.We're finding you advisors. Someone will whatsapp you soon.")}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="d-flex justify-content-center align-items-center">
                    <img
                        src={require("../img/icons/findingAdvisor.png")}
                        alt="listingImage"
                        className="img-fluid"
                        style={{ borderRadius: 20, width: 200, height: 200 }}
                    />
                </Modal.Body>
                <Modal.Footer className="d-flex">
                    <Button
                        variant="primary"
                        onClick={() => {
                            setShowSuccessPostRequest(false);
                            navigate("/requestlisting", { state: { activeTab: "request" } });
                        }}
                        disabled={loading}
                        style={{ width: '100%' }}
                    >
                        {loading ? "Loading..." : "View"}
                    </Button>
                    <Button
                        variant="secondary"
                        onClick={() => {
                            setShowSuccessPostRequest(false);
                            navigate("/");
                        }}
                        disabled={loading}
                        style={{ width: '100%' }}
                    >
                        {t('postRequest.Back to homepage')}
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }


    useEffect(() => {

        const getCountryCode = async () => {

            const geoResponse = await fetch(process.env.REACT_APP_BASE_URL + '/user/getCountry2', {
                headers: { 'Authorization': process.env.REACT_APP_API_KEY }
            });

            const geoData = await geoResponse.json();
            if (geoData.ReturnCode == 200) {
                const country = geoData.GeoData
                setCountryCodeFromIP(country.toUpperCase())
            }else{
                setCountryCodeFromIP('SG') //'SG' //'MY'
            }
        }

        getCategories()
        getCountryCode()
    }, []);

    function greyLine() {
        return (
            <div
                style={{
                    borderBottom: '1px solid #D8D8D8',
                    marginBottom: '1.8%',
                    marginTop: '1%',
                }}
            />
        );
    }

    return (
        <div className='greybk-div p-lg-5'>
            {/* Adjusted Container for Desktop */}
            <Container style={{ width: '55%', maxWidth: '800px' }} className='px-5 py-5 bg-white shadow-lg rounded border-light mx-auto d-none d-lg-block'>
                <h4 className='fw-bold pb-3'>{t('postRequest.Post Request')}</h4>
                {greyLine()}
                {renderCategory()}
                {renderSubCategories()}
                {renderLocation()}
                {renderDistanceSelection()}
                {renderDescribeRequest()}
                <Row className="mx-2 mt-5 mb-5">
                    <Button variant="primary" onClick={handlePostButton} className="btn-lg">
                        {t('postRequest.Post')}
                    </Button>
                </Row>
                <SuccessPostRequestModal />
            </Container>

            {/* Adjusted Container for Mobile */}
            <Container className='px-4 py-4 bg-white shadow-lg rounded border-light mx-auto d-lg-none'>
                <h4 className='fw-bold pb-3'>{t('postRequest.Post Request')}</h4>
                {greyLine()}
                {renderCategory()}
                {renderSubCategories()}
                {renderLocation()}
                {renderDistanceSelection()}
                {renderDescribeRequest()}
                <Row className="mx-1 mt-3 mb-3">
                    <Button variant="primary" onClick={handlePostButton}>
                        {t('postRequest.Post')}
                    </Button>
                </Row>
                <SuccessPostRequestModal />
            </Container>
        </div>
    ) 
}

export default PostRequest