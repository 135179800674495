import React, { useEffect, useState, useRef } from "react";
import { Container, Row, Col, Image, Button } from 'react-bootstrap';
import { useTranslation } from "react-i18next";
import queryString from 'query-string';
import { useLocation } from "react-router-dom";

const Footer = ({ }) => {
    const { search } = useLocation();
    const [isWebsite, setIsWebsite] = useState(window.innerWidth > 768);
    const [t, i18n] = useTranslation("global");

    useEffect(() => {
        let queryParam = queryString.parse(search);
        let _lang = queryParam.lang ? queryParam.lang : localStorage.getItem('selectedValue')
        i18n.changeLanguage(_lang);
    }, [])

    return (<div>
        <footer style={{ backgroundColor: "#F2F2F2" }}>

            <div className="container py-5 px-5 px-md-0" >
                <div className="row my-4">
                    <div className="col-md-4 col-xs-12">
                        <h4 className="footer-title">{t('footer.About Adviser2u')}</h4>
                        <div className="footer-link"><a href="AboutUs">{t('footer.About Us')}</a></div>
                        <div className="footer-link"><a href="Career">{t('footer.Adviser2u Careers')}</a></div>
                        <div className="footer-link"><a href="Terms">{t('navigation.Terms Of Use')}</a></div>
                        <div className="footer-link"><a href="TermsOfService">{t('navigation.Terms Of Service')}</a></div>
                        <div className="footer-link"><a href="PrivacyPolicy">{t('navigation.Privacy Policy')}</a></div>
                        {/* <div className="footer-link"><a href="Policies">Adviser2u Policies</a></div>
                        <div className="footer-link"><a href="PrivacyPolicies">Privacy Policies</a></div>
                        <div className="footer-link"><a href="ForAdvisers">For Advisers</a></div>
                        <div className="footer-link"><a href="ForInvestors">For Investors</a></div>
                        <div className="footer-link"><a href="AmbassadorProgramme">Ambassador Programme</a></div>
                        <div className="footer-link"><a href="Blog">Blog</a></div> */}
                    </div>

                    <div className="col-md-4 col-xs-12 mt-5 mt-md-0">
                        <h4 className="footer-title">{t('footer.Follow Us')}</h4>
                        <div className="footer-link"><a href="https://www.facebook.com/Adviser2u.MY"><Image src={require('../img/icons/fb@2x.png')} alt="" className="footer-social-image" /> {t('footer.Facebook')}</a></div>
                        <div className="footer-link"><a href="https://www.instagram.com/adviser2u.my/"><Image src={require('../img/icons/ig@2x.png')} alt="" className="footer-social-image" /> {t('footer.Instagram')}</a></div>
                        <div className="footer-link"><a href="https://www.linkedin.com/company/adviser2u/"><Image src={require('../img/icons/linkedin@2x.png')} alt="" className="footer-social-image" /> {t('footer.LinkedIn')}</a></div>
                        <div className="footer-link"><a href="https://www.youtube.com/@Official.Adviser2u"><Image src={require('../img/icons/youtube@2x.png')} alt="" className="footer-social-image" /> {t('footer.YouTube')}</a></div>
                        <div className="footer-link"><a href="https://www.tiktok.com/@officialadviser2u"><Image src={require('../img/icons/tiktok@2x.png')} alt="" className="footer-social-image" /> {t('footer.TikTok')}</a></div>
                        <div className="footer-link"><a href="https://twitter.com/Adviser2u_"><Image src={require('../img/icons/twitter@2x.png')} alt="" className="footer-social-image" /> {t('footer.Twitter')}</a></div>
                        <div className="footer-link"><a href="https://www.xiaohongshu.com/user/profile/640a9d11000000001f031cee?xhsshare=CopyLink&appuid=63a354f80000000026007149&apptime=1698824047"><Image src={require('../img/icons/xiaohongshu.png')} alt="" className="footer-social-image" /> </a></div>
                    </div>

                    <div className="col-md-4 col-xs-12 mt-5 mt-md-0">
                        <h4 className="footer-title">{t('footer.Adviser2u App Download')}</h4>
                        <div className="footer-link"><a href="https://apps.apple.com/us/app/adviser2u/id6448983215?itsct=apps_box_badge&amp;itscg=30200" >
                            <img src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83&amp;releaseDate=1697328000" alt="Download on the App Store" style={{ height: "56px" }} />
                        </a></div>
                        <div className="footer-link"><a href="https://play.google.com/store/apps/details?id=com.advisor2uapp&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1" >
                            <img src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png" alt="Download on the Google Play Store" style={{ height: "80px", marginLeft: '-12px' }} /></a></div>
                    </div>
                </div>
            </div>

            <div className="container-fluid p-3" style={{ backgroundColor: "#C0281C" }}>
                <div className="row">
                    <div className="col-12">
                        <div className="text-center">
                            <div style={{ color: "white" }}>{t('footer.Copyright 2023, Adviser2u MY Sdn.Bhd. [202201043105(1488802-T)]. All Rights Reserved')}
                                <br />
                                {t('footer.Country & Region Malaysia | Singapore | Indonesia | Taiwan | Thailand | Philippines')}</div>
                        </div>
                    </div>
                </div>
            </div>
        </footer >
    </div>
    );
}
export default Footer;