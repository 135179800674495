import React, { useState, useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Container, Row, Col, Image, Button, Card, Modal } from 'react-bootstrap';
import planIcon from '../../img/valid.svg';
import domainName from '../../domainName';
import { useNavigate } from "react-router-dom";
import { COLORS, SIZES } from '../../colorTheme/colorTheme';
import bowser from 'bowser';
import { useTranslation } from "react-i18next";

const SubscriptionDetails = () => {
    const [userIdFromParam, setUserIdFromParam] = useState('');
    const inputRef = useRef(null);
    const navigate = useNavigate();
    const [showCancelSubscriptionModal, setShowCancelSubscriptionModal] = useState(false)
    const [showCannotCancelModal, setShowCannotCancelModal] = useState(false)
    
    const { token, UserData, UserId } = useSelector((state) => state.login);
    const [subscriptionData, setSubscriptionData] = useState({})
    const [subscriptionHistoryData, setsubscriptionHistoryData] = useState({})
    const [lastSubscribeHistory, setLastSubscribeHistory] = useState({})
    const [changePlanButtonDisplay, setChangePlanButtonDisplay] = useState(0)
    const [showModal, setShowModal] = useState(false);
    const [isWebsite, setIsWebsite] = useState(window.innerWidth > 768);

    const [_countryCode, set_CountryCode] = useState('')

    const [t] = useTranslation("global");
    const [pathLang, setPathLang] = useState('');
    const [cannotCancel, setCannotCancel] = useState(false);

    // const userAgent = navigator.userAgent;
    const [isMobile, setIsMobile] = useState(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent));
    const downloadSafari = () => {
        const qrCodeImageURL = subscriptionData.DisplayRefer ? subscriptionData.DisplayRefer.QrCodeImage : null;

        if (qrCodeImageURL) {
            fetch(qrCodeImageURL)
                .then((response) => response.blob())
                .then((blob) => {
                    const url = window.URL.createObjectURL(blob);
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'qr_code.png');
                    link.target = '_blank';
                    link.click();
                    window.URL.revokeObjectURL(url);
                })
                .catch((error) => {
                    console.error(t('error.Error fetching or downloading the image:'), error);
                });
        }
    };

    const download = () => {
        const isSafari = /^((?!chrome|android|ios).)*safari/i.test(navigator.userAgent);
        console.log(isSafari);
        if (isSafari) {
            downloadSafari();
        } else {
            const qrCodeImageURL = subscriptionData.DisplayRefer ? subscriptionData.DisplayRefer.QrCodeImage : null;
            if (qrCodeImageURL) {
                // Create a temporary anchor element
                const link = document.createElement('a');
                link.href = qrCodeImageURL;
                link.setAttribute('download', 'qr_code.png'); // Specify the desired filename here
                link.target = '_blank'; // Open in a new tab/window
                link.click();
            }
        }
    };

    const handleOpenModal = () => {
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    const copyText = () => {
        inputRef.current.select();
        document.execCommand('copy');
        // alert('Copied the text: ' + inputRef.current.value);
    };

    const changePlan = (action) => {
        // 0: newly subscribe. Go to free trial
        // 1: change plan. Go to change plan
        // 2: cancelled plan. Go to buy plan

        let _url = ``
        if (action > 0)
            _url = `&changePlan=${action}`

        if (subscriptionHistoryData && subscriptionHistoryData.CountryCode)
            _url += `&countrycode=${subscriptionHistoryData.CountryCode}`

        if (pathLang)
            _url += `&lang=${pathLang}`

        navigate(`/BecomeAnAdvisor?uid=${userIdFromParam}${_url}`)
    };

    const cancelSubscription = () => {
        if (cannotCancel) {
            setShowCannotCancelModal(true);
        } else {
            setShowCancelSubscriptionModal(true);
        }
    };

    const proceedToCancel = async () => {


        await domainName
            .serverAPICall(
                domainName.debugAPI + "/adviser_plan/adminCancelAdviserPlanSubscription",
                "POST",
                JSON.stringify({
                    "UserId": userIdFromParam,
                })
            )
            .then(domainName.handleServerAPICallErrors)
            .then((responseJson => {
                if (responseJson.ReturnCode === 200) {
                    navigate(`/subscriptionSuccessful?changePlan=3&userId=${userIdFromParam}&lang=${pathLang}`);
                } else {
                    alert(
                        t("error.Unable to cancel plan. Please contact support team. Thank you.")
                    );
                }

            }))
            .catch((error) => {
                alert(
                    t("error.Oops!"),
                    t("error.An error occurred. Please try again later."),
                    error
                );
            });

        setShowCancelSubscriptionModal(false)
    };

    const fetchRegisteradivserCountry = async (id) => {
        return new Promise(async (resolve, reject) => {
            await domainName
                .serverAPICall(
                    domainName.debugAPI + "/user/meMenu",
                    "POST",
                    JSON.stringify({
                        "UserId": id,
                        "DisplayScreen": "User"
                    })
                )
                .then(domainName.handleServerAPICallErrors)
                .then((responseJson => {
                    if (responseJson.RegisteradivserCountry) {
                        resolve(responseJson.RegisteradivserCountry)
                    } else {
                        resolve(null)
                    }
                }))
                .catch((error) => {
                    // console.log('/user/meMenu error here', error)
                    reject(error)
                });
        })
    }

    const getUserPlanDetails = async () => {

        // Get the URLSearchParams object from the current URL
        const searchParams = new URLSearchParams(window.location.search);

        // check if have url querystring
        const userid = searchParams.get('userId');

        // Retrieve 'lang' from the URL or fallback to localStorage or default value
        const _lang = searchParams.get('lang') || localStorage.getItem('selectedValue') || 'en'; 

        setPathLang(_lang);

        let uid = UserId

        if (userid != null && userid != '') {
            uid = userid
        }

        setUserIdFromParam(uid)

        let registeredCountry = await domainName.getCountry();
        if (uid != '' && uid != undefined) {
            let cc = await fetchRegisteradivserCountry(uid);
            registeredCountry = cc == null ? registeredCountry : cc;
            set_CountryCode(registeredCountry);
        }

        await domainName
            .serverAPICall(
                domainName.debugAPI + `/adviser_plan/selectAdviserPlanDetailsByUserId?UserId=${uid}`,
                "GET"
                , null, null, null, null
                , registeredCountry
            )
            .then(domainName.handleServerAPICallErrors)
            .then((responseJson => {
                ////console.log('responseJson:: ', responseJson)
                setSubscriptionData(responseJson.Data.SubscribePlanDisplay)
                setsubscriptionHistoryData(responseJson.Data.SubscribeHistory)
                setLastSubscribeHistory(responseJson.Data.LastSubscribeHistory)

                if (
                    responseJson.Data.LastSubscribeHistory &&
                    responseJson.Data.LastSubscribeHistory.PlanKey === 'PLAN_FREE'
                ) {
                    setCannotCancel(true); 
                } else {
                    setCannotCancel(false); 
                }

                let changePlanBtnDisplay = 0 // default if both SubscribeHistory & LastSubscribeHistory is null
                if (responseJson.Data.LastSubscribeHistory && responseJson.Data.SubscribeHistory)
                    changePlanBtnDisplay = 1 // go to change plan
                else if (responseJson.Data.LastSubscribeHistory && !responseJson.Data.SubscribeHistory)
                    changePlanBtnDisplay = 2// cancelled before, this time go to buy plan

                setChangePlanButtonDisplay(changePlanBtnDisplay)
                console.log('changePlanBtnDisplay::', changePlanBtnDisplay)

                // setSubscriptionPlan(responseJson.Data)
            }))
            .catch((error) => {
                alert(
                    t("error.Oops!"),
                    t("error.An error occurred. Please try again later."),
                    error
                );
            });

    }


    const openWhatsApp = () => {
        console.log('isMobile:: ', isMobile)
        const message = encodeURIComponent(subscriptionData.DisplayRefer ? subscriptionData.DisplayRefer.ShareLinkContent : '');

        if (!isMobile) {
            const whatsappWebURL = `https://web.whatsapp.com/send?text=${message}`;
            window.open(whatsappWebURL);
        } else {

            const whatsappAppURL = `whatsapp://send?text=${message}`;
            window.location.href = whatsappAppURL;
        }
    };

    const openFacebook = () => {
        // const appID = '1384587305428357';
        // const link = encodeURIComponent(subscriptionData.DisplayRefer ? subscriptionData.DisplayRefer.QrCodeLink_x_fb_linkedin : '');
        // const redirectUri = encodeURIComponent(subscriptionData.DisplayRefer ? subscriptionData.DisplayRefer.QrCodeLink_x_fb_linkedin : '');
        // const quote = 'Your default text here'; // Replace with the default text you want to pre-fill.

        // const facebookURL = `https://www.facebook.com/dialog/feed?` +
        //     `app_id=${appID}&` +
        //     `link=${link}&` +
        //     `redirect_uri=${redirectUri}&` +
        //     `display=popup&` +
        //     `caption=YourCaptionHere&` +
        //     `description=YourDescriptionHere&` +
        //     `quote=${encodeURIComponent(quote)}`;

        // window.open(facebookURL, '_blank');
        const message = encodeURIComponent(subscriptionData.DisplayRefer ? subscriptionData.DisplayRefer.QrCodeLink_x_fb_linkedin : '');

        const facebookURL = `https://www.facebook.com/sharer/sharer.php?u=${message}`;
        window.open(facebookURL)
    };


    const openTwitter = () => {
        const message = encodeURIComponent(subscriptionData.DisplayRefer ? subscriptionData.DisplayRefer.ShareLinkContent_x_fb_linkedin : '');
        const instagramWebURL = `https://twitter.com/intent/tweet?text=${message}`;
        window.open(instagramWebURL);
    };

    const openLinkedIn = () => {
        const message = encodeURIComponent(subscriptionData.DisplayRefer ? subscriptionData.DisplayRefer.QrCodeLink_x_fb_linkedin : '');
        window.open(`https://www.linkedin.com/sharing/share-offsite/?url=${message}`);

    };

    const openTelegram = () => {
        const message = encodeURIComponent(subscriptionData.DisplayRefer ? subscriptionData.DisplayRefer.ShareLinkContent : '');

        if (!isMobile) {
            const telegramWebURL = `https://t.me/share/url?url=${message}`;
            window.open(telegramWebURL);
        } else {
            window.location.href = `tg://msg?text=${message}`;
        }
    };

    function renderNextPaymentDetails(d) {
        return (
            <Row>
                <Col className='col-lg-6'>{d.Key}</Col>
                <Col className='col-lg-6'>{d.Value}</Col>
            </Row>
        )
    }

    function CannotCancelSubscriptionModal() {
        return (
            <Modal show={showCannotCancelModal} onHide={() => setShowCannotCancelModal(false)} fade={false}>
                <Modal.Header closeButton>
                    <Modal.Title>{t('subscription.Cancellation Not Allowed')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row style={{ paddingLeft: '2%' }}>
                        {t('subscription.Free plan subscriptions are non-cancelable. Please consider upgrading to a higher-tier plan if required.')}
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={() => setShowCannotCancelModal(false)}>
                        {t('subscription.OK')}
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
    
    function CancelSubscriptionModal() {
        return (
            <Modal show={showCancelSubscriptionModal} onHide={() => setShowCancelSubscriptionModal(false)} fade={false}>
                <Modal.Header closeButton>
                    <Modal.Title>{t('subscription.Cancel Subscription')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row style={{ paddingLeft: '2%' }}>{t('subscription.Are you sure you want to cancel subscription?')} </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={proceedToCancel}>
                        {t('subscription.Proceed to cancel')}
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }

    useEffect(() => {
        const handleResize = () => {
            setIsWebsite(window.innerWidth > 768);
        };

        window.addEventListener('resize', handleResize);

        getUserPlanDetails()

    }, [])



    return (
        <div style={{ height: "100%" }}>
            <Container >
                <Row>
                    <Col className='col-12 col-lg-3'>
                        <Image className='mb-3' src={require("../../img/icons/plan.png")} width={35} height={35} style={{ marginRight: '5%', marginTop: isWebsite ? '5%' : '5%' }} />
                        <span style={{ fontSize: isWebsite ? '15px' : '17px' }}>{t('subscription.Plan')}</span>
                    </Col>

                    <Col className='col-6 col-lg-7 '>
                        <div>{subscriptionData.DisplayPlan ? subscriptionData.DisplayPlan.Title : null}</div>
                        <div style={{ color: '#5B5D6B' }}>{subscriptionData.DisplayPlan ? subscriptionData.DisplayPlan.Desc : null}</div>
                    </Col>

                    <Col className='col-lg-2 d-flex justify-content-end align-items-center '>

                        {changePlanButtonDisplay == 0 && (<button
                            className="btn-white fw-bold btn"
                            style={{ borderColor: COLORS.gray }}
                            onClick={() => {
                                changePlan(0)
                            }}
                        >{t('subscription.Subscribe Now')}</button>)}


                        {changePlanButtonDisplay == 1 && (<button
                            className="btn-white fw-bold btn"
                            style={{ borderColor: COLORS.gray }}
                            onClick={() => {
                                changePlan(1)
                            }}
                        >{t('subscription.Change Plan')}</button>)}

                        {changePlanButtonDisplay == 2 && (<button
                            className="btn-white fw-bold btn"
                            style={{ borderColor: COLORS.gray }}
                            onClick={() => {
                                changePlan(2)
                            }}
                        >{t('subscription.Subscribe Now')}</button>)}

                    </Col>
                </Row>

                <div className='divider-line'></div>


                {subscriptionHistoryData && (
                    <>
                        <Row>
                            <Col className='col-12 col-lg-3'>
                                <Image className='mb-3' src={require("../../img/icons/next payment.png")} width={35} height={35} style={{ marginRight: '5%', marginTop: '5%' }} />
                                <span>{t('subscription.Next Payment')}</span>
                            </Col>

                            <Col className='col-lg-7'>
                                <div>{subscriptionData.DisplayNextPayment ? subscriptionData.DisplayNextPayment.Total : null}</div>
                                <div style={{ color: '#5B5D6B', whiteSpace: 'nowrap', }}>{subscriptionData.DisplayNextPayment ? subscriptionData.DisplayNextPayment.PaymentDate : null}</div>

                                <div style={{ color: '#5B5D6B' }}>
                                    {
                                        subscriptionData.DisplayNextPayment ?
                                            subscriptionData.DisplayNextPayment.PaymentDetails.map((item, index) =>
                                                renderNextPaymentDetails(item)
                                            ) : <></>
                                    }
                                </div>

                            </Col>

                        </Row>

                        <div className='divider-line'></div>
                    </>
                )}

                <Row>
                    <Col className=' col-12 col-lg-3'>
                        <Image className='mb-3' src={require("../../img/icons/payment method.png")} width={35} height={35} style={{ marginRight: '5%', marginTop: '5%' }} />
                        <span>{t('subscription.Payment Method')}</span>
                    </Col>

                    <Col className='col-6 col-lg-7'>
                        <div>{subscriptionData.DisplayPaymentMethodDetails ? subscriptionData.DisplayPaymentMethodDetails.Brand : null}</div>
                        <div style={{ color: '#5B5D6B' }}>{subscriptionData.DisplayPaymentMethodDetails ? subscriptionData.DisplayPaymentMethodDetails.Last4Digit : null}</div>
                        <div style={{ color: '#5B5D6B' }}>{subscriptionData.DisplayPaymentMethodDetails ? subscriptionData.DisplayPaymentMethodDetails.Expired : null}</div>
                        <div style={{ color: '#5B5D6B' }}>{subscriptionData.DisplayPaymentMethodDetails ? subscriptionData.DisplayPaymentMethodDetails.Name : null}</div>
                    </Col>

                    {/* <Col className='col-lg-2 d-flex justify-content-end align-items-center'>
                        <button style={{ height: '40px', width: '60px', borderRadius: '5px', background: 'white', boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.5)', }}>Edit</button>
                    </Col> */}
                </Row>

                {subscriptionHistoryData && (

                    <div>
                        <div className='divider-line'></div>

                        <Row className='mb-3'>
                            <div className='col-lg-8 col-md-6'>
                                <div>{t('subscription.Subscription Status')}</div>
                                <div style={{ color: '#5B5D6B', fontSize: '15px' }}>{t('subscription.Subscription is active until')} {subscriptionHistoryData.SubscribedEndedDateDisplay}.</div>
                            </div>


                            <div className='col-lg-4 col-md-6 d-flex justify-content-end align-items-center'>
                                <button
                                    className="btn-white text-dark  fw-bold btn"
                                    style={{ borderColor: COLORS.gray }}
                                    onClick={cancelSubscription}
                                >
                                    {t('subscription.Cancel Subscription')}
                                </button>
                            </div>
                        </Row>
                    </div>

                )}
                <CannotCancelSubscriptionModal />
                <CancelSubscriptionModal />
            </Container>

        </div>

    );
};

export default SubscriptionDetails;
