import React, { useEffect, useState } from 'react'
import { Link, json, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import domainName from '../domainName';
import { Container, Row, Col, Image, Button, Card, Modal } from 'react-bootstrap';
import { COLORS, FONTS, SIZES } from '../colorTheme/colorTheme';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from "react-i18next";

const PostJobRequest = () => {

    const [t] = useTranslation("global");

    const navigate = useNavigate();


    const { token, UserData, UserId } = useSelector((state) => state.login);

    //categories
    const [categories, setCategories] = useState([]);
    const [selectedCategoryIndustry, setSelectedCategoryIndustry] = useState(null);
    const [selectedCategoryIndustryName, setSelectedCategoryIndustryName] = useState(null);
    const [selectedCategoryList, setSelectedCategoryList] = useState([]);
    const [selectedSubCategoryList, setSelectedSubCategoryList] = useState([]);
    const [selectedCategoryID, setSelectedCategoryID] = useState()
    const [selectedCategoryKey, setSelectedCategoryKey] = useState()

    // for address location
    const [address, setAddress] = useState('');
    const [addressValid, setAddressValid] = useState(false);
    const [locationList, setLocationList] = useState(null)

    const [tab10KM, setTab10KM] = useState(true);
    const [tab20KM, setTab20KM] = useState(false);

    // describe request
    const [describeRequest, setDescribeRequest] = useState('')

    //for modal
    const [showSuccessPostRequest, setShowSuccessPostRequest] = useState(false)
    const [loading, setLoading] = useState(true);

    //for address checking based on IP
    const [countryCodeFromIP, setCountryCodeFromIP] = useState('SG') //'SG' //'MY'

    const [resume, setResume] = useState([]);
    const MAX_FILE_SIZE = 2 * 1024 * 1024; // 2MB in bytes
    const ALLOWED_FILE_TYPES = ['application/pdf', 'image/png', 'image/jpeg', 'image/jpg'];

    const handleSelect = async (selectedAddress) => {
        try {
            const results = await geocodeByAddress(selectedAddress);
            const latLng = await getLatLng(results[0]);

            // Extract relevant address components from the results
            const addressComponents = results[0].address_components;
            const addressDetails = {
                Address: selectedAddress,
                UnitNo: null,
                City: null,
                Region: null,
                Country: null,
                Lat: latLng.lat,
                Lng: latLng.lng,
            };

            // Loop through address components to find specific details
            addressComponents.forEach((component) => {
                if (component.types.includes('street_number')) {
                    // Set unit number if available
                    addressDetails.UnitNo = component.long_name;
                } else if (component.types.includes('locality')) {
                    // Set city if available
                    addressDetails.City = component.long_name;
                } else if (component.types.includes('administrative_area_level_1')) {
                    // Set region if available
                    addressDetails.Region = component.long_name;
                } else if (component.types.includes('country')) {
                    // Set country if available
                    addressDetails.Country = component.long_name;
                    addressDetails.CountryCode = component.short_name;
                }
            });

            setAddress(selectedAddress)
            setLocationList(addressDetails);

            setAddressValid(true)

        } catch (error) {
            console.error(t('error.Error selecting address:'), error);
        }
    };

    async function handlePostButton() {

        let errors = [];

        if (addressValid == false) {
            errors.push(t("errorPush.- Please re-select address and do not change any words"));
        }

        if (describeRequest == false) {
            errors.push(t("errorPush.- Please leave your describe request message"));
        }

        if (errors.length > 0) {
            alert(errors.join("\n"))
        } else {

            const formData = new FormData();

            formData.append('MaxDistance', tab10KM ? 10 : 20);
            formData.append('ExtraInfo', describeRequest || "");
            formData.append('LocationList', JSON.stringify(locationList ? [locationList] : [])); 
            formData.append('CountryCode', locationList?.CountryCode || "");
            formData.append('PosterUserId', UserId || "");
            formData.append('IndustryCategory', selectedCategoryIndustry || []);
            formData.append('IndustryCategoryName', selectedCategoryIndustryName || []);
            formData.append('CategoryList', JSON.stringify(selectedCategoryList || [])); 
        
            if (resume.length > 0) {

                // Add files to FormData
                resume.forEach((file, index) => {
                    formData.append('files', file); 
                });

                // console.log('FormData entries:');
                // for (let [key, value] of formData.entries()) {
                //     if (value instanceof File) {
                //         console.log(`${key}: [File] name: ${value.name}, size: ${value.size}, type: ${value.type}`);
                //     } else {
                //         console.log(`${key}:`, value);
                //     }
                // }

            } else {
                formData.append('files', null);
            }

            try {
                await domainName
                    .serverAPICall2(
                        `${domainName.debugAPI}/user_request/insertUserJobRequestWithResume`,
                        "POST",
                        formData,
                        UserId
                    )
                    .then((responseJson) => {
                        if (responseJson === 200) {
                            console.log("Upload success");
                            setShowSuccessPostRequest(true); 
                        } else {
                            console.error("API Error:", responseJson);
                            alert(t("errorPush.An error occurred while uploading your request. Please try again later."));
                        }
                    })
                    .catch((error) => {
                        console.error("Network or runtime error:", error);
                        alert(t("errorPush.An unexpected error occurred. Please try again later.") + "\n" + error.message);
                    });
            } finally {
                setLoading(false); 
            }
        }
    }


    async function getCategories() {
        await domainName
            .serverAPICall(
                domainName.debugAPI + "/category/listAllIndustry",
                "GET"
            )
            .then(domainName.handleServerAPICallErrors)
            .then((responseJson => {
                const categoriesData = responseJson.Data;

                const categoryIndustries = categoriesData.map(item => ({
                    CategoryIndustryName: item.CategoryIndustryName,
                    CategoryIndustry: item.CategoryIndustry,
                    Categories: item.Categories.map(category => ({
                        CategoryKey: category.CategoryKey,
                        CategoryName: category.CategoryName,
                        _id: category._id,
                        Sub_Enabled: category.Sub_Enabled,
                        SubCategoryList: category.SubCategoryList.map(subCategory => ({
                            Sub_CategoryName: subCategory.Sub_CategoryName,
                            Sub_CategoryKey: subCategory.Sub_CategoryKey,
                            Sub_Enabled: subCategory.Sub_Enabled,
                            Sub_Sequence: subCategory.Sub_Sequence,
                            _id: subCategory._id,
                            CreatedTimestamp: subCategory.CreatedTimestamp,
                        }))
                    }))
                }));

                setCategories(categoryIndustries);

                if (categoryIndustries.length > 0) {
                    setSelectedCategoryIndustry(categoryIndustries[0].CategoryIndustry);
                    setSelectedCategoryIndustryName(categoryIndustries[0].CategoryIndustryName);
                    setSelectedCategoryList(categoryIndustries[0].Categories);
                }

                console.log("responseJson.Data: ", responseJson.Data)
            }))
            .catch((error) => {
                console.log("/category/listAllIndustry error: ", error)
            });
    }

    function renderDescribeRequest() {

        return (
            <div style={{ paddingTop: '5%' }}>
                <h6>
                    <span style={{ color: 'red' }}>*</span> <strong>{t('postRequest.Describe your job request')}</strong>
                </h6>
                <div className='col d-flex justify-content-center position-relative'>
                    <textarea
                        className='form-control'
                        value={describeRequest}
                        onChange={(e) => setDescribeRequest(e.target.value)}
                        // placeholder='Leave your comment here'
                        style={{
                            height: 250,
                            resize: 'none', // Disable textarea resizing
                        }}
                        maxLength={200}
                    />
                    <div
                        className='position-absolute bottom-0 end-0 p-3 text-muted'
                        style={{
                            fontSize: '0.8rem',
                        }}
                    >
                        {describeRequest.length}/200
                    </div>
                </div>
            </div>
        )
    }

    const addResume = (event) => {
        const file = event.target.files[0];
        if (!file) return;
    
        if (resume.length >= 3) {
            alert(t("errorPush.You can only upload a maximum of 3 files."));
            return;
        }
    
        // Check for duplicate files
        const isDuplicate = resume.some(
            (existingFile) => existingFile.name === file.name && existingFile.lastModified === file.lastModified
        );
        if (isDuplicate) {
            alert(t("errorPush.This file is already added."));
            return;
        }

        const fileType = file.type;
        const fileSize = file.size;

        if (!ALLOWED_FILE_TYPES.includes(fileType)) {
            alert(t("errorPush.Invalid file type") + '\n' + t("errorPush.Please upload a PDF, PNG, JPG or JPEG image."));
            return;
        }
    
        if (fileSize > MAX_FILE_SIZE) {
            alert(t("errorPush.File too large") + '\n' + t("errorPush.File size must be less than") + " 2MB");
            return;
        }
    
        setResume([...resume, file]); // Add the new file to the state
        event.target.value = '';// Clear the input field
    };
    
    const removeFile = (index) => {
        const updatedFiles = [...resume];
        updatedFiles.splice(index, 1);
        setResume(updatedFiles);
    };

    function renderAttachResume() {
        return (

            <div style={{ paddingTop: '5%' }}>
                <h6>
                    <span style={{ color: 'red' }}>*</span> <strong>{t('postRequest.Attach your resume')}</strong>
                </h6>

                <div style={{ marginTop: '10px', display: 'flex', overflowX: 'auto', paddingBottom: '12px', gap: '10px' }}>
                {/* Upload button */}
                <label
                htmlFor="file-upload"
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '120px',
                    height: '120px',
                    border: `2px solid ${COLORS.orange}`,
                    borderRadius: '5px',
                    cursor: 'pointer',
                    textAlign: 'center',
                    color: COLORS.orange,
                    backgroundColor: '#fff',
                    flexShrink: 0,
                }}
                >
                <span style={{ fontSize: '1.2rem' }}>+</span>
                <span style={{ fontSize: '1rem' }}>png, jpg, pdf</span>
                <span style={{ fontSize: '1rem' }}>Size limit: 2MB</span>
                <span style={{ fontSize: '1rem' }}>{`${resume.length}/3`}</span>
                <input
                    id="file-upload"
                    type="file"
                    style={{ display: 'none' }}
                    accept=".pdf,.jpg,.jpeg,.png"
                    onChange={addResume}
                />
                </label>

                    {/* Uploaded files */}
                    {resume.map((file, index) => (
                    <div
                        key={index}
                        style={{
                            width: '120px',
                            height: '120px',
                            position: 'relative',
                            border: '1px solid #fff',
                            borderRadius: '5px',
                            backgroundColor: '#f9f9f9',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flexShrink: 0,
                        }}
                    >
                        {/* Remove button */}
                        <button
                        onClick={() => removeFile(index)}
                        style={{
                            position: 'absolute',
                            top: '-5px',
                            right: '-4px',
                            border: 'none', 
                            background: 'transparent', 
                            padding: 0, 
                            cursor: 'pointer',
                            width: '20px', 
                            height: '20px',
                        }}
                        >
                        <img
                            src={require('../img/icons/close3.jpg')} 
                            alt="Remove"
                            style={{
                                width: '100%', 
                                height: '100%', 
                                objectFit: 'contain', 
                                backgroundColor: 'transparent', 
                            }}                        
                        />
                        </button>

                        {/* File Preview */}
                        {file.type.startsWith('image/') ? (
                        <img
                            src={URL.createObjectURL(file)}
                            alt="Uploaded"
                            style={{
                            width: '100%',
                            height: '100%',
                            objectFit: 'cover',
                            borderRadius: '5px',
                            }}
                        />
                        ) : (
                        <img
                            src={require('../img/icons/pdf-icon.jpeg')} // Replace with your web asset path
                            alt="PDF"
                            style={{
                                width: '100%',
                                height: '100%',
                            }}
                        />
                        )}
                    </div>
                    ))}
                </div>
            </div>
        );
    }
    
    function renderDistanceSelection() {
        return (
            <div style={{ paddingTop: '5%' }}>
                <h6 style={{ color: COLORS.black }}>
                    <span style={{ color: 'red' }}>*</span> <strong>{t('postRequest.Distance of jobs from your location')}</strong>
                </h6>
                <Row style={{ paddingTop: '2%', backgroundColor: COLORS.white }}>
                    <Col className="d-flex justify-content-center">
                        {/* Button for ≤ 10km */}
                        <button
                            style={{
                                width: '100%',
                                border: tab10KM ? `2px solid ${COLORS.orange}` : `2px solid ${COLORS.lightGray1}`, // Uniform border
                                backgroundColor: tab10KM ? COLORS.lightOrange : COLORS.white,
                                borderRadius: 5,
                                paddingTop: '4%',
                                paddingBottom: '3%',
                                color: tab10KM ? COLORS.orange : 'black'
                            }}
                            onClick={() => {
                                setTab10KM(true);
                                setTab20KM(false);
                            }}
                        >
                            ≤ 10km
                        </button>
                    </Col>
                    <Col className="d-flex justify-content-center">
                        {/* Button for ≤ 20km */}
                        <button
                            style={{
                                width: '100%',
                                border: tab20KM ? `2px solid ${COLORS.orange}` : `2px solid ${COLORS.lightGray1}`, // Uniform border
                                backgroundColor: tab20KM ? COLORS.lightOrange : COLORS.white,
                                borderRadius: 5,
                                paddingTop: '4%',
                                paddingBottom: '3%',
                                color: tab20KM ? COLORS.orange : 'black'
                            }}
                            onClick={() => {
                                setTab10KM(false);
                                setTab20KM(true);
                            }}
                        >
                            ≤ 20km
                        </button>
                    </Col>
                </Row>
            </div>
        )
    }

    function renderLocation() {
        return (
            <div style={{ paddingTop: '5%' }}>
                <h6>
                    <span style={{ color: 'red' }}>*</span> <strong>{t('postRequest.Your location')}</strong>
                </h6>
                <div style={{ position: 'relative' }}>
                    <PlacesAutocomplete
                        value={address}
                        onChange={(a) => {
                            setAddress(a);
                            setAddressValid(false);
                        }}
                        onSelect={handleSelect}
                        searchOptions={{ componentRestrictions: { country: countryCodeFromIP } }} // 'MY' is the country code for Malaysia
                    >
                        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                            <div style={{ position: 'relative' }}>
                                <input
                                    {...getInputProps({
                                        placeholder: t('placeholder.Enter your address...'),
                                        className: 'form-control',
                                    })}
                                    style={{
                                        paddingRight: '32px', // Enough space for the GPS icon
                                    }}
                                />
                                {/* Dropdown suggestions */}
                                <div
                                    style={{
                                        position: 'absolute',
                                        top: '100%', // Below the input
                                        left: '0',
                                        width: '100%',
                                        zIndex: '999', // Ensure it shows above other elements
                                        backgroundColor: 'white',
                                    }}
                                >
                                    {loading && <div>{t('postRequest.Loading...')}</div>}
                                    {suggestions.map((suggestion, index) => {
                                        const { description } = suggestion;
                                        return (
                                            <div
                                                key={index}
                                                {...getSuggestionItemProps(suggestion)}
                                                style={{
                                                    backgroundColor: 'white',
                                                    cursor: 'pointer',
                                                    padding: '5px', // Adjust padding for suggestion items
                                                }}
                                            >
                                                {description}
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        )}
                    </PlacesAutocomplete>
                    <img
                        src={require("../img/icons/gps-icon.png")} 
                        style={{
                            position: 'absolute',
                            right: '10px',
                            top: '50%',
                            transform: 'translateY(-50%)', // Center the icon vertically
                            width: '20px',
                            height: '20px',
                            cursor: 'pointer',
                        }}
                        alt="GPS Icon"
                    />
                </div>
            </div>

        );
    }  


    function renderSelection(name, onPress, currentKey, selectedKey) {
        const isSelected = currentKey === selectedKey;

        return (
            <Container className="d-inline-block me-1 mt-1">  
                <Button
                    onClick={onPress}
                    variant="light" // Keep variant light for non-selected state
                    style={{
                        backgroundColor: isSelected ? '#C0281C1A' : COLORS.lightGray5, // Custom light orange if selected
                        borderRadius: '30px',
                        border: isSelected ? '2px solid #C0281C' : '2px solid transparent',
                        color: isSelected ? COLORS.orange : COLORS.darkGray, // Custom text color for selected
                        padding: '6px 12px', // Padding for button
                        fontSize: '1.10rem', 
                    }}
                >
                    {name}
                </Button>
            </Container>
        );
    }

    function renderCategory() {
        return (
            <div style={{ paddingTop: '3%' }}>
                <h6>
                    <span style={{ color: 'red' }}>*</span> <strong>{t('postRequest.What job industry are you looking for')}</strong>
                </h6>

                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                    {categories.map((item, index) => (
                        <div
                            key={index}
                            style={{
                                marginBottom: '1.5%',
                                marginLeft: '-13px',
                                display: 'flex',
                                alignItems: 'center',
                            }}
                        >
                            {renderSelection(
                                item.CategoryIndustryName,
                                () => {
                                    setSelectedCategoryIndustry(item.CategoryIndustry);
                                    setSelectedCategoryIndustryName(item.CategoryIndustryName);
                                    setSelectedCategoryList(item.Categories);
                                },
                                item.CategoryIndustry,
                                selectedCategoryIndustry
                            )}
                        </div>
                    ))}
                </div>
            </div>
        );
    }


    function SuccessPostRequestModal() {
        return (
            <Modal show={showSuccessPostRequest} onHide={() => {
                setShowSuccessPostRequest(false)
                navigate("/requestlisting", { state: { activeTab: "jobRequest" } })
            }} fade={false}>
                <Modal.Header >
                    <Modal.Title style={{ textAlign: 'center' }}>
                        {t("postRequest.We're finding you jobs. Someone will whatsapp you soon.")}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="d-flex justify-content-center align-items-center">
                    <img
                        src={require("../img/icons/findingAdvisor.png")}
                        alt="listingImage"
                        className="img-fluid"
                        style={{ borderRadius: 20, width: 200, height: 200 }}
                    />
                </Modal.Body>
                <Modal.Footer className="d-flex">
                    <Button
                        variant="primary"
                        onClick={() => {
                            setShowSuccessPostRequest(false);
                            navigate("/requestlisting", { state: { activeTab: "jobRequest" } });
                        }}
                        disabled={loading}
                        style={{ width: '100%' }}
                    >
                        {loading ? "Loading..." : "View"}
                    </Button>
                    <Button
                        variant="secondary"
                        onClick={() => {
                            setShowSuccessPostRequest(false);
                            navigate("/");
                        }}
                        disabled={loading}
                        style={{ width: '100%' }}
                    >
                        {t('postRequest.Back to homepage')}
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }


    useEffect(() => {

        const getCountryCode = async () => {

            const geoResponse = await fetch(process.env.REACT_APP_BASE_URL + '/user/getCountry2', {
                headers: { 'Authorization': process.env.REACT_APP_API_KEY }
            });

            const geoData = await geoResponse.json();
            if (geoData.ReturnCode == 200) {
                const country = geoData.GeoData
                setCountryCodeFromIP(country.toUpperCase())
            }else{
                setCountryCodeFromIP('SG') //'SG' //'MY'
            }
        }

        getCategories()
        getCountryCode()
    }, []);

    function greyLine() {
        return (
            <div
                style={{
                    borderBottom: '1px solid #D8D8D8',
                    marginBottom: '1.8%',
                    marginTop: '1%',
                }}
            />
        );
    }

    return (
        <div className='greybk-div p-lg-5'>
            {/* Adjusted Container for Desktop */}
            <Container style={{ width: '55%', maxWidth: '800px' }} className='px-5 py-5 bg-white shadow-lg rounded border-light mx-auto d-none d-lg-block'>
                <h4 className='fw-bold pb-3'>{t('postRequest.Post a job Request')}</h4>
                {greyLine()}
                {renderCategory()}
                {renderLocation()}
                {renderDistanceSelection()}
                {renderDescribeRequest()}
                {renderAttachResume()}
                <Row className="mx-2 mt-5 mb-5">
                    <Button variant="primary" onClick={handlePostButton} className="btn-lg">
                        {t('postRequest.Post')}
                    </Button>
                </Row>
                <SuccessPostRequestModal />
            </Container>

            {/* Adjusted Container for Mobile */}
            <Container className='px-4 py-4 bg-white shadow-lg rounded border-light mx-auto d-lg-none'>
                <h4 className='fw-bold pb-3'>{t('postRequest.Post a job Request')}</h4>
                {greyLine()}
                {renderCategory()}
                {renderLocation()}
                {renderDistanceSelection()}
                {renderDescribeRequest()}
                {renderAttachResume()}
                <Row className="mx-1 mt-3 mb-3">
                    <Button variant="primary" onClick={handlePostButton}>
                        {t('postRequest.Post')}
                    </Button>
                </Row>
                <SuccessPostRequestModal />
            </Container>
        </div>
    ) 
}

export default PostJobRequest