import React, { useState, useEffect, useRef } from "react";
// import Button from 'react-bootstrap/Button';
// import { Col, Image, Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import AdvisorCard from '../components/AdvisorCard';
import Banner from '../components/Banner';
import Slider from 'react-slick';
import { Link, json } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import AdvisorDetails from "./AdvisorDetails";
import domainName from "../domainName";
import { useNavigate } from "react-router-dom";
import '../components/MyButton.css';
import { Container, Row, Col, Image, Button } from 'react-bootstrap';
import { COLORS, FONTS, SIZES } from "../colorTheme/colorTheme";
import OneSignal from 'react-onesignal';
import { setLatitude, setLongitude } from "../redux/slices/login";
import { SHA256 } from "crypto-js";
import { ScrollMenu, VisibilityContext } from 'react-horizontal-scrolling-menu';

import Popup from "../components/Popup";

import axios from 'axios';
import { useTranslation } from "react-i18next";

const Home = () => {
    
    const [t] = useTranslation("global");

    //for advisor
    const [displayListing, setDisplayListing] = useState([])
    const [newRequest, setNewRequest] = useState('0')
    const [unreadMessage, setUnreadMessage] = useState('0')
    const [overallRating, setOverallRating] = useState('0')
    const [overallReview, setOverallReview] = useState('0')
    const [newVisitor, setNewVisitor] = useState('0')
    const [activeStatus, setActiveStatus] = useState('Inactive')
    const [advisorData, setAdvisorData] = useState()
    const [postedServiceNumber, setPostedServiceNumber] = useState()

    const [isWebsite, setIsWebsite] = useState(window.innerWidth > 768);

    //set categories
    const [categories, setCategories] = useState([]);
    const OneSignalAppId = process.env.REACT_APP_ONESIGNAL_APPID;


    //set advisor listings
    // const [advisorServiceListings, setAdvisorServiceListings] = useState([])
    const [exploreNearByService, setExploreNearByService] = useState([])
    const [explorePopularService, setExplorePopularService] = useState([])
    const [exploreRatingService, setExploreRatingService] = useState([])

    
    //set banner
    const [banner, setBanner] = useState([]);

    //set popup image
    const [popupImage, setPopupImage] = useState();
    const [popupImageLink, setPopupImageLink] = useState(null);
    const [buttonPopup, setButtonPopup] = useState(false);

    // const isMobile = window.innerWidth <= 576; // Check if the screen width is less than or equal to 576px (considered as mobile)

    const lastIndex = banner.length - 1;




    //4-Grid Card
    //const menuItems = [...new Set(Data.map((Val) => Val.category))];

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { token, UserData, UserId, latitude, longitude } = useSelector((state) => state.login);

    // const { advisor } = useSelector((state) => state.switchRole);


    // Retrieving 'advisor' state from local storage
    console.log(`const advisorState = JSON.parse(localStorage.getItem('advisorState'));`)
    const advisorState = JSON.parse(localStorage.getItem('advisorState'));

    const [filterCategory, setFilterCategory] = useState('All');

    const handleFilterChange = (event) => {
        setFilterCategory(event.target.value);
    }
    const [view, setView] = useState('');
    useEffect(() => {
        const handleResize = () => {
            const width = window.innerWidth;
            if (width < 768) {
                setView('mobile');
            } else if (width >= 768 && width < 900) {
                setView('tab');
            } else if (width >= 900 && width < 1200) {
                setView('laptop');
            } else if (width >= 1100 && width <= 3000) {
                setView('monitor');
            } else {
                setView('default');
            }
        };
        //For the website be more responsive
        handleResize();
        // For resize
        window.addEventListener('resize', handleResize);
        // For cleaning up the event listener on component unmount
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    function greyLine() {
        return (
            <div
                style={{
                    borderBottom: '1px solid #D8D8D8',
                    marginBottom: '2%',
                    marginTop: '2%',
                }}
            />
        );
    }

    // for advisor

    async function getUpcomingBookings() {
        await domainName
            .serverAPICall(
                domainName.debugAPI + "/user/selectUserBookingListing",
                "POST",
                JSON.stringify({
                    "UserId": UserId,
                    // "UserId": "6406ac6ad3ab42d2395e625b",
                    "BookingListType": "Upcoming",
                    "UserRole": "Advisor",
                    "PageNum": 1,
                    "ResultNum": 25
                }),
                // undefined, // user id, wait binglun settle backend
                // userState.latitude, // latitude
                // userState.longitude // longitude
            )
            .then(domainName.handleServerAPICallErrors)
            .then((responseJson => {
                //return data here
                //console.log("responseJson /user/selectUserBookingListing ->", responseJson);
                setDisplayListing(responseJson.Data)
            }))
            .catch((error) => {
                //console.log("responseJson /user/selectUserBookingListing error >", error);
            });
    }



    async function getAdvisorServiceInfo() {
        await domainName
            .serverAPICall(
                domainName.debugAPI + "/user/findAdvisorService",
                "POST",
                JSON.stringify({
                    "UserId": UserId
                    // "UserId": "64128aa13777c7fbb49a4c57"
                }),
            )
            .then(domainName.handleServerAPICallErrors)
            .then((responseJson => {
                //console.log('/user/findAdvisorService : response : ', responseJson)
                setPostedServiceNumber(responseJson.Data.TotalPost)
            }))
            .catch((error) => {
                //console.log('/user/findAdvisorService : error : ', error)
            });
    }

    async function getAdvisorHomePage() {

        // //console.log(" userState.UserId", userState.UserId)
        await domainName
            .serverAPICall(
                domainName.debugAPI + "/user/getAdviserHomePage",
                "POST",
                JSON.stringify({
                    "UserId": UserId,
                    // "UserId": "6406ac6ad3ab42d2395e625b",
                }),
                // userState.UserId,
                // userState.latitude, // latitude
                // userState.longitude // longitude

            )
            .then(domainName.handleServerAPICallErrors)
            .then((responseJson => {

                //console.log('/user/getAdviserHomePage -> response : ', responseJson)
                setNewRequest(responseJson.Data.TotalTotalNewRequest)
                setUnreadMessage(responseJson.Data.TotalUnreadMessage)
                setOverallRating(responseJson.Data.TotalOverallRating)
                setOverallReview(responseJson.Data.TotalOverallReview)
                setNewVisitor(responseJson.Data.TotalNewVisitors)

            }))
            .catch((error) => {
                //console.log("/user/getAdviserHomePage -> got error", error)
            });

    }

    async function getPopupImg() {
        await domainName
            .serverAPICall(
                domainName.debugAPI + "/user/findUserScreenDisplay",
                "POST",
                JSON.stringify({
                    "UserId": UserId,
                    //"UserId": "64128aa13777c7fbb49a4c57",
                    "AppScreen": "Screen1"
                }),
            )
            .then(domainName.handleServerAPICallErrors)
            .then((responseJson => {
                //console.log('/user/findUserScreenDisplay : response : ', responseJson)
                if (responseJson.ReturnCode == 200 && responseJson.Data != null) {
                    setPopupImage(responseJson.Data.ImageDisplay)
                    if (responseJson.Data.Link !== null && responseJson.Data.Link !== undefined && responseJson.Data.Link !== "") {
                        setPopupImageLink(responseJson.Data.Link);
                    }
                    setTimeout(() => {
                        setButtonPopup(true);
                    }, 3000);
                    if (responseJson.Data.IsOneTime == true) {
                        updateUserFirstTimeUser()
                    }
                }
            }))
            .catch((error) => {
                //console.log('/user/findUserScreenDisplay : error : ', error)
            });
    }

    async function updateUserFirstTimeUser() {
        await domainName
            .serverAPICall(
                domainName.debugAPI + "/user/updateIsFirstTimeUserScreen",
                "POST",
                JSON.stringify({
                    "UserId": UserId,
                    // "UserId": "6406ac6ad3ab42d2395e625b",
                    "Appscreen": "Screen1"
                }),
            )
            .then(domainName.handleServerAPICallErrors)
            .then((responseJson => {
                console.log("responseJson /user/updateIsFirstTimeUserScreen ->", responseJson);
            }))
            .catch((error) => {
                //console.log("responseJson /user/updateIsFirstTimeUserScreen error >", error);
            });
    }



    async function checkAdvisorActive() {

        await domainName
            .serverAPICall(
                domainName.debugAPI + "/adviser_plan/checkAdviserIsExpiredAndPostService",
                "POST",
                JSON.stringify({
                    "UserId": UserId,
                    // "UserId": "6406ac6ad3ab42d2395e625b",
                }),
                // userState.UserId,
                // userState.latitude, // latitude
                // userState.longitude // longitude
            )
            .then(domainName.handleServerAPICallErrors)
            .then((responseJson => {
                //console.log('/adviser_plan/checkAdviserIsExpiredAndPostService -> response : ', responseJson)
                setActiveStatus(responseJson.IsSubscribed.DisplayStatus)
                setAdvisorData(responseJson.Data)

            }))
            .catch((error) => {
                //console.log("/adviser_plan/checkAdviserIsExpiredAndPostService error ->", error)
            });

    }

    function renderBookingsAdvisor(bookinginfo) {
        return (
            <div
                style={{
                    // backgroundColor: '#f9f9f9', 
                    borderRadius: '8px', 
                    // boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.1)', 
                    paddingLeft: '16px', 
                    paddingRight: '16px', 
                    cursor: 'pointer',
                }}
                onClick={() => {
                    navigate(`/bookingDetailsAdvisor?bookingid=${bookinginfo._id}`);
                }}
            >
                {/* Booking Number and Status */}
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        fontSize: '0.9em',
                    }}
                >
                    <span style={{ color: COLORS.black, fontWeight: 'bold' }}>
                        {t('BookingsAdvisor.Booking No')}: {bookinginfo.Booking_No}
                    </span>
                    <span
                        style={{
                            color: COLORS.lightOrange1,
                            fontWeight: 'bold',
                            fontSize: '0.9em',
                        }}
                    >
                        {bookinginfo.DisplayStatus}
                    </span>
                </div>
    
                {/* User Information */}
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginTop: '12px',
                        fontSize: '0.85em',
                    }}
                >
                    <Image
                        src={require('../img/icons/user.png')}
                        width={25}
                        height={25}
                        style={{ borderRadius: '50%', marginRight: '10px' }}
                    />
                    <span>{bookinginfo.UserData.Name}</span>
                </div>
    
                {/* Booking Date & Time */}
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginTop: '8px',
                        fontSize: '0.85em',
                    }}
                >
                    <Image
                        src={require('../img/icons/dateTime.png')}
                        width={25}
                        height={25}
                        style={{ borderRadius: '50%', marginRight: '10px' }}
                    />
                    <span>{bookinginfo.DisplayDateTime}</span>
                </div>
    
                {/* Booking Location */}
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginTop: '8px',
                        fontSize: '0.85em',
                    }}
                >
                    <Image
                        src={require('../img/icons/location.png')}
                        width={25}
                        height={25}
                        style={{ borderRadius: '50%', marginRight: '10px' }}
                    />
                    <span>{bookinginfo.DisplayLocation}</span>
                </div>
    
                {/* Buttons for Profile and Chat */}
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        marginTop: '16px', 
                    }}
                >
                    {/* View Profile Button */}
                    <button
                        onClick={(e) => {
                            e.stopPropagation();
                            navigate(`/myProfile?userId=${bookinginfo.UserData._id}`);
                        }}
                        className="btn-white text-dark fw-bold btn"
                        style={{
                            borderColor: COLORS.gray, 
                            backgroundColor: COLORS.white, 
                            color: COLORS.black,
                            width: '49%', 
                            padding: '5px 0', 
                            borderRadius: '5px', 
                            textAlign: 'center',
                            fontSize: '0.9em',
                        }}
                    >
                        {t('home.View Profile')}
                    </button>

                    {/* Chat Button */}
                    <button
                        onClick={(e) => {
                            e.stopPropagation();
                            let currentTime = new Date().getTime(); // epoch timestamp now
                            const resultEncrypted = SHA256(
                                UserId + process.env.REACT_APP_COMMUNITY_APP_KEY + 'Adviser2u' + currentTime
                            );
                            navigate(
                                `/message?userId=${UserId}&messageId=${bookinginfo.UserData._id}&uid=${resultEncrypted}&tid=${currentTime}`
                            );
                        }}
                        className="btn-white text-dark fw-bold btn"
                        style={{
                            borderColor: COLORS.gray, 
                            backgroundColor: COLORS.white,
                            color: COLORS.black, 
                            width: '49%', 
                            padding: '5px 0', 
                            borderRadius: '5px',
                            textAlign: 'center',
                            fontSize: '0.9em', 
                        }}
                    >
                        {t('home.Chat')}
                    </button>
                </div>

                {greyLine()}
            </div>
        );
    }

    function renderUpcomingBookings() {
        return (
            <div
                className="bg-white rounded px-3 py-4"
                style={{
                    width: 'auto',
                    minHeight: 300,
                    paddingLeft: '2%',
                    paddingTop: '3%',
                    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)', 
                    marginTop: '20px',
                }}
            >                
                <div style={{ fontWeight: 'bold', fontSize: "1.2em", paddingBottom: "1.2em"}}>{t('home.Upcoming Bookings')}</div>

                {
                    displayListing == ""
                        ?
                            <div style={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                                minHeight: '300px', 
                            }}>

                                <div><img src={require("../img/icons/bookingImage.png")} /></div>
                                <div style={{ paddingTop: '2%'}}> <span>{t("home.You don't have any upcoming bookings yet")}</span></div>

                            </div>
                        :
                        <div>
                            {
                                displayListing.map((item, index) =>
                                    renderBookingsAdvisor(item)
                                )
                            }
                        </div>
                }
            </div>
        )
    }

    function renderHomeLabelComponent(title, data, onPress, showArrow, review) {
        return (
            <div className="bg-white rounded px-3 py-3 shadow-sm" style={{ width: 'auto', minHeight: 120, height: 'auto', cursor: showArrow && 'pointer' }}
                onClick={onPress}
            >
                <div className="d-flex justify-content-between">
                    <div className="d-flex flex-column mr-md-4">
                        <span className="text-gray fs-6 mb-1" style={{ color: COLORS.gray }}>{title}</span>
                        {
                            title !== "Overall rating" && title !== "New visitor" ?
                                <span style={{ color: COLORS.black, fontSize: '1.5em', fontWeight: "bold"}}>{data}</span> :
                                title === "Overall rating" ?
                                    <div className="d-flex flex-column">
                                        <div className="d-flex">
                                            <img
                                                src={require("../img/icons/star.png")}
                                                alt="star"
                                                style={{
                                                    width: 15, height: 15, alignSelf: 'center', marginRight: '10%'
                                                }}
                                            />
                                            <span style={{ color: COLORS.black, fontSize: '1.5em', fontWeight: "bold"}}>{data}</span>
                                        </div>
                                        <span className="fs-10" style={{ color: COLORS.gray }}>{`based on ${review} reviews`}</span>
                                    </div> :
                                    <div className="d-flex flex-column">
                                        <span style={{ color: COLORS.black, fontSize: '1.5em', fontWeight: "bold"}}>{data}</span>
                                        <span className="fs-8" style={{ color: COLORS.gray }}>{t('home.refresh weekly')}</span>
                                    </div>
                        }
                    </div>
                    <div className="pt-4">
                        {showArrow && <img src={require("../img/icons/arrow.png")} alt="arrow" style={{ width: 10, height: 10 }} />}
                    </div>
                </div>
            </div>
        );
    }


    // // for user and consumer
    // const getAdvisorServiceListings = async () => {

    //     //console.log('/service/exploreHomeListing > UserId,latitude,longitude', UserId, latitude, longitude)

    //     await domainName
    //         .serverAPICall(
    //             domainName.debugAPI + "/service/exploreHomeListing",
    //             "GET",
    //             undefined,
    //             UserId,
    //             latitude, // latitude
    //             longitude // longitude
    //         )
    //         .then(domainName.handleServerAPICallErrors)
    //         .then((responseJson => {
    //             //console.log('/service/exploreHomeListing -> response : ', responseJson)
    //             setAdvisorServiceListings(responseJson.Data)
    //         }))
    //         .catch((error) => {
    //             //console.log('/service/exploreHomeListing -> error : ', error)
    //         });
    // }

    const getExploreNearByService = async () => {

        await domainName
            .serverAPICall(
                domainName.debugAPI + "/service/exploreNearByService",
                "GET",
                undefined,
                UserId,
                latitude, // latitude
                longitude // longitude
            )
            .then(domainName.handleServerAPICallErrors)
            .then((responseJson => {
                // console.log('/service/exploreNearByService -> response : ', responseJson)
                setExploreNearByService(responseJson.Data)
            }))
            .catch((error) => {
                // console.log('/service/exploreNearByService -> error : ', error)
            });
    }


    const getExplorePopularService = async () => {

        await domainName
            .serverAPICall(
                domainName.debugAPI + "/service/explorePopularService",
                "GET",
                undefined,
                UserId,
                latitude, // latitude
                longitude // longitude
            )
            .then(domainName.handleServerAPICallErrors)
            .then((responseJson => {
                //console.log('/service/exploreHomeListing -> response : ', responseJson)
                setExplorePopularService(responseJson.Data)
            }))
            .catch((error) => {
                //console.log('/service/exploreHomeListing -> error : ', error)
            });
    }

    const getExploreRatingService = async () => {

        await domainName
            .serverAPICall(
                domainName.debugAPI + "/service/exploreRatingService",
                "GET",
                undefined,
                UserId,
                latitude, // latitude
                longitude // longitude
            )
            .then(domainName.handleServerAPICallErrors)
            .then((responseJson => {
                //console.log('/service/exploreHomeListing -> response : ', responseJson)
                setExploreRatingService(responseJson.Data)
            }))
            .catch((error) => {
                //console.log('/service/exploreHomeListing -> error : ', error)
            });
    }




    const getCategories = async () => {

        await domainName
            .serverAPICall(
                domainName.debugAPI + "/category/listAllCategory",
                "GET"
            )
            .then(domainName.handleServerAPICallErrors)
            .then((responseJson => {
                setCategories(responseJson.Data)
            }))
            .catch((error) => {
                //console.log('/category/listAllCategory -> error : ', error)
            });

    }

    const getBanner = async () => {

        await domainName
            .serverAPICall(
                domainName.debugAPI + "/banner/listAllBanner",
                "GET"
            )
            .then(domainName.handleServerAPICallErrors)
            .then((responseJson => {
                //console.log('responseJson.Data -> list all banner', responseJson.Data)
                setBanner(responseJson.Data)
            }))
            .catch((error) => {
                //console.log('/banner/listAllBanner -> error : ', error)
            });

    }
    function LeftArrow() {
        const { isFirstItemVisible, scrollPrev } =
            React.useContext(VisibilityContext);

        return (
            <div disabled={isFirstItemVisible} onClick={() => scrollPrev()}>
                Left
            </div>
        );
    }

    function RightArrow() {
        const { isLastItemVisible, scrollNext } = React.useContext(VisibilityContext);

        return (
            <div disabled={isLastItemVisible} onClick={() => scrollNext()}>
                Right
            </div>
        );
    }
    // function renderAdvisorListings(title, moreButtonClicked, serviceListings) {

    //     return (
    //         <>
    //             <div className="w-100 mt-3 mb-3">
    //                 <span style={{ fontWeight: 'bold', fontSize: 25 }}>{title}</span>
    //             </div>

    //             <Row style={{ maxheight: '400px' }}>
    //                 {/* <ScrollMenu LeftArrow={LeftArrow} RightArrow={RightArrow}> */}
    //                 {serviceListings?.map((service, index) => (
    //                     <AdvisorCard key={index} item={service} />
    //                 ))}
    //                 {/* </ScrollMenu> */}
    //             </Row>

    //         </>
    //     );
    // }

    function renderExploreNearByService(title, moreButtonClicked, serviceListings) {

        return (
            <>
                <div className="w-100 mt-3 mb-3">
                    <span style={{ fontWeight: 'bold', fontSize: 25 }}>{title}</span>
                </div>

                <Row style={{ maxheight: '400px' }}>
                    {/* <ScrollMenu LeftArrow={LeftArrow} RightArrow={RightArrow}> */}
                    {serviceListings?.map((service, index) => (
                        <AdvisorCard key={index} item={service} />
                    ))}
                    {/* </ScrollMenu> */}
                </Row>

            </>
        );
    }
    function renderExplorePopularService(title, moreButtonClicked, serviceListings) {

        return (
            <>
                <div className="w-100 mt-3 mb-3">
                    <span style={{ fontWeight: 'bold', fontSize: 25 }}>{title}</span>
                </div>

                <Row style={{ maxheight: '400px' }}>
                    {/* <ScrollMenu LeftArrow={LeftArrow} RightArrow={RightArrow}> */}
                    {serviceListings?.map((service, index) => (
                        <AdvisorCard key={index} item={service} />
                    ))}
                    {/* </ScrollMenu> */}
                </Row>

            </>
        );
    }
    function renderExploreRatingService(title, moreButtonClicked, serviceListings) {

        return (
            <>
                <div className="w-100 mt-3 mb-3">
                    <span style={{ fontWeight: 'bold', fontSize: 25 }}>{title}</span>
                </div>

                <Row style={{ maxheight: '400px' }}>
                    {/* <ScrollMenu LeftArrow={LeftArrow} RightArrow={RightArrow}> */}
                    {serviceListings?.map((service, index) => (
                        <AdvisorCard key={index} item={service} />
                    ))}
                    {/* </ScrollMenu> */}
                </Row>

            </>
        );
    }


    function renderAdvisorHomepage() {
        return (
            <div>
                <div
                    className="py-5"
                    style={{
                        backgroundColor: COLORS.orange,
                        paddingLeft: '7%',
                        paddingRight: '7%',
                        // marginLeft: '-0.85%',
                        // marginRight: '-0.85%',
                    }}
                >
                    <div className="d-flex justify-content-between align-items-center">
                        <h4 className="text-left pb-1 m-0" style={{ color: COLORS.white }}>
                            {t('home.Hi')} {UserData?.Name},
                        </h4>
                            
                        {/* Active Status */}
                        <div className="px-3 py-1 rounded-pill d-inline-block" style={{ backgroundColor: 'rgba(0,0,0,0.2)', fontSize: "0.8em" }}>
                            <span className="text-white">{activeStatus}</span>
                        </div>
                    </div>                        

                    <h5 className="text-left pb-1" style={{ color: COLORS.white, marginBottom: '20px' }}>{t("home.Welcome back!")}</h5>

                    {/* Check if all the services that adviser posted are completed */}
                    {activeStatus === "Active" && (parseInt(advisorData?.SubscribeHistory[0].TotalPostService) - parseInt(postedServiceNumber)) > 0 &&
                        <div className="d-flex">
                            <span className="text-white fs-6">{t('home.Please complete your listing.')}</span>
                            <button onClick={() => { navigate('/myListingsAdvisor') }} className="text-white fs-6 bg-transparent border-0" style={{ cursor: 'pointer' }}>{t('home.Complete &gt;')}</button>
                        </div>
                        // Add message to tell the user they are inactive now
                    }
                </div>
                
                <Container className="width95" >

                    {/* 4 column's container */}
                    <div className="mt-neg-8 px-2.4" style={{ zIndex: 10, marginTop: '-3em', marginBottom: '2%' }}>
                        <div className="row g-3">
                            <div className="col-lg-3 col-sm-6 col-6">
                                {renderHomeLabelComponent(t("home.New Request"), newRequest, () => navigate('/newrequestadvisor'), true)}
                            </div>
                            <div className="col-lg-3 col-sm-6 col-6">
                                {renderHomeLabelComponent(t("home.Unread Message"), unreadMessage, () => navigate('/message?userId=' + (UserData && UserData._id ? UserData._id : '')), true)}
                            </div>
                            <div className="col-lg-3 col-sm-6 col-6">
                                {renderHomeLabelComponent(t("home.Overall rating"), overallRating, () => navigate('/myReview'), true, overallReview)}
                            </div>
                            <div className="col-lg-3 col-sm-6 col-6">
                                {renderHomeLabelComponent(t("home.New visitor"), newVisitor, undefined, false)}
                            </div>
                        </div>
                    </div>

                    <div>
                        {renderUpcomingBookings()}
                    </div>
                </Container>
            </div>
        )
    }


    function renderUsersHomepage() {

        return (

            <>

                {/* banner here */}
                <Banner bannerObj={banner} />

                <Container className="width95">
                    <Row className="mt-5">
                        {/* Filter For Categories */}
                        <Col>
                            <span style={{ fontWeight: 'bold', fontSize: 25 }}>  {t('home.Categories')}</span>

                            <Row>
                                {categories.map((item, index) => (
                                    <Col className="p-3" xs={3} md={2} xl={1} key={index}>
                                        <Link
                                            style={{ textDecorationLine: 'none', color: COLORS.black }}
                                            to={`/ServiceListing?category=${encodeURIComponent(JSON.stringify(item))}`}
                                            key={index}
                                        >
                                            <div>
                                                <Image src={item.ImageDisplay} style={{ maxWidth: '100%' }} />
                                                <div className="text-center pt-2" style={{ textAlign: 'center' }}>
                                                    <span style={{
                                                        fontSize: isWebsite ? 15 : 12, maxWidth: '100%', lineHeight: isWebsite ? '1.2' : '1.0', display: 'block', whiteSpace: 'break-spaces',
                                                    }}>{item.CategoryName}</span>
                                                </div>
                                            </div>
                                        </Link>
                                    </Col>
                                ))}
                            </Row>


                            {/* <div className="d-flex flex-wrap align-items-start mt-2" style={{ display: 'flex', flexWrap: 'wrap', }}>
                            {categories.map((item, index) => (
                                <Link
                                    style={{ textDecorationLine: 'none', color: COLORS.black }}
                                    to={`/ServiceListing?category=${encodeURIComponent(JSON.stringify(item))}`}
                                    key={index}
                                >
                                    <div className="image-container-2 me-2 mb-2 " style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        maxWidth: isWebsite ? '150px' : '120px',
                                        wordBreak: 'break-word',
                                    }}>
                                        <Image
                                            src={item.ImageDisplay}
                                            fluid
                                            className="img-fluid"
                                            style={{
                                                height: isWebsite ? '110px' : '60px',
                                                width: isWebsite ? '110px' : '60px',
                                            }}
                                        />
                                        <div className="text-center pt-2" style={{ textAlign: 'center' }}>
                                            <span style={{
                                                fontSize: isWebsite ? 15 : 12, maxWidth: '100%', lineHeight: isWebsite ? '1.2' : '1.0', display: 'block', whiteSpace: 'break-spaces',
                                            }}>{item.CategoryName}</span>
                                        </div>
                                    </div>
                                </Link>
                            ))}
                        </div> */}
                        </Col>
                        {/* <div>{JSON.stringify(categories)}</div> */}
                    </Row >
                </Container >

                {/* <Container className="width95">
                    <Row className="mt-4">
                        <div>
                            <div>{JSON.stringify(advisorServiceListings)}</div>
                            {advisorServiceListings.map((item, index) => (
                                <div key={index}>
                                    {renderAdvisorListings(item.Title, item.Type, item.Service)}
                                </div>
                            ))}
                        </div>
                    </Row>
                </Container> */}
                <Container className="width95">
                    <Row className="mt-4">
                        <div>
                            {exploreNearByService.map((item, index) => (
                                <div key={index}>
                                    {renderExploreNearByService(item.Title, item.Type, item.Service)}
                                </div>
                            ))}
                        </div>
                    </Row>
                </Container>                
                <Container className="width95">
                    <Row className="mt-4">
                        <div>
                            {explorePopularService.map((item, index) => (
                                <div key={index}>
                                    {renderExplorePopularService(item.Title, item.Type, item.Service)}
                                </div>
                            ))}
                        </div>
                    </Row>
                </Container>                
                <Container className="width95">
                    <Row className="mt-4">
                        <div>
                            {exploreRatingService.map((item, index) => (
                                <div key={index}>
                                    {renderExploreRatingService(item.Title, item.Type, item.Service)}
                                </div>
                            ))}
                        </div>
                    </Row>
                </Container>

                <div className="container-fluid footer-banner mt-5" >
                    <Row className="mt-4">
                        <Col>
                            <h1 className="footer-heading ">
                                <div style={{paddingRight: "5%"}}>
                                    {t('home.A ONE-STOP PROFESSIONAL')} 
                                    <br/>
                                {t('home.FINANCIAL PLATFORM')}
                                </div>
                            </h1>
                        </Col>
                    </Row>
                    <Row className="mt-4">
                        <Col>
                            <h3 style={{ color: COLORS.darkGray }} >
                                {t('home.Download and create profile')}
                            </h3>
                        </Col>
                    </Row>

                    <Row className="mt-3">
                        <Col>
                            <div className={isWebsite ? "" : "text-center"}>
                                <a href="https://apps.apple.com/us/app/adviser2u/id6448983215?itsct=apps_box_badge&amp;itscg=30200" style={{ display: "inline-block", overflow: "hidden", borderRadius: "13px", width: "175px", height: "83px" }}>
                                    <img src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83&amp;releaseDate=1697328000" alt="Download on the App Store" style={{ borderRadius: "13px", width: "175px", height: "83px" }} />
                                </a>
                                <a href="https://play.google.com/store/apps/details?id=com.advisor2uapp&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1" style={{ display: "inline-block", overflow: "hidden", borderRadius: "13px", width: "200px", height: "83px" }}>
                                    <img src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png" alt="Download on the Google Play Store" style={{ borderRadius: "13px", width: "200px", height: "83px" }} /></a>
                            </div>
                        </Col>
                    </Row>
                </div>

                {/* <img
                        src={require('../img/app-banner-no-text.jpeg')}
                        className="img-fluid"
                        alt="Image"
                        style={{
                            height: 'auto',
                            width: '100%',
                            objectFit: 'cover',
                        }}
                    /> */}

            </>
        );
    }


    function getPopup() {
        return (
            <div>
                <Popup
                    trigger={buttonPopup}
                    setTrigger={setButtonPopup}
                    imageLink={popupImageLink}
                >
                    <img src={popupImage} style={{ maxHeight: '85vh', maxWidth: '90vw' }} />
                </Popup>
            </div>

        )
    }


    useEffect(() => {
        OneSignal.init({
            appId: OneSignalAppId,
        });
        OneSignal.Slidedown.promptPush();
    }, []);


    useEffect(() => {
        // Request the user's location here
        navigator.geolocation.getCurrentPosition(
            (position) => {
                const { latitude, longitude } = position.coords;
                //console.log('Latitude:', latitude);
                //console.log('Longitude:', longitude);

                setLatitude(latitude)
                setLongitude(longitude)

                // Do something with the user's location data
            },
            (error) => {
                console.error(t('error.Error getting user location:'), error);
                // Handle the error case
            }
        );

        const handleResize = () => {
            setIsWebsite(window.innerWidth > 768);
        };

        window.addEventListener('resize', handleResize);

        if (advisorState.advisor == true) {
            checkAdvisorActive()
            getAdvisorHomePage()
            getAdvisorServiceInfo()
            getUpcomingBookings()
        } else {
            getBanner()
            getPopupImg()
            getCategories()
            // getAdvisorServiceListings()
            getExploreNearByService()
            getExplorePopularService()
            getExploreRatingService()

        }

        return () => {
            window.removeEventListener('resize', handleResize);
        };

    }, [UserId])

    return (
        <div>
            {advisorState.advisor == true
                ?
                <Container fluid style={{ backgroundColor: COLORS.lightGray3, paddingBottom: '20px', paddingLeft:'0', paddingRight:'0' }}>
                    {renderAdvisorHomepage()}
                </Container>
                :
                <div>
                    {renderUsersHomepage()}
                    {getPopup()}
                </div>
            }
        </div>
    )
}

export default Home