import React, { useEffect, useState } from 'react'
import domainName from '../../domainName'
import { useSelector } from 'react-redux';
import './newRequestDetails.css'

import { Container, Row, Col, Image, Button, Modal } from 'react-bootstrap';
import { COLORS } from "../../colorTheme/colorTheme";
import { useNavigate } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { SHA256 } from 'crypto-js';

const NewRequestListings = () => {

    const { token, UserData, UserId, latitude, longitude } = useSelector((state) => state.login);

    const [newRequestListing, setNewRequestListing] = useState([])

    const navigate = useNavigate();
    const [t, i18n] = useTranslation("global");

    const [tab, setTab] = useState('Request');
    const [requestListing, setRequestListing] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const [selectedRequest, setSelectedRequest] = useState(null);

    const [isModalVisible, setModalVisible] = useState(false);
    const [isInsufficientModalVisible, setInsufficientModalVisible] = useState(false);

    const [requestId, setRequestId] = useState();
    const [requestType, setRequestType] = useState("NORMAL");
    //data from api
    const [haveRequestData, setHaveRequestData] = useState(false);
    const [requestData, setRequestData] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [enoughCredit, setEnoughCredit] = useState(false);
    const [targetUserId, setTargetUserId] = useState(false);

    const [showFullScreen, setShowFullScreen] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);

    const openFullScreen = (file) => {
        setSelectedImage(file);
        setShowFullScreen(true);
    };

    const closeFullScreen = () => {
        setSelectedImage(null);
        setShowFullScreen(false);
    };

    async function fetchRequestListing(typeSelected) {
        setIsLoading(true);
        await domainName
            .serverAPICall(
                domainName.debugAPI + "/user_request/getRequestListing",
                "POST",
                JSON.stringify({
                    "UserId": UserId,
                    "type": typeSelected
                }),
            )
            .then(domainName.handleServerAPICallErrors)
            .then((responseJson => {

                if (responseJson.ReturnCode == 200 && responseJson.Data != null) {
                    setRequestListing(responseJson.Data);
                } else {
                    console.error("Invalid data format received:", responseJson.ReturnDesc);
                    setRequestListing([]);
                }
            }))
            .catch((error) => {
                console.error(`Error fetching data from /user_request/getRequestListing:`, error);
                setRequestListing([]);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }


    function renderRequestCard(requestitem) {
        const statusMapping = {
            ACTIVE: { text: t('NewRequestListings.Active'), backgroundColor: COLORS.lightGreen, textColor: COLORS.green2 },
            DELETE: { text: t('NewRequestListings.Deleted'), backgroundColor: COLORS.lightOrange4, textColor: COLORS.orange2 },
            USER_WITHDRAWAL: { text: t('NewRequestListings.User Withdrawal'), backgroundColor: COLORS.lightOrange4, textColor: COLORS.orange2 },
            EXPIRED: { text: t('NewRequestListings.Expired'), backgroundColor: COLORS.lightGray4, textColor: COLORS.gray4 },
        };

        const currentStatus = statusMapping[requestitem.Status] || { text: t('NewRequestListings.Unknown'), backgroundColor: COLORS.lightGray4, textColor: COLORS.gray4 };

        const isSelected = selectedRequest?.RequestId === requestitem.RequestId;

        return (
            <div style={{
                backgroundColor: isSelected ? COLORS.lightGrayAccount : COLORS.white,
                borderWidth: 0,
                borderBottomWidth: 1.5,
                borderColor: COLORS.lightGray1,
                borderStyle: 'solid',
                paddingTop: '1%',
                paddingBottom: '3%',
                cursor: 'pointer'
            }}
                onClick={() => {
                    // navigate(`/newRequestAdvisorDetails?requestId=${requestitem.RequestId}`)
                    setSelectedRequest(requestitem);
                    getRequestDetails(requestitem.RequestId, requestitem.RequestType)
                }}>
                <Row className='mt-2'>
                    <Col>
                        <div style={{ fontSize: '1em', fontWeight: 'bold' }}>{requestitem.RequestIdNo}</div>
                    </Col>
                    <Col className="d-flex justify-content-end">
                        <div
                            style={{
                                backgroundColor: currentStatus.backgroundColor,
                                color: currentStatus.textColor,
                                padding: '5px 10px',
                                borderRadius: '10px',
                                display: 'inline-block',
                                fontSize: '0.8em',
                                fontWeight: 'bold',
                            }}
                        >
                            {currentStatus.text}
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div style={{ fontSize: '0.8em' }}>{requestitem.CreatedDate}</div>
                    </Col>
                </Row>
                <Row className='mt-2'>
                    <Col>
                        <img src={require('../../img/icons/user.png')} style={{ height: 20, width: 20 }} />
                        <div style={{ fontSize: '0.9em', display: 'inline', marginLeft: 8 }}>{requestitem.PosterName}</div>
                    </Col>
                </Row>

                <Row className='mt-2'>
                    <Col>
                        <img src={require('../../img/icons/categories.png')} style={{ height: 20, width: 20 }} />
                        <div style={{ fontSize: '0.9em', display: 'inline', marginLeft: 8 }}>{requestitem.CategoryDisplay}</div>
                    </Col>
                </Row>

            </div >
        )
    }

    async function getRequestDetails(requestid, requesttype) {
        await domainName
            .serverAPICall(
                domainName.debugAPI + "/user_request/getRequestDetailById",
                "POST",
                JSON.stringify({
                    "UserId": UserId,
                    "RequestId": requestid ? requestid : requestId,
                    "RequestType": requesttype
                }),
            )
            .then(domainName.handleServerAPICallErrors)
            .then((responseJson => {
                if (responseJson.ReturnCode == 200 && responseJson.Data != null) {
                    // console.log("NewRequestListings", responseJson.Data)
                    setHaveRequestData(true);
                    setRequestId(requestid)

                    setRequestData(responseJson.Data)
                    setShowModal(!responseJson.Data.HaveSpentCredit);
                    setEnoughCredit(responseJson.Data.IsMyCreditEnough);
                    setTargetUserId(responseJson.Data.PosterUserId)
                } else {
                    alert(responseJson.ReturnDesc)
                }
            }))
            .catch((error) => {
                console.log('/getRequestDetailById : error : ', error)
            });
    }

    const handleNavigateToBecomeAnAdvisor = () => {
        navigate(`/BecomeAnAdvisor?uid=${UserId}`)
    };

    async function handleConfirmSpentCredit() {
        setModalVisible(false);

        await domainName
            .serverAPICall(
                domainName.debugAPI + "/adviser_plan/useCredit",
                "POST",
                JSON.stringify({
                    "UserId": UserId,
                    "RequestType": selectedRequest?.RequestType,
                    "RequestId": selectedRequest?.RequestId
                })
            )
            .then(domainName.handleServerAPICallErrors)
            .then((responseJson => {
                //return data here
                console.log(" /adviser_plan/useCredit responseJson here oh !", responseJson);
                if (responseJson.ReturnCode == 200) {
                    setShowModal(false);
                } else {
                    alert(responseJson.ReturnDesc)
                }
            }))
            .catch((error) => {
                console.log("/adviser_plan/useCredit error here oh !", error);
            });
    }

    function renderRequestDetails() {
        const statusMapping = {
            ACTIVE: { text: t('NewRequestListings.Active'), backgroundColor: COLORS.lightGreen, textColor: COLORS.green2 },
            DELETE: { text: t('NewRequestListings.Deleted'), backgroundColor: COLORS.lightOrange4, textColor: COLORS.orange2 },
            USER_WITHDRAWAL: { text: t('NewRequestListings.User Withdrawal'), backgroundColor: COLORS.lightOrange4, textColor: COLORS.orange2 },
            EXPIRED: { text: t('NewRequestListings.Expired'), backgroundColor: COLORS.lightGray4, textColor: COLORS.gray4 },
        };

        const currentStatus = statusMapping[requestData?.Status] || { text: t('NewRequestListings.Unknown'), backgroundColor: COLORS.lightGray4, textColor: COLORS.gray4 };

        if (!selectedRequest) {
            return (
                // Data exists but no request is selected
                <div style={{ textAlign: "center", padding: "20px" }}>
                    <p style={{ fontSize: "0.9em" }}>
                        {t('NewRequestListings.Select Request Details')}
                    </p>
                </div>
            );
        }

        return (
            <div style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                height: 'auto',
                padding: "10px",
            }}>
                {/* Conditional Rendering */}
                {showModal ? (
                    <div>
                        {/* If showModal is true */}
                        <div className="p-3" style={{ backgroundColor: COLORS.lightGrayAccount, borderRadius: 5, flexDirection: 'column' }}>
                            {/* Status Box */}
                            <div
                                style={{
                                    backgroundColor: currentStatus.backgroundColor,
                                    color: currentStatus.textColor,
                                    padding: '5px 10px',
                                    borderRadius: '10px',
                                    textAlign: 'center',
                                    marginBottom: '5px',
                                    display: 'inline-block',
                                }}
                            >
                                <span style={{ fontSize: '0.85em', fontWeight: 'bold' }}>{currentStatus.text}</span>
                            </div>

                            {/* Request Information */}
                            <div style={{ fontSize: '1em', fontWeight: 'bold', opacity: 0.8 }}>
                                {t('NewRequestListings.Request No')} {requestData?.RequestIdNo || 'N/A'}
                            </div>
                            <div style={{ fontSize: '1em', fontWeight: 'bold', opacity: 0.8 }}>
                                {t('NewRequestListings.Request date')} {requestData?.RequestDate || 'N/A'}
                            </div>
                        </div>

                        {/* Hide Request Details */}
                        <div style={{ marginTop: '20px' }}>
                            <div className="feature-item" style={{ opacity: 0.8 }}>
                                <img
                                    src={require('../../img/icons/user.png')}
                                    alt="user icon"
                                    className="feature-icon"
                                />
                                <span>{requestData?.PosterName || 'N/A'}</span>
                            </div>

                            <div className="feature-item" style={{ opacity: 0.6 }}>
                                <img
                                    src={require('../../img/icons/categories.png')}
                                    alt="category icon"
                                    className="feature-icon"
                                />
                                <span>{requestData?.Category || 'N/A'}</span>
                            </div>

                            <div
                                className="feature-item"
                                style={{
                                    opacity: 0.4,
                                    overflow: 'hidden',
                                    whiteSpace: 'nowrap',
                                    textOverflow: 'ellipsis',
                                }}
                            >
                                <img
                                    src={require('../../img/icons/location.png')}
                                    alt="location icon"
                                    className="feature-icon"
                                />
                                <span>{requestData?.Address || 'N/A'}</span>
                            </div>
                        </div>


                        {/* Request Details */}
                        <div style={{ marginTop: '35px' }}>
                            <Row className="mb-3">
                                <Col style={{ fontSize: '1.2em', fontWeight: 'bold' }}>
                                    {t('NewRequestListings.Use 1 Credit to View the Request')} {requestData?.PosterName || 'N/A'}{t('NewRequestListings.From')}
                                </Col>
                            </Row>
                            <Row className="mb-2">
                                <Col style={{ fontSize: '1em' }}>
                                    <img
                                        src={require('../../img/icons/check2.png')}
                                        alt="check icon"
                                        style={{
                                            width: '20px',
                                            height: '20px',
                                            marginRight: '8px',
                                        }}
                                    />
                                    {t('NewRequestListings.Request Location')}
                                </Col>
                            </Row>
                            <Row className="mb-2">
                                <Col style={{ fontSize: '1em', display: 'flex', alignItems: 'center' }}>
                                    <img
                                        src={require('../../img/icons/check2.png')}
                                        alt="check icon"
                                        style={{
                                            width: '20px',
                                            height: '20px',
                                            marginRight: '8px',
                                        }}
                                    />
                                    {t('NewRequestListings.View1')} {requestData?.PosterName || 'N/A'} {t('NewRequestListings.Profile')}
                                </Col>
                            </Row>
                            <Row className="mb-5">
                                <Col style={{ fontSize: '1em' }}>
                                    <img
                                        src={require('../../img/icons/check2.png')}
                                        alt="check icon"
                                        style={{
                                            width: '20px',
                                            height: '20px',
                                            marginRight: '8px',
                                        }}
                                    />
                                    {t('NewRequestListings.Chatting')} {requestData?.PosterName || 'N/A'}{t('NewRequestListings.With')}
                                </Col>
                            </Row>
                        </div>


                        {/* Unlock Section */}
                        <Row style={{ textAlign: 'center', margin: '0' }}>
                            <Button
                                style={{
                                    display: 'flex', // Flexbox for layout
                                    alignItems: 'center', // Vertically align icon and text
                                    justifyContent: 'center', // Center icon and text within the button
                                    borderRadius: '5px',
                                    padding: '10px 15px',
                                    fontSize: '1em',
                                    fontWeight: 'bold',
                                    border: 'none',
                                    gap: '10px', // Space between the icon and text
                                }}
                                onClick={() => {
                                    if (enoughCredit) {
                                        setModalVisible(true);
                                    } else {
                                        setInsufficientModalVisible(true);
                                    }
                                }}
                            >
                                <img
                                    src={require('../../img/icons/unlock.png')}
                                    alt="unlock icon"
                                    style={{
                                        width: '20px', // Adjust icon size
                                        height: '20px', // Maintain aspect ratio
                                    }}
                                />
                                {t('NewRequestListings.Unlock Now with 1 Credit')}
                            </Button>
                        </Row>

                        {/* Confirm Modal */}
                        <Modal show={isModalVisible} onHide={() => setModalVisible(false)} centered>
                            <Modal.Body style={{ textAlign: "start", padding: "30px" }}>

                                <div style={{ fontWeight: "bold", marginBottom: "20px", fontSize: '1.1em', color: COLORS.darkGray }}>
                                    {t('NewRequestListings.Confirm Title')}
                                </div>

                                <p style={{ fontSize: "1em", marginBottom: "30px", color: COLORS.darkGray }}>
                                    {t("NewRequestListings.Confirm Message")}
                                </p>

                                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                                    <Button
                                        variant="secondary"
                                        onClick={() => setModalVisible(false)}
                                        style={{
                                            backgroundColor: COLORS.white,
                                            color: COLORS.darkGray,
                                            border: "none",
                                            padding: "10px 20px",
                                            fontWeight: "bold",
                                            fontSize: '1em',
                                        }}
                                    >
                                        {t("NewRequestListings.Cancel")}
                                    </Button>
                                    <Button
                                        variant="primary"
                                        onClick={() => {
                                            handleConfirmSpentCredit()
                                        }}
                                        style={{
                                            backgroundColor: COLORS.orange,
                                            color: COLORS.white,
                                            border: "none",
                                            padding: "10px 20px",
                                            fontWeight: "bold",
                                            fontSize: '1em',
                                        }}
                                    >
                                        {t("NewRequestListings.Confirm")}
                                    </Button>
                                </div>
                            </Modal.Body>
                        </Modal>

                        {/* Insufficient Credit Modal */}
                        <Modal show={isInsufficientModalVisible} onHide={() => setInsufficientModalVisible(false)} centered>
                            <Modal.Body style={{ textAlign: "start", padding: "30px" }}>

                                <div style={{ fontWeight: "bold", marginBottom: "20px", fontSize: '1.1em', color: COLORS.darkGray }}>
                                    {t('NewRequestListings.Insufficient Credit Title')}
                                </div>

                                <p style={{ fontSize: "1em", marginBottom: "30px", color: COLORS.darkGray }}>
                                    {t("NewRequestListings.Insufficient Credit Message")}
                                </p>

                                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                                    <Button
                                        variant="secondary"
                                        onClick={() => setInsufficientModalVisible(false)}
                                        style={{
                                            backgroundColor: COLORS.white,
                                            color: COLORS.darkGray,
                                            border: "none",
                                            padding: "10px 20px",
                                            fontWeight: "bold",
                                            fontSize: '1em',
                                        }}
                                    >
                                        {t("NewRequestListings.Cancel")}
                                    </Button>
                                    <Button
                                        variant="primary"
                                        onClick={handleNavigateToBecomeAnAdvisor}
                                        style={{
                                            backgroundColor: COLORS.orange,
                                            color: COLORS.white,
                                            border: "none",
                                            padding: "10px 20px",
                                            fontWeight: "bold",
                                            fontSize: '1em',
                                        }}
                                    >
                                        {t("NewRequestListings.Upgrade Now")}
                                    </Button>
                                </div>
                            </Modal.Body>
                        </Modal>
                    </div>
                ) : (
                    <div>
                        {/* If showModal is false */}
                        <div className="p-3" style={{ backgroundColor: COLORS.lightGrayAccount, borderRadius: 5, flexDirection: 'column' }}>
                            {/* Status Box */}
                            <div
                                style={{
                                    backgroundColor: currentStatus.backgroundColor,
                                    color: currentStatus.textColor,
                                    padding: '5px 10px',
                                    borderRadius: '10px',
                                    textAlign: 'center',
                                    marginBottom: '5px',
                                    display: 'inline-block',
                                }}
                            >
                                <span style={{ fontSize: '0.85em', fontWeight: 'bold' }}>{currentStatus.text}</span>
                            </div>

                            {/* Request Information */}
                            <div style={{ fontSize: '1em', fontWeight: 'bold' }}>
                                {t('NewRequestListings.Request No')} {requestData?.RequestIdNo || 'N/A'}
                            </div>
                            <div style={{ fontSize: '1em', fontWeight: 'bold' }}>
                                {t('NewRequestListings.Request date')} {requestData?.RequestDate || 'N/A'}
                            </div>
                        </div>

                        {/* Request Details */}
                        <div style={{ marginTop: '20px' }}>
                            <div className="feature-item">
                                <img
                                    src={require('../../img/icons/user.png')}
                                    alt="user icon"
                                    className="feature-icon"
                                />
                                {requestData?.PosterName || 'N/A'}
                            </div>
                            <div className="feature-item">
                                <img
                                    src={require('../../img/icons/categories.png')}
                                    alt="category icon"
                                    className="feature-icon"
                                />
                                {requestData?.Category || 'N/A'}
                            </div>
                            <div className="feature-item">
                                <img
                                    src={require('../../img/icons/location.png')}
                                    alt="location icon"
                                    className="feature-icon"
                                />
                                {requestData?.Address || 'N/A'}
                            </div>
                            <div className="feature-item">
                                <img
                                    src={require('../../img/icons/description.png')}
                                    alt="description icon"
                                    className="feature-icon"
                                />
                                {requestData?.Description || 'N/A'}
                            </div>
                        </div>

                        {tab === 'Job Request' && requestData?.ListingFiles?.length > 0 && (
                            <div style={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
                                <img
                                    src={require('../../img/icons/attachment.png')}
                                    style={{ width: 20, height: 20, marginRight: 10, filter: 'brightness(0.9)' }}
                                    alt="Attachment Icon"
                                />
                                <div style={{ display: 'flex', gap: '10px', flexWrap: 'wrap' }}>
                                    {requestData.ListingFiles.map((file, index) => (
                                        <div key={index} style={{
                                            width: '95px',
                                            height: '95px',
                                            backgroundColor: '#f0f0f0',
                                            borderRadius: '5px',
                                            overflow: 'hidden',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            cursor: 'pointer',
                                            position: 'relative',
                                        }}>
                                            {file.endsWith('.pdf') ? (
                                                <img
                                                    src={require('../../img/icons/pdf-icon.jpeg')}
                                                    alt="PDF Icon"
                                                    style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                                                    onClick={() => {
                                                        window.open(file, '_blank');
                                                    }}
                                                />
                                            ) : (
                                                <img
                                                    src={file}
                                                    alt="Uploaded Image"
                                                    style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                                                    onClick={() => openFullScreen(file)}
                                                />
                                            )}
                                            <Modal show={showFullScreen} onHide={closeFullScreen} centered>
                                                <Modal.Body>
                                                    {selectedImage && (
                                                        <img
                                                            src={selectedImage}
                                                            alt="Full Screen Image"
                                                            style={{ width: '100%', maxHeight: '80vh' }}
                                                        />
                                                    )}
                                                </Modal.Body>
                                            </Modal>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        )}


                        <Row className="mt-2" style={{ marginBottom: '3%', paddingTop: '3%' }}>
                            <Col>
                                <button
                                    type="button"
                                    className="input-group btn"
                                    style={{
                                        backgroundColor: COLORS.white,
                                        color: COLORS.black,
                                        borderWidth: 1,
                                        borderColor: COLORS.black,
                                        fontWeight: 'bold',
                                        fontSize: '1em',
                                        padding: '10px',
                                    }}
                                    onClick={() => {
                                        navigate(`/myProfile?userId=${targetUserId}`)
                                    }}
                                >
                                    {t('NewRequestDetails.View Profile')}
                                </button>
                            </Col>

                            <Col>
                                <button
                                    type="button"
                                    className="input-group btn"
                                    style={{
                                        backgroundColor: COLORS.orange,
                                        color: COLORS.white,
                                        borderWidth: 1,
                                        borderColor: COLORS.orange,
                                        fontWeight: 'bold',
                                        fontSize: '1em',
                                        padding: '10px',
                                    }}
                                    onClick={() => {
                                        let currentTime = new Date().getTime(); //epoch timestamp now
                                        const resultEncrypted = SHA256(UserId + process.env.REACT_APP_COMMUNITY_APP_KEY + "Adviser2u" + currentTime);
                                        navigate(`/message?userId=${UserId}&messageId=${targetUserId}&uid=${resultEncrypted}&tid=${currentTime}`)
                                    }}
                                >
                                    {t('NewRequestDetails.Chat')}
                                </button>
                            </Col>
                        </Row>
                    </div>
                )}
            </div>
        );
    }

    const renderTabs = () => (
        <Row className="mt-3 g-0">
            <Col xs={6} md={6} lg={6} className="d-flex">
                <button
                    type="button"
                    className="input-group btn"
                    style={{
                        backgroundColor: COLORS.white,
                        color: tab === 'Request' ? COLORS.orange : COLORS.gray,
                        borderBottomWidth: tab === 'Request' ? '2px' : '1px',
                        borderBottomColor: tab === 'Request' ? COLORS.orange : COLORS.lightGray1,
                        fontWeight: tab === 'Request' ? 'bold' : 'normal',
                        padding: '10px 20px',
                        width: '100%',
                        textAlign: 'center',
                        borderRadius: 0
                    }}
                    onClick={() => setTab('Request')}
                >
                    <span style={{ fontSize: '1em' }}>{t('NewRequestListings.Request')}</span>
                </button>
            </Col>
            <Col xs={6} md={6} lg={6} className="d-flex">
                <button
                    type="button"
                    className="input-group btn"
                    style={{
                        backgroundColor: COLORS.white,
                        color: tab === 'Job Request' ? COLORS.orange : COLORS.gray,
                        borderBottomWidth: tab === 'Job Request' ? '2px' : '1px',
                        borderBottomColor: tab === 'Job Request' ? COLORS.orange : COLORS.lightGray1,
                        fontWeight: tab === 'Job Request' ? 'bold' : 'normal',
                        padding: '10px 20px',
                        width: '100%',
                        textAlign: 'center',
                        borderRadius: 0
                    }}
                    onClick={() => setTab('Job Request')}
                >
                    <span style={{ fontSize: '1em' }}>{t('NewRequestListings.Job Request')}</span>
                </button>
            </Col>
        </Row>
    );


    useEffect(() => {
        setRequestListing([]);
        if (tab === 'Request') {
            setSelectedRequest(false);
            fetchRequestListing("NORMAL");
        } else if (tab === 'Job Request') {
            setSelectedRequest(false);
            fetchRequestListing("JOB");
        }
    }, [tab]);

    return (
        <Container fluid style={{ backgroundColor: COLORS.lightGray4, padding: "20px" }}>
            <Row className="justify-content-center">
                {/* Left Panel: Requests List */}
                <Col
                    xs={12}
                    lg={4}
                    style={{
                        paddingLeft: "calc(0.2rem + 0.5vw)",
                    }}
                >
                    <div
                        style={{
                            backgroundColor: "#F8F8F8",
                            paddingTop: "2%",
                            minHeight: "80vh",
                        }}
                    >
                        <Container className="box-container p-2">
                            <Row>
                                <Col>{renderTabs()}</Col>
                            </Row>

                            <div
                                style={{
                                    height: "82vh",
                                    overflowY: "auto", // Only vertical scrolling
                                    overflowX: "hidden", // Prevent horizontal scrolling
                                    backgroundColor: COLORS.white,
                                    borderRadius: "10px",
                                    padding: "10px", // Consistent padding
                                }}
                            >
                                {isLoading ? (
                                    <div
                                        style={{
                                            flex: 1,
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            flexDirection: "column",
                                            height: "100%",
                                        }}
                                    >
                                        <div
                                            style={{
                                                width: "50px",
                                                height: "50px",
                                                border: "5px solid rgba(0, 0, 0, 0.1)",
                                                borderTop: "5px solid red",
                                                borderRadius: "50%",
                                                animation: "spin 1s linear infinite",
                                            }}
                                        />
                                        <p style={{ marginTop: 10, color: "gray" }}>{t('advisorDetails.Loading...')}</p>
                                    </div>
                                ) : requestListing.length > 0 ? (
                                    requestListing.map(renderRequestCard)
                                ) : (
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            height: "100%",
                                            textAlign: "center",
                                            color: COLORS.gray,
                                        }}
                                    >
                                        <p style={{ fontSize: "1em", fontWeight: "bold" }}>
                                            {t('NewRequestListings.No Requests Yet')}
                                        </p>
                                        <p style={{ fontSize: "0.9em" }}>
                                            {t('NewRequestListings.No Requests Message')}
                                        </p>
                                    </div>
                                )}
                            </div>
                        </Container>
                    </div>
                </Col>

                {/* Right Panel: Request Details */}
                <Col
                    xs={12}
                    lg={7}
                    style={{
                        paddingRight: "calc(0.2rem + 0.5vw)",
                    }}
                >
                    <div
                        style={{
                            backgroundColor: COLORS.white,
                            borderRadius: "5px",
                            boxShadow: "0 2px 5px rgba(0,0,0,0.1)",
                            marginTop: "1%",
                            minHeight: selectedRequest ? "auto" : "90vh",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: selectedRequest ? "flex-start" : "center",
                            alignItems: "center",
                            overflow: "hidden",
                            padding: "20px",
                        }}
                    >
                        {isLoading ? (
                            // Loading State
                            <div
                                style={{
                                    flex: 1,
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    flexDirection: "column",
                                    height: "100%",
                                }}
                            >
                                <div
                                    style={{
                                        width: "50px",
                                        height: "50px",
                                        border: "5px solid rgba(0, 0, 0, 0.1)",
                                        borderTop: "5px solid red",
                                        borderRadius: "50%",
                                        animation: "spin 1s linear infinite",
                                    }}
                                />
                                <p style={{ marginTop: 10, color: "gray" }}>{t('advisorDetails.Loading...')}</p>
                            </div>
                        ) : !requestListing.length ? (
                            // No Data State
                            <div style={{ textAlign: "center", padding: "20px" }}>
                                <p style={{ fontSize: "0.9em" }}>
                                    {t('NewRequestListings.Select Request Prompt')}
                                </p>
                            </div>
                        ) : (
                            // Data Loaded State
                            renderRequestDetails()
                        )}
                    </div>

                </Col>
            </Row>
        </Container>
    )
}

export default NewRequestListings