import React from 'react'
import { Container, Row, Col, Button, NavDropdown, Modal, Image } from 'react-bootstrap';
import { useDispatch, useSelector } from "react-redux";
import domainName from '../../domainName';
import { COLORS, FONTS, SIZES } from '../../colorTheme/colorTheme';
import { useEffect, useState } from 'react';
import ReactStarRatings from 'react-star-ratings';
import queryString from 'query-string';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import AdvisorPanel from '../../components/AdvisorPanel';
import ConsumerPanel from '../../components/ConsumerPanel';

const MyReviews = () => {
    const navigate = useNavigate();

    const { token, UserData, UserId } = useSelector((state) => state.login);
    const advisorState = JSON.parse(localStorage.getItem('advisorState'));

    // set data from API
    const [postReviewListing, setPostReviewListing] = useState([])
    const [receivedReviewListing, setReceivedReviewListing] = useState([])
    const [userRatingDetails, setUserRatingDetails] = useState()

    //tab selection
    const [tabReceived, setTabReceived] = useState(true)
    const [tabSent, setTabSent] = useState(false)

    const [t, i18n] = useTranslation("global");

    async function getUserPostReviewListing(targetUserId) {

        await domainName
            .serverAPICall(
                domainName.debugAPI + "/user/userPostReviewListing",
                "POST",
                JSON.stringify({
                    "UserId": UserId,
                    "TargetUserId": targetUserId != undefined ? targetUserId : null,
                    "PageNum": 1,
                    "ResultNum": 10
                }),
                // userState.UserId, // user id, wait binglun settle backend
                //userState.latitude, // latitude
                //userState.longitude // longitude
            )
            .then(domainName.handleServerAPICallErrors)
            .then((responseJson => {
                //console.log('/user/userPostReviewListing : response : ', responseJson.Data.PostReviewListing)
                setPostReviewListing(responseJson.Data.PostReviewListing)
            }))
            .catch((error) => {
                //console.log('/user/userPostReviewListing : error : ', error)
            });
    }

    async function getReceivedReviewListing(reviewUserId) {

        await domainName
            .serverAPICall(
                domainName.debugAPI + "/user/userReceivedReviewListing",
                "POST",
                JSON.stringify({
                    "UserId": UserId,
                    "ReviewerUserId": reviewUserId != undefined ? reviewUserId : null,
                    "PageNum": 1,
                    "ResultNum": 10
                }),
                // userState.UserId, // user id, wait binglun settle backend
                //userState.latitude, // latitude
                //userState.longitude // longitude
            )
            .then(domainName.handleServerAPICallErrors)
            .then((responseJson => {
                //console.log('/user/userReceivedReviewListing : response : ', responseJson.Data.ReceivedReviewListing)
                setReceivedReviewListing(responseJson.Data.ReceivedReviewListing)
                setUserRatingDetails(responseJson.Data.UserRatingDetails)
            }))
            .catch((error) => {
                //console.log('/user/userReceivedReviewListing : error : ', error)
            });
    }

    function renderTabSelection() {
        return (
            <Row className='mt-3'>
                <Col xs={6} md={6} lg={6} className="d-flex" style={{ paddingRight: 0 }}>
                    <button type="button" class="input-group btn" style={{
                        backgroundColor: COLORS.white,
                        color: tabReceived ? COLORS.orange : COLORS.black,
                        borderBottomWidth: tabReceived ? 1 : 0,
                        borderBottomColor: COLORS.orange,
                        borderRadius: 0
                    }} onClick={() => { setTabReceived(true); setTabSent(false) }}>
                        {t('MyReviews.Receive')}
                    </button>
                </Col>
                <Col xs={6} md={6} lg={6} className="d-flex" style={{ paddingLeft: 0 }}>
                    <button type="button" class="input-group btn" style={{
                        backgroundColor: COLORS.white,
                        color: tabSent ? COLORS.orange : COLORS.black,
                        borderBottomWidth: tabSent ? 1 : 0,
                        borderBottomColor: COLORS.orange,
                        borderRadius: 0
                    }} onClick={() => { setTabReceived(false); setTabSent(true) }}>
                        {t('MyReviews.sent')}
                    </button>
                </Col>
            </Row>
        )
    }

    function renderReviewCardForSentTab(reviewitem) {
        return (
            <div>
                <div style={{ marginTop: '5%', cursor: 'pointer' }} onClick={() => {
                    navigate(`/myProfile?userId=${reviewitem.TargetUserId}`)
                }}>
                    <Row className="my-3 align-items-center d-flex">
                        <Col xs="auto" className="d-flex align-items-center">
                            <Image 
                                src={reviewitem.TargetUserImage} 
                                width={50} 
                                height={50} 
                                style={{ borderRadius: '50%' }} 
                            />
                        </Col>

                        <Col xs className="text-truncate">
                            {reviewitem.TargetUserName}
                        </Col>

                        <Col xs="auto" className="text-end text-truncate">
                            {reviewitem.CreatedTimestampDisplay}
                        </Col>
                    </Row>
                    
                    <Row className="my-3">
                        <Col>
                            <ReactStarRatings
                                rating={reviewitem.Likes}  // Set the initial rating value
                                starRatedColor={COLORS.orange}  // Customize the color of the filled-in stars
                                numberOfStars={5}  // Set the total number of stars to display
                                starDimension="20px"  // Set the size of each star
                                starSpacing="1px"  // Set the spacing between each sta
                            />
                        </Col>
                    </Row>
                    {
                        reviewitem.DisplayAttachmentPhoto.length > 0 &&
                        <Row className="my-3">
                            <Image
                                src={reviewitem.DisplayAttachmentPhoto[0]}
                                style={{
                                    width: 130, height: 100
                                }}
                            />
                        </Row>
                    }
                    <Row className="my-3">
                        <Col>{reviewitem.ReviewMessage}</Col>
                    </Row>
                </div>
                <Row className='mt-2'>
                    <button type="button" class="input-group btn" style={{
                        backgroundColor: COLORS.white,
                        color: COLORS.black,
                        borderWidth: 1,
                        borderColor: COLORS.black
                    }} onClick={() => {
                        //console.log(reviewitem)
                        navigate(`/reviewrating?${queryString.stringify({
                            imageDisplay: reviewitem.TargetUserImage,
                            name: reviewitem.TargetUserName,
                            ratingStar: reviewitem.Likes,
                            description: reviewitem.ReviewMessage,
                            targetUserId: reviewitem.TargetUserId,
                            attachmentPhotos: reviewitem.AttachmentPhoto,
                            attachmentPhotosURL: reviewitem.DisplayAttachmentPhoto[0]
                        })}`)
                    }}>
                        {t('MyReviews.Edit')}
                    </button>
                </Row>
            </div>
        )
    }

    function renderReviewCard(reviewitem) {
        return (
            <div style={{ marginTop: '5%', cursor: 'pointer' }} onClick={() => {
                navigate(`/myProfile?userId=${reviewitem.ReviewerUserId}`)
            }}>
                <Row className="my-3 align-items-center d-flex">
                    <Col xs="auto" className="d-flex align-items-center">
                        <Image 
                            src={reviewitem.ReviewerImage} 
                            width={50} 
                            height={50} 
                            style={{ borderRadius: '50%' }} 
                        />
                    </Col>

                    <Col xs className="text-truncate">
                        {reviewitem.ReviewerName}
                    </Col>

                    <Col xs="auto" className="text-end text-truncate">
                        {reviewitem.CreatedTimestampDisplay}
                    </Col>
                </Row>

                <Row className="my-3">
                    <Col>
                        <ReactStarRatings
                            rating={reviewitem.Likes}  // Set the initial rating value
                            starRatedColor={COLORS.orange}  // Customize the color of the filled-in stars
                            numberOfStars={5}  // Set the total number of stars to display
                            starDimension="20px"  // Set the size of each star
                            starSpacing="1px"  // Set the spacing between each sta
                        />
                    </Col>
                </Row>
                {
                    reviewitem.DisplayAttachmentPhoto.length > 0 &&
                    <Row className="my-3">
                        <Image
                            src={reviewitem.DisplayAttachmentPhoto[0]}
                            style={{
                                width: 130, height: 100
                            }}
                        />
                    </Row>
                }

                <Row className="my-3">
                    <Col>{reviewitem.ReviewMessage}</Col>
                </Row>
            </div>
        )
    }

    function renderSentListing() {
        return (
            <div style={{padding:'15px'}}>
                {
                    postReviewListing == "" ?
                        <div style={{ backgroundColor: COLORS.lightGray4, textAlign: 'center', marginTop: '2%'}}>
                            <div style={{ paddingTop: '3%' }}>
                                <img 
                                    src={require("../../img/icons/myreview.png")} 
                                    alt="My Review Icon" 
                                    style={{ mixBlendMode: 'multiply', width: '80px', height: 'auto' }}
                                />
                            </div>
                            <div style={{ paddingTop: '2%', paddingBottom: '3%', color: COLORS.black }}>{t("MyReviews.NoReview")}</div>
                        </div>
                        : postReviewListing.map((item, index) => (
                            renderReviewCardForSentTab(item)
                        ))
                }
            </div>
        )
    }


    function renderReceivedListing() {
        return (
            <div>
                <Row className="justify-content-center align-items-center">
                    <Col className="col-lg-12">
                        <Container className="p-5">
                            <div style={{ fontSize: "1.8em", fontWeight: 'bold', textAlign: 'center' }}>
                                {userRatingDetails?.UserRating}
                            </div>

                            <Row>
                                <Col className="d-flex justify-content-center">
                                    <ReactStarRatings
                                        rating={userRatingDetails?.UserRating}  // Set the initial rating value
                                        starRatedColor={COLORS.orange}  // Customize the color of the filled-in stars
                                        numberOfStars={5}  // Set the total number of stars to display
                                        starDimension="20px"  // Set the size of each star
                                        starSpacing="1px"  // Set the spacing between each sta
                                    />
                                </Col>
                            </Row>

                            <div style={{ fontSize: "1.1em", fontWeight: 'bold', textAlign: 'center', marginTop: "15px", }}>
                                {t('MyReviews.based on')} {userRatingDetails?.UserReviewListCount} {t('MyReviews.reviews')}
                            </div>
   
                        </Container>
                    </Col>
                </Row>
                <div style={{padding:'15px'}}>
                    {               
                        receivedReviewListing == "" ?
                            <div style={{ backgroundColor: COLORS.lightGray4, textAlign: 'center', marginTop: '2%'}}>
                                <div style={{ paddingTop: '3%' }}>
                                    <img 
                                        src={require("../../img/icons/myreview.png")} 
                                        alt="My Review Icon" 
                                        style={{ mixBlendMode: 'multiply', width: '80px', height: 'auto' }}
                                    />
                                </div>
                                <div style={{ paddingTop: '2%', paddingBottom: '3%', color: COLORS.black }}>{t("MyReviews.NoReview")}</div>
                            </div>
                            : receivedReviewListing.map((item, index) => (
                                renderReviewCard(item)
                            ))
                    }
                </div>
            </div>
        )
    }

    useEffect(() => {
        // Get the URLSearchParams object from the current URL
        const searchParams = new URLSearchParams(window.location.search);

        // Get the value of the 'serviceid' parameter
        const tab = searchParams.get('tab');

        if (tab == "1") {
            setTabSent(true)
            setTabReceived(false)

        }

        getUserPostReviewListing()
        getReceivedReviewListing()
    }, [])

    return (
        <Container fluid style={{ backgroundColor: COLORS.lightGray4, padding: "20px" }}>
            <Row className="justify-content-center">
                {/* Left Panel */}
                <Col xs={12} lg={3} style={{ paddingTop: "20px" }} className="d-none d-lg-block" >
                    <div
                        style={{
                            backgroundColor: COLORS.white,
                            borderRadius: "8px",
                            padding: "20px",
                            boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
                            minHeight: "80vh",
                        }}
                    >
                        {advisorState.advisor == true && (
                            <AdvisorPanel
                                userId={UserId}
                                activePage="myReviews"
                            />
                        )}
                        {advisorState.advisor == false && (
                            <ConsumerPanel
                                userId={UserId}
                                activePage="myReviews"
                            />
                        )}
                    </div>
                </Col>
    
                {/* Right Panel */}
                <Col xs={12} lg={8} style={{ paddingTop: "20px" }}>
                    <div
                        style={{
                            backgroundColor: COLORS.white,
                            borderRadius: "5px",
                            boxShadow: "0 2px 5px rgba(0,0,0,0.1)",
                            minHeight: "90vh",
                            height: 'auto',
                            paddingTop: "5px",
                            paddingLeft: "15px",
                            paddingRight: "15px"  
                        }}
                    >
                        <Row>
                            <Col className="p-2">
                                <div style={{ paddingLeft: "10px", paddingTop: "15px", paddingBottom: "10px", fontSize: "1.2em", fontWeight: 'bold'}}>{t('MyReviews.My Reviews')}</div>
                            </Col>
                        </Row>
                        
                        {renderTabSelection()}
                        {tabReceived ? renderReceivedListing() : renderSentListing()}
                    </div>
                </Col>
            </Row>
        </Container>
    );

}

export default MyReviews