import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Image } from "react-bootstrap";
import { COLORS } from "../colorTheme/colorTheme";
import { useTranslation } from "react-i18next";
import domainName from "../domainName";

const AdvisorPanel = ({ userId, activePage }) => {

    const [t] = useTranslation("global");

    const [displayUserName, setDisplayUserName] = useState("");
    const [displayImage, setDisplayImage] = useState("");
    const [displayFollowers, setDisplayFollowers] = useState("");
    const [displayFollowing, setDisplayFollowing] = useState("");

    const [loading, setLoading] = useState(false);

    const fetchUserInfo = async () => {
        setLoading(true);

        try {
            const response = await domainName.serverAPICall(
                `${domainName.debugAPI}/user/meMenu`,
                "POST",
                JSON.stringify({
                    UserId: userId,
                    DisplayScreen: "User",
                })
            );

            const data = await response.json();
            setDisplayUserName(data.Data.UserName);
            setDisplayImage(data.Data.UserImage);
            setDisplayFollowers(data.Data.TotalFollower);
            setDisplayFollowing(data.Data.TotalFollowing);
        } catch (error) {
            console.error("Error fetching user info:", error);
        } finally {
            setLoading(false);
        }
    };

    const renderLoadingSpinner = (message) => {
        return (
            <div
                style={{
                    flex: 1,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    height: "100%",
                }}
            >
                <div
                    style={{
                        width: "50px",
                        height: "50px",
                        border: "5px solid rgba(0, 0, 0, 0.1)",
                        borderTop: "5px solid red",
                        borderRadius: "50%",
                        animation: "spin 1s linear infinite",
                    }}
                />
                <p style={{ marginTop: 10, color: "gray" }}>{message}</p>
            </div>
        );
    };

    const renderGreyLine = () => (
        <div
            style={{
                borderBottom: "1px solid #D8D8D8",
                marginTop: "2em",
                marginBottom: "2em",
            }}
        />
    );

    const renderProfileSection = () => (
        <div style={{ marginTop: "15px" }}>
            <div style={{ marginBottom: "2em", marginRight: "5%" }}>
                <Link to={`/myProfile?userId=${userId}`} style={{ color: COLORS.gray, textDecoration: "none" }}>
                    <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                        <strong style={{ marginRight: "0.5em" }}>{t("account.My Profile")}</strong>
                        <Image
                            src={require("../img/icons/arrow.png")}
                            width={10}
                            height={15}
                        />
                    </div>
                </Link>
            </div>

            <div className="d-flex flex-column align-items-center">
                <Image
                    src={displayImage}
                    width={110}
                    height={110}
                    roundedCircle
                    style={{ marginBottom: "1em" }}
                />
                <div
                    style={{
                        fontSize: "1.2em",
                        fontWeight: "bold",
                        maxWidth: "200px",
                        display: "-webkit-box",
                        WebkitBoxOrient: "vertical",
                        WebkitLineClamp: 2,
                        overflow: "hidden",
                        textAlign: "center",
                    }}
                    title={displayUserName}
                >
                    {displayUserName}
                </div>
                <div style={{ fontSize: "0.85em", marginTop: "0.5em" }}>
                    {t("account.Followers")} <strong>{displayFollowers}</strong>
                    <span style={{ margin: "0 0.5em" }}>|</span>
                    {t("account.Following")} <strong>{displayFollowing}</strong>
                </div>
            </div>
        </div>
    );

    const renderBusinessNavigation = () => (
        <div style={{ marginLeft: "1em" }}>
            <h6 className="fw-bold">{t("SecondAccountSetting.My Business")}</h6>
            <Link style={{ textDecoration: "none", color: activePage === "myCredits" ? COLORS.orange : COLORS.gray }} to={`/myCreditsAdvisor`}>
                <div className="d-flex align-items-center mt-3">
                    <Image
                        src={require(`../img/icons/${activePage === "myCredits" ? "mycredits_active.png" : "mycredits.png"}`)}
                        width={25}
                        height={25}
                        className="me-2"
                    />
                    <span style={{ fontSize: "1.2em" }}>{t("SecondAccountSetting.My Credits")}</span>
                </div>
            </Link>
            <Link style={{ textDecoration: "none", color: activePage === "bookingsAdvisor" ? COLORS.orange : COLORS.gray }} to={`/bookinglistAdvisor`} >
                <div className="d-flex align-items-center mt-3">
                    <Image
                        src={require(`../img/icons/${activePage === "bookingsAdvisor" ? "booking2_active.png" : "booking2.png"}`)}
                        width={25}
                        height={25}
                        className="me-2"
                    />
                    <span style={{ fontSize: "1.2em" }}>{t("SecondAccountSetting.My Bookings")}</span>
                </div>
            </Link>
            <Link style={{ textDecorationLine: "none", color: activePage === "myListings" ? COLORS.orange : COLORS.gray }} to={`/myListingsAdvisor`}>
                <div className="d-flex align-items-center mt-3">
                    <Image
                        src={require(`../img/icons/${activePage === "myListings" ? "mylistings_active.png" : "mylistings.png"}`)}
                        width={25}
                        height={25}
                        className="me-2"
                    />
                    <span style={{ fontSize: "1.2em" }}>{t("SecondAccountSetting.My Listings")}</span>
                </div>
            </Link>
            <Link style={{ textDecorationLine: "none", color: activePage === "myReviews" ? COLORS.orange : COLORS.gray }} to={`/myReview`}>
                <div className="d-flex align-items-center mt-3" style={{ marginBottom: "3em" }}>
                    <Image
                        src={require(`../img/icons/${activePage === "myReviews" ? "myreview_active.png" : "myreview2.png"}`)}
                        width={25}
                        height={25}
                        className="me-2"
                    />
                    <span style={{ fontSize: "1.2em" }}>{t("SecondAccountSetting.My Reviews")}</span>
                </div>
            </Link>
        </div>
    );

    const renderAccountSettings = () => (
        <div style={{ marginLeft: "1em" }}>
            <h6 className="fw-bold">{t("SecondAccountSetting.Account Settings")}</h6>
            <Link style={{ textDecoration: "none", color: activePage === "accountAndSecurity" ? COLORS.orange : COLORS.gray }} to={`/accountAndSecurity`}>
                <div className="d-flex align-items-center mt-3">
                    <Image
                        src={require(`../img/icons/${activePage === "accountAndSecurity" ? "accountSecurity_active.png" : "accountSecurity2.png"}`)}
                        width={25}
                        height={25}
                        className="me-2"
                    />
                    <span style={{ fontSize: "1.2em" }}>{t("SecondAccountSetting.Account and Security")}</span>
                </div>
            </Link>
            <Link style={{ textDecoration: "none", color: activePage === "referReward" ? COLORS.orange : COLORS.gray }} to={`/referReward`} >
                <div className="d-flex align-items-center mt-3">
                    <Image
                        src={require(`../img/icons/${activePage === "referReward" ? "referRewards_active.png" : "referRewards2.png"}`)}
                        width={25}
                        height={25}
                        className="me-2"
                    />
                    <span style={{ fontSize: "1.2em" }}>{t("SecondAccountSetting.Refer and Rewards")}</span>
                </div>
            </Link>
            <Link style={{ textDecorationLine: "none", color: activePage === "subscription" ? COLORS.orange : COLORS.gray }} to={`/subscription`}>
                <div className="d-flex align-items-center mt-3" style={{ marginBottom: "3em" }}>
                    <Image
                        src={require(`../img/icons/${activePage === "subscription" ? "subscription_active.png" : "subscription2.png"}`)}
                        width={25}
                        height={25}
                        className="me-2"
                    />
                    <span style={{ fontSize: "1.2em" }}>{t("SecondAccountSetting.Subscription")}</span>
                </div>
            </Link>
        </div>
    );

    useEffect(() => {
        if (userId) {
            fetchUserInfo();
        }
    }, [userId]);

    return (
        <>
            {loading ? (
                renderLoadingSpinner(t('advisorDetails.Loading...'))
            ) : (
                <>
                    {renderProfileSection()}
                    {renderGreyLine()}
                    {renderBusinessNavigation()}
                    {renderGreyLine()}
                    {renderAccountSettings()}
                </>
            )}
        </>
    );
};

export default AdvisorPanel;
