import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Container, Row, Col, Button, Image } from "react-bootstrap";
import { COLORS } from "../../colorTheme/colorTheme";
import { useTranslation } from "react-i18next";
import domainName from "../../domainName";
import AdvisorPanel from "../../components/AdvisorPanel";
import ConsumerPanel from "../../components/ConsumerPanel";

const MyCredits = () => {
    const { UserId } = useSelector((state) => state.login);
    const navigate = useNavigate();
    const [t] = useTranslation("global");
    const advisorState = JSON.parse(localStorage.getItem('advisorState'));
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const isMobile = screenWidth <= 768;

    const [modalVisible, setModalVisible] = useState(false);

    const [creditHistory, setCreditHistory] = useState([]);
    const [creditBalance, setCreditBalance] = useState([]);
    const [isUnlimitedCredit, setIsUnlimitedCredit] = useState([]);
    const [renewsAt, setRenewsAt] = useState([]);
    const [isActive, setIsActive] = useState([]);


    const fetchCreditHistory = async () => {
        try {
            await domainName
                .serverAPICall(
                    domainName.debugAPI + "/Adviser_Plan/getCreditHistoryByUserId",
                    "POST",
                    JSON.stringify({
                        "UserId": UserId,
                    })
                )
                .then(domainName.handleServerAPICallErrors)
                .then((responseJson => {
                    // console.log("setCreditHistory ->", responseJson.Data);
                    if (responseJson.Data) {
                        const sortedHistory = responseJson.Data.sort((a, b) => new Date(b.CreatedTimestamp) - new Date(a.CreatedTimestamp));
                        setCreditHistory(sortedHistory);
                        setCreditBalance(responseJson.CreditBalance || 0);
                        setIsUnlimitedCredit(responseJson.IsUnlimitedCredit || false);
                        setRenewsAt(responseJson.RenewsAt || "N/A");
                        setIsActive(responseJson.IsActive || false);
                    } else {
                        setCreditHistory([]);
                        setCreditBalance(0);
                        setIsUnlimitedCredit(false);
                        setRenewsAt("N/A");
                        setIsActive(false);
                    }
                }))
                .catch((error) => {
                    alert(
                        t("error.Oops!"),
                        t("error.An error occurred. Please try again later."),
                        error
                    );
                });
        } catch (error) {
            console.error("Error, Failed to fetch credit history.", error);
        }
    };

    function renderButtonTextWithSpace(text, screenWidth) {
        const maxCharsPerLine = screenWidth <= 768 ? 20 : 40;
        const words = text.split(" ");
        let currentLineLength = 0;
        let formattedText = "";

        for (let i = 0; i < words.length; i++) {
            const word = words[i];
            if (currentLineLength + word.length <= maxCharsPerLine) {
                formattedText += word + " ";
                currentLineLength += word.length + 1;
            } else {
                formattedText += "\n" + word + " ";
                currentLineLength = word.length + 1;
            }
        }

        return formattedText.trim();
    }


    function renderCreditBalanceSection() {
        return (
            <div
                style={{
                    backgroundImage: `url(${require("../../img/icons/credit_bg.png")})`,
                    backgroundSize: "contain", // Keep the original size of the image
                    backgroundRepeat: "no-repeat", // Prevent the image from repeating
                    backgroundPosition: "right", // Center the image
                    backgroundColor: COLORS.orange3, // Fill uncovered areas with orange 
                    color: "white",
                    borderRadius: "10px",
                    padding: isMobile ? "10px" : "20px",
                    marginBottom: "20px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    minHeight: isMobile ? "100px" : "120px",
                }}
            >
                <div className="d-flex justify-content-between align-items-center">
                    {isActive ? (
                        <div>
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    fontSize: isMobile ? "1em" : "1.2em",
                                    paddingLeft: "10px",
                                }}
                            >
                                <span style={{ marginRight: isMobile ? "5px" : "10px", }}>{t("MyCredits.My Credit Balance")}</span>
                                <div
                                    style={{
                                        position: "relative", // Make this container the positioning context
                                        display: "inline-block",
                                    }}
                                >
                                    <Image
                                        src={require("../../img/icons/info.png")}
                                        alt="info icon"
                                        style={{
                                            width: isMobile ? "15px" : "20px",
                                            height: isMobile ? "15px" : "20px",
                                            cursor: "pointer",
                                        }}
                                        onClick={() => setModalVisible(!modalVisible)}
                                    />

                                    {modalVisible && (
                                        <div className="credit-menu">
                                            <div className="speech-arrow"></div>
                                            <div style={{ textAlign: "center" }}>
                                                <img
                                                    src={require("../../img/icons/logoUseCreditViewRequest.png")}
                                                    alt="Illustration"
                                                    style={{
                                                        width: "260px",
                                                        height: "260px",
                                                        marginTop: "1em",
                                                        marginBottom: "1em",
                                                    }}
                                                />

                                                <div
                                                    style={{
                                                        fontSize: "0.85em",
                                                        color: COLORS.gray,
                                                        marginBottom: "2em",
                                                        textAlign: "justify",
                                                        textJustify: "inter-word",
                                                    }}
                                                >
                                                    {t("MyCredits.Credit Balance Message")}
                                                </div>

                                                <Button
                                                    style={{
                                                        fontWeight: "bold",
                                                        borderRadius: "5px",
                                                        padding: "10px",
                                                        width: "60%",
                                                        marginBottom: "2em"
                                                    }}
                                                    onClick={() => navigate("/newrequestadvisor")}
                                                >
                                                    {t("MyCredits.View Request")}
                                                </Button>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>

                            <div style={{ fontSize: '1.5em', fontWeight: 'bold', paddingLeft: '10px' }}>
                                {isUnlimitedCredit ? t('MyCredits.unlimited') : creditBalance}
                            </div>
                            <div style={{ fontSize: isMobile ? "1em" : "1.2em", paddingLeft: '10px' }}>
                                {t("MyCredits.Renews At")} {renewsAt}
                            </div>
                        </div>
                    ) : (
                        <div
                            style={{
                                fontSize: isMobile ? "1em" : "1.2em",
                                paddingLeft: "10px",
                            }}
                        >
                            {t("MyCredits.Plan Inactive Message")}
                        </div>
                    )}
                    <div style={{ textAlign: "center" }}>

                        <Button
                            variant="light"
                            style={{
                                backgroundColor: COLORS.white,
                                color: COLORS.black,
                                fontWeight: 600,
                                height: "auto",
                                padding: "10px",
                                fontSize: "1em",
                                textAlign: "center",
                                whiteSpace: "pre-wrap",
                                wordBreak: "normal",
                                marginLeft: 10,
                            }}
                            onClick={() => navigate(`/BecomeAnAdvisor?uid=${UserId}`)}
                        >
                            {renderButtonTextWithSpace(
                                isActive ? t("MyCredits.Get More Credits") : t("MyCredits.Renew Plan"),
                                screenWidth
                            )}
                        </Button>
                    </div>
                </div>
            </div>
        );
    }

    function renderCreditHistory() {
        return (
            <div style={{ paddingRight: "30px", paddingLeft: "30px", paddingBottom: "30px" }}>
                {creditHistory.map((item) => {
                    const date = new Date(item.CreatedTimestamp);

                    // Automatically format based on the user's locale and timezone
                    const formattedDate = date.toLocaleDateString(); // Adapts to the user's regional date format
                    const formattedTime = date.toLocaleTimeString(undefined, {
                        hour: "numeric",
                        minute: "numeric",
                        hour12: true,
                    });
                    return (
                        <div
                            key={item._id}
                            className="d-flex justify-content-between align-items-center"
                            style={{
                                borderBottom: "1px solid #E0E0E0",
                                paddingTop: "1.5em",
                                paddingBottom: "1.5em",
                            }}
                        >
                            <div>
                                <div style={{ fontSize: "1.1em", marginBottom: "5px", fontWeight: "bold" }}>{item.Description}</div>
                                <div style={{ fontSize: "0.9em", color: COLORS.gray }}>{formattedDate}, {formattedTime}</div>
                            </div>
                            {item.AddDeductCredit !== 0 && (
                                <p
                                    style={{
                                        fontSize: "16px",
                                        fontWeight: "bold",
                                        color: item.AddDeductCredit > 0 ? COLORS.green2 : COLORS.black,
                                    }}
                                >
                                    {item.AddDeductCredit > 0
                                        ? `+${item.AddDeductCredit}`
                                        : item.AddDeductCredit}
                                </p>
                            )}
                        </div>
                    );
                })}
            </div>
        );
    }

    useEffect(() => {
        fetchCreditHistory();
    }, []);

    useEffect(() => {
        const handleResize = () => setScreenWidth(window.innerWidth);
        window.addEventListener("resize", handleResize);

        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return (
        <Container fluid style={{ backgroundColor: COLORS.lightGray4, padding: "20px" }}>
            <Row className="justify-content-center">
                {/* Left Panel */}
                <Col xs={12} lg={3} style={{ paddingTop: "20px" }} className="d-none d-lg-block" >
                    <div
                        style={{
                            backgroundColor: COLORS.white,
                            borderRadius: "8px",
                            padding: "20px",
                            boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
                            minHeight: "80vh",
                        }}
                    >
                        {advisorState.advisor == true && (
                            <AdvisorPanel
                                userId={UserId}
                                activePage="myCredits"
                            />
                        )}
                        {advisorState.advisor == false && (
                            <ConsumerPanel
                                userId={UserId}
                                activePage="myCredits"
                            />
                            // <div
                            //     style={{
                            //         display: "flex",
                            //         justifyContent: "center",
                            //         alignItems: "center",
                            //         height: "80vh",
                            //         textAlign: "center",
                            //     }}
                            // >
                            //     {t("subscriptionStepOne.Sign up now to become an Adviser!")}
                            // </div>
                        )}
                    </div>
                </Col>

                {/* Right Panel */}
                <Col xs={12} lg={8} style={{ paddingTop: "20px" }}>
                    <div
                        style={{
                            backgroundColor: COLORS.white,
                            borderRadius: "5px",
                            boxShadow: "0 2px 5px rgba(0,0,0,0.1)",
                            minHeight: "90vh",
                        }}
                    >
                        {renderCreditBalanceSection()}
                        <div style={{ fontSize: "1.2em", paddingLeft: "30px", paddingTop: "15px", color: COLORS.gray }}>
                            {t("MyCredits.Credit History")}
                        </div>
                        {renderCreditHistory()}
                    </div>
                </Col>
            </Row>
        </Container>
    );

};

export default MyCredits;