import React, { useState, useEffect } from 'react';
import domainName from '../../domainName'
import { useDispatch, useSelector } from "react-redux";
import { Container, Row, Col, Image, Button, Card, Modal } from 'react-bootstrap';
import { useLocation, useNavigate } from "react-router-dom";
import queryString from 'query-string';
import './SubscriptionStepOne.css';
import CheckoutForm from '../../components/CheckoutForm';
import { useTranslation } from "react-i18next";


import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import loadingGif from '../../img/loading.gif';

const SubscriptionStepThree = () => {
  const navigate = useNavigate();
  const [isWebsite, setIsWebsite] = useState(window.innerWidth > 768);
  const [stripeCustomer, setStripeCustomer] = useState({})
  const [stripeOption, setStripeOption] = useState({})
  const [stripePromise, setStripePromise] = useState({})

  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();
  const [selectedPlan, setSelectedPlan] = useState({});
  const [renderElements, setRenderElements] = useState(false);

  const [referralCode, setReferralCode] = useState('');

  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [buttonText, setButtonText] = useState('Applied');
  const [inputReadOnly, setInputReadOnly] = useState(false);
  const [haveCard, setHaveCard] = useState(false);
  const [cardUpdate, setCardUpdate] = useState(false);

  const [confirmChangePlanModalShow, setConfirmChangePlanModalShow] = useState(false)

  const [registeredCountryCode, setRegisteredCountryCode] = useState(null);

  const [t, i18n] = useTranslation("global");

  const [pathLang, setPathLang] = useState('');


  const handleInputChange = (event) => {
    setReferralCode(event.target.value);
    if (event.target.value == '') {
      setButtonDisabled(true);
    } else {
      setButtonDisabled(false);
    }

  };

  const handleClearReferralCode = () => {
    setReferralCode('');

    setButtonDisabled(true);
    setButtonText('Applied');
  };

  const proceedToChangePlan = async () => {
    setConfirmChangePlanModalShow(true)
  }

  const callChangePlan = async () => {
    setConfirmChangePlanModalShow(false)
    setIsLoading(true)

    if (selectedPlan.changePlan == 0) { // only when first time user added card but yet to subscribe plan

      console.log('halo 1 referralCode', referralCode)

      await domainName
        .serverAPICall(
          domainName.debugAPI + "/adviser_plan/firstTimeCreateAdvisorWithNoFreeTrial", //This is the first time to create an advisory account without spend money (free plan).
          "POST",
          JSON.stringify({
            "UserId": selectedPlan.uid,
            "PlanId": selectedPlan._id,
            "TotalPostService": selectedPlan.PassInListing,
            "ReferralCode": referralCode == '' ? null : referralCode
          })
        )
        .then(domainName.handleServerAPICallErrors)
        .then((responseJson => {
          setIsLoading(false)
          console.log('firstTimeCreateAdvisorWithNoFreeTrial response?')
          if (responseJson.ReturnCode === 200) {
            // checkout successful
            navigate(`/subscriptionSuccessful?userId=${selectedPlan.uid}&lang=${selectedPlan.lang ? selectedPlan.lang : localStorage.getItem('selectedValue')}`);

            // console.log('responseJson3 ::', responseJson.Data)
          } else {

            if (responseJson.ReturnDesc) {
              console.log('error5')

              alert(
                responseJson.ReturnDesc
              );
            } else {
              alert(
                t("error.Checkout failed")
              );
            }

          }

        }))
        .catch((error) => {
          console.log('error6', error)

          // alert(
          //   "Oops!",
          //   "An error occurred. Please try again later.",
          //   error
          // );

          setIsLoading(false)

        });

    } else {
      let _url = '/adviser_plan/changeAdviserPlanSubscription'

      if (selectedPlan.PlanKey == 'PLAN_FREE') {
        _url = '/adviser_plan/changeAdviserPlanSubscriptionWithoutPayment'
      }
      await domainName
        .serverAPICall(
          domainName.debugAPI + _url,
          "POST",
          JSON.stringify({
            "UserId": selectedPlan.uid,
            "PlanId": selectedPlan._id,
            "TotalPostService": selectedPlan.PassInListing
          }),
          null, null, null, registeredCountryCode
        )
        .then(domainName.handleServerAPICallErrors)
        .then((responseJson => {
          setIsLoading(false)

          if (responseJson.ReturnCode === 200) {
            navigate(`/subscriptionSuccessful?changePlan=${selectedPlan.changePlan}&userId=${selectedPlan.uid}&lang=${selectedPlan.lang ? selectedPlan.lang : localStorage.getItem('selectedValue')}`);
          } else {
            alert(
              t("error.Unable to change plan.")
            );
          }

        }))
        .catch((error) => {
          setIsLoading(false)
          console.log('error7', error)


          // alert(
          //   "Oops!",
          //   "An error occurred. Please try again later.",
          //   error
          // );
        });
    }

  }

  const changeCard = async () => {
    updateCard('/user/updateUserPaymentAccount', selectedPlan.uid, selectedPlan.CountryCode)
  }

  const handleApplyReferralCode = async () => {
    // Submit the referral code
    // Implement your logic here
    ////console.log('Referral code submitted:', referralCode);


    if (referralCode === '') {
      alert(
        t("error.Please enter referral code.")
      );
    } else {
      // here to check the referral code is valid
      await domainName
        .serverAPICall(
          domainName.debugAPI + "/user/checkAdvisorReferralIsValid",
          "POST",
          JSON.stringify({
            "UserId": selectedPlan.uid,
            "ReferralCode": referralCode
          })
        )
        .then(domainName.handleServerAPICallErrors)
        .then((responseJson => {
          ////console.log('checkAdvisorReferralIsValid response?')
          if (responseJson.ReturnCode === 200) {
            // ////console.log('responseJson3 ::', responseJson.Data)
            setButtonDisabled(true);
            setButtonText('Valid');
            setInputReadOnly(true);
          } else {
            alert(
              t("error.Invalid referral code.")
            );
          }

        }))
        .catch((error) => {
          console.log('error8', error)

          alert(
            t("error.Oops!"),
            t("error.An error occurred. Please try again later."),
            error
          );
        });
    }


  };


  const getClientSecret = async (uid, countrycode) => {

    // setStripeOption(options)
    console.log('do here 1 ????', uid)

    // 1. check user have account
    await domainName
      .serverAPICall(
        domainName.debugAPI + "/user/selectUserPaymentAccount",
        "POST",
        JSON.stringify({
          "UserId": uid
        }),
        null, null, null, countrycode
      )
      .then(domainName.handleServerAPICallErrors)
      .then((async responseJson => {
        console.log('responseJson1 ::', responseJson)

        // check if user have added card
        console.log('111')
        if (responseJson.ReturnCode == 200 && responseJson.Data != null) {
          setStripeCustomer(responseJson.Data)
          setHaveCard(true)

        } else {

          console.log('222')

          setHaveCard(false)

          let apiUrl = '/user/insertUserPaymentAccount'

          if (responseJson.ReturnCode == 200 && responseJson.Data == null) {
            apiUrl = '/user/updateUserPaymentAccount'
            ////console.log('do here 2 ????', uid)
          }

          updateCard(apiUrl, uid, countrycode)

        }


      }))
      .catch((error) => {
        console.log('error9', error)

        alert(
          error
        );
      });
  }

  function LoadingModal() {
    return (
      <Modal show={isLoading} backdrop="static" keyboard={false} centered>
        <Modal.Body className="text-center">
          <img src={loadingGif} alt="Loading" />
          <p>{t('subscriptionStepThree.Loading...')}</p>
        </Modal.Body>
      </Modal>
    );
  }

  async function updateCard(apiUrl, uid, countrycode) {

    console.log('apiUrl::', apiUrl)
    console.log('selectedPlan.uid::', uid)
    console.log('selectedPlan.CountryCode::', countrycode)
    await domainName
      .serverAPICall(
        domainName.debugAPI + apiUrl,
        "POST",
        JSON.stringify({
          "UserId": uid
        }), null, null, null, countrycode
      )

      // .serverAPICall(
      //   domainName.debugAPI + apiUrl,
      //   "POST",
      //   JSON.stringify({
      //     "UserId": uid
      //   })
      // )

      .then(domainName.handleServerAPICallErrors)
      .then((responseJson => {
        console.log('sini?')
        if (responseJson.ReturnCode === 200) {
          console.log('responseJson2 ::', responseJson.Data)
          setStripeCustomer(responseJson.Data)

          let options = {
            // passing the client secret obtained from the server
            clientSecret: responseJson.Data.ClientSecret,
          };


          setStripeOption(options)
          setHaveCard(false)
          setRenderElements(true)

        }

      }))
      .catch((error) => {
        console.log('error4', error)


        alert(
          error
        );
      });
  }


  function renderCCBrandIcon(brand) {
    switch (brand) {
      case 'amex':
        return "https://js.stripe.com/v3/fingerprinted/img/amex-a49b82f46c5cd6a96a6e418a6ca1717c.svg";
        break;
      case 'mastercard':
        return "https://js.stripe.com/v3/fingerprinted/img/mastercard-4d8844094130711885b5e41b28c9848f.svg";
        break;
      case 'visa':
        return "https://js.stripe.com/v3/fingerprinted/img/visa-729c05c240c4bdb47b03ac81d9945bfe.svg";
        break;
      default:
        return "";
        break;
    }
  }

  function ConfirmChangePlanModal() {
    return (
      <Modal show={confirmChangePlanModalShow} onHide={() => setConfirmChangePlanModalShow(false)} fade={false}>
        <Modal.Header closeButton>
          <Modal.Title>{t('subscriptionStepThree.Confirmation')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            {selectedPlan.changePlan == 1 ? t('subscriptionStepThree.Change to ') : t('subscriptionStepThree.Subscribe ')}{selectedPlan.Title} {selectedPlan.PassInListing} {t('subscriptionStepThree.listing')}
          </div>

          <div>
            {t('subscriptionStepThree.Total:')}
            {selectedPlan.Currency} {selectedPlan.changePlan == 0 ? '0 (Free trial)' : parseFloat(parseFloat(selectedPlan.OfferPrice) * parseFloat(selectedPlan.PassInListing)).toFixed(2)}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setConfirmChangePlanModalShow(false)}>
            {t('subscriptionStepThree.Cancel')}
          </Button>
          <Button variant="primary" onClick={callChangePlan}>
            {t('subscriptionStepThree.Confirm')}
          </Button>
        </Modal.Footer>
      </Modal>
    )
  }

  const fetchRegisteredAdviser = async (id) => {
    return new Promise(async (resolve, reject) => {
      await domainName
        .serverAPICall(
          domainName.debugAPI + "/user/meMenu",
          "POST",
          JSON.stringify({
            "UserId": id,
            "DisplayScreen": "User"
          })
        )
        .then(domainName.handleServerAPICallErrors)
        .then((responseJson => {
          if (responseJson.ReturnCode == 200) {
            if (responseJson.Data.FirstRegisterReferralCode) {
              setReferralCode(responseJson.Data.FirstRegisterReferralCode)
            }

            if (responseJson.RegisteradivserCountry) {
              resolve(responseJson.RegisteradivserCountry)
            } else {
              resolve(null)
            }

          }
        }))
        .catch((error) => {
          // console.log('/user/meMenu error here', error)
          reject(error)
        });
    })
  }



  useEffect(() => {
    // setIsLoading(true);

    let queryParams = queryString.parse(location.search);
    console.log(queryParams)
    setSelectedPlan(queryParams)

    let _lang = queryParams.lang ? queryParams.lang : localStorage.getItem('selectedValue')
    i18n.changeLanguage(_lang);

    async function fetchStripeKey() {

      let registeredCountry = await fetchRegisteredAdviser(queryParams.uid)
      console.log('registeredCountry::', registeredCountry)
      setRegisteredCountryCode(registeredCountry)

      // need do payment
      if (queryParams.PlanKey != 'PLAN_FREE') {
        const stripeKey = await domainName.getStripeKey(registeredCountry)

        setStripePromise(loadStripe(stripeKey))
        setRenderElements(false)
        getClientSecret(queryParams.uid, registeredCountry)
      }

    }

    fetchStripeKey()

    // setTimeout(() => {
    //   setIsLoading(false);
    // }, 3000);


  }, [cardUpdate])

  const handleOnCardUpdated = (data) => {
    // Process the data received from CheckoutForm
    console.log('Your card updated', data);
    setCardUpdate(data)
    // You can perform any additional actions with the data received here
  };


  return (
    <div className='greybk-div'>
      <LoadingModal />
      <Container>

        <Row className="justify-content-center align-items-cente mb-4">
          <Col className='col-lg-5 text-center'>
            <div className='mt-5'>
            </div>

            {/* remove the 60 days free trial - @ 29/9/23
            {!selectedPlan ? (
              <>
                <h3 className='fw-bold mt-5'>Start your free 60 days trial</h3>
                <div>No commitments. Cancel anytime.</div>
              </>
            ) :
              <div className='mt-5'>
              </div>
            } */}

          </Col>
        </Row>

        <Row>


          <Col className='col-lg-8 col-md-12 p-3'>

            {selectedPlan.PlanKey == 'PLAN_FREE' ?

              <div className='box-container p-5'>
                <h5 className='fw-bold mb-3'></h5>

                <Row>
                  <Col className='col-12'>{t('subscriptionStepThree.You are choosing Free Plan')}</Col>
                </Row>



                <Row className='mt-3'>

                  <Col className='mt-2 col-md-4 col-12'>
                    <Button className='orange-btn' onClick={proceedToChangePlan} >
                      {t('subscriptionStepThree.Subscribe now')}
                    </Button>
                  </Col>
                </Row>

              </div>

              :

              <div className='box-container p-5'>
                <h5 className='fw-bold mb-3'>{t('subscriptionStepThree.Payment Method')}</h5>

                {!isLoading && (
                  <>
                    {haveCard && (
                      <div>
                        <Row>
                          <Col className='col-md-3 col-12'>{t('subscriptionStepThree.Credit Card No.:')}</Col>
                          <Col className='col-md-9 col-12'>
                            {stripeCustomer ? '**** **** **** ' + stripeCustomer.last4 : null}
                            <Image src={stripeCustomer ? renderCCBrandIcon(stripeCustomer.brand) : null} style={{ width: '30px', marginLeft: '10px' }} />
                          </Col>
                        </Row>


                        <Row className='mt-2'>
                          <Col className='col-md-3 col-12'>{t('subscriptionStepThree.Card Expiration:')}</Col>
                          <Col className='col-md-9 col-12'> {stripeCustomer ? stripeCustomer.exp_month : null} / {stripeCustomer ? stripeCustomer.exp_year : null}
                          </Col>
                        </Row>


                        <Row className='mt-3'>
                          <Col className='mt-2 col-md-4 col-12'>
                            <Button className='orange-transparent-btn' onClick={changeCard}>
                              {t('subscriptionStepThree.Change another card')}
                            </Button>
                          </Col>

                          <Col className='mt-2 col-md-4 col-12'>
                            <Button className='orange-btn' onClick={proceedToChangePlan} >

                              {selectedPlan.changePlan == 0 && (<>{t('subscriptionStepThree.Subscribe now<')}</>)}
                              {selectedPlan.changePlan == 1 && (<>{t('subscriptionStepThree.Proceed to change plan')}</>)}
                              {/* {selectedPlan.changePlan == 2 && (<>Purchase plan</>)} */}
                              {(selectedPlan.changePlan != 0 && selectedPlan.changePlan != 1) && <>{t('subscriptionStepThree.Purchase plan')}</>}

                            </Button>
                          </Col>
                        </Row>


                      </div>
                    )}
                  </>
                )}

                {renderElements && (
                  <Elements stripe={stripePromise} options={stripeOption}>
                    <CheckoutForm uid={selectedPlan.uid}
                      planId={selectedPlan._id}
                      listing={selectedPlan.PassInListing}
                      referralCode={referralCode}
                      isUpdateCard={(!selectedPlan.changePlan || selectedPlan.changePlan == 0) ? false : true}
                      onCardUpdated={handleOnCardUpdated}
                      lang={selectedPlan.lang ? selectedPlan.lang : null} />
                  </Elements>
                )}

              </div>


            }



          </Col>


          <Col className='col-lg-4 col-md-12 p-3'>
            <div className='box-container p-5'>
              <h5 className='fw-bold mb-3'>{t('subscriptionStepThree.Summary')}</h5>

              <Row className='mb-2'>
                <Col>{selectedPlan.Title}</Col>
                <Col className='text-end'>{selectedPlan.Currency} {selectedPlan.OfferPrice}</Col>
              </Row>

              <Row className='mb-2'>
                <Col>{t('subscriptionStepThree.Listing')}</Col>
                <Col className='text-end'>{selectedPlan.PassInListing} {t('subscriptionStepThree.x listing')}</Col>
              </Row>

              {(!selectedPlan.changePlan || selectedPlan.changePlan == 0) && (
                <Row className='mb-2'>
                  <Col>{t('subscriptionStepThree.afterFreeTrialEnd')}</Col>
                  <Col className='text-end'>{selectedPlan.Currency} {parseFloat(parseFloat(selectedPlan.OfferPrice) * parseFloat(selectedPlan.PassInListing)).toFixed(2)} / {t('subscriptionStepThree.month')}</Col>
                </Row>
              )}


              <Row className='mt-4 mb-4 pt-3 pb-3' style={{ borderTop: '1px solid #D9D9D9', borderBottom: '1px solid #D9D9D9' }}>
                <Col>{t("subscriptionStepThree.Today's Total")}</Col>
                <Col className='text-end'>{selectedPlan.Currency} {(!selectedPlan.changePlan || selectedPlan.changePlan == 0) ? '0.00' : parseFloat(parseFloat(selectedPlan.OfferPrice) * parseFloat(selectedPlan.PassInListing)).toFixed(2)}</Col>
              </Row>

              {(!selectedPlan.changePlan || selectedPlan.changePlan == 0) && (
                <>
                  <Row className='mb-2'>
                    <Col>{t('subscriptionStepThree.Referral Code')}</Col>
                  </Row>

                  <Row className='mb-2'>
                    <Col className='col-8'>
                      <div className="input-group">
                        <input
                          type="text"
                          className="form-control"
                          placeholder={t("placeholder.Enter referral code")}
                          value={referralCode}
                          onChange={handleInputChange}
                          readOnly={inputReadOnly}
                        />
                        {referralCode && (
                          <button
                            className="btn btn-outline-secondary"
                            type="button"
                            onClick={handleClearReferralCode}
                          >
                            &times;
                          </button>
                        )}
                      </div>
                    </Col>
                    <Col className='col-4'>
                      <Button disabled={buttonDisabled} onClick={handleApplyReferralCode}
                        style={{
                          width: '120%',
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center'
                        }}>
                        {buttonText}
                      </Button>
                    </Col>
                  </Row>
                </>
              )}

            </div>
          </Col>
        </Row>


        <ConfirmChangePlanModal />
      </Container>

    </div>
  )

}

// bdvis23697

export default SubscriptionStepThree