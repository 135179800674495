import React from 'react'
import { useEffect, useState } from 'react';
import { Container, Row, Col, Button, Image } from 'react-bootstrap';
import domainName from '../../domainName';
import { useSelector } from 'react-redux';
import { COLORS } from '../../colorTheme/colorTheme';
import ReactStarRatings from 'react-star-ratings';
import { useNavigate } from 'react-router-dom';
import { SHA256 } from 'crypto-js';
import { useTranslation } from "react-i18next";


const MyProfile = () => {

    const { token, UserData, UserId } = useSelector((state) => state.login);

    const navigate = useNavigate();

    // user id which come from param
    const [targetUserId, setTargetUserId] = useState()

    // tab selection 
    const [tabPost, setTabPost] = useState(true);
    const [tabReviews, setTabReviews] = useState(false);

    // data retrieve from API
    const [profileImageDisplay, setProfileImageDisplay] = useState();
    const [followers, setFollowers] = useState();
    const [following, setFollowing] = useState();
    const [reviews, setReviews] = useState();
    const [profileName, setProfileName] = useState();
    const [canFollow, setCanFollow] = useState();
    const [canFollowMa, setCanFollowMa] = useState();
    const [isFollow, setIsFollow] = useState();

    const [serviceLevel, setServiceLevel] = useState();
    const [UserAwards, setUserAwards] = useState();
    const [verified, setVerified] = useState();

    const [canWhatsapp, setCanWhatsApp] = useState();

    const [whatsappLink, setWhatsAppLink] = useState();
    const [serviceNameList, setServiceNameDisplayList] = useState([]);

    //posts and reviews
    const [postListing, setPostListing] = useState([])
    const [receivedReviewListing, setReceivedReviewListing] = useState([])

    const [t] = useTranslation("global");


    async function getCommunityPosts(otherUserId) {
        await domainName
            .serverAPICall(
                domainName.debugAPI + "/user/userPostCommunityListing?PageNum=1&ResultNum=100",
                "GET",
                // JSON.stringify({
                //     "UserId": userState.UserId,
                //     "TargetUserId": userState.UserId
                // }),
                undefined,
                otherUserId != undefined ? otherUserId : UserId
            )
            .then(domainName.handleServerAPICallErrors)
            .then((responseJson => {
                //console.log('/user/userPostCommunityListing responseJson here', responseJson)
                setPostListing(responseJson.Data)

            }))
            .catch((error) => {
                //console.log('/user/userPostCommunityListing error here', error)
            });
    }

    async function getReviewsListings(otherUserId) {
        await domainName
            .serverAPICall(
                domainName.debugAPI + "/user/userReceivedReviewListing",
                "POST",
                JSON.stringify({
                    "UserId": otherUserId != undefined ? otherUserId : UserId,
                    //"ReviewerUserId": reviewUserId != undefined ? reviewUserId : null,
                    "ReviewerUserId": null,
                    "PageNum": 1,
                    "ResultNum": 100
                }),
                // userState.UserId, // user id, wait binglun settle backend
                //userState.latitude, // latitude
                //userState.longitude // longitude
            )
            .then(domainName.handleServerAPICallErrors)
            .then((responseJson => {
                //console.log('/user/userReceivedReviewListing : response : ', responseJson)
                setReceivedReviewListing(responseJson.Data.ReceivedReviewListing)

            }))
            .catch((error) => {
                //console.log('/user/userReceivedReviewListing : error : ', error)
            });
    }

    async function unfollow() {
        await domainName
            .serverAPICall(
                domainName.debugAPI + "/user/userUnFollowing",
                "POST",
                JSON.stringify({
                    "UserId": UserId,
                    "TargetUserId": targetUserId
                }),
                // userState.UserId, // user id, wait binglun settle backend
                //userState.latitude, // latitude
                //userState.longitude // longitude
            )
            .then(domainName.handleServerAPICallErrors)
            .then((responseJson => {
                //console.log('/user/userUnFollowing : response : ', responseJson)
                if (responseJson.ReturnCode == 200) {
                    setCanFollowMa(true)
                    setFollowers(prevState => prevState - 1);
                }
            }))
            .catch((error) => {
                //console.log('/user/userUnFollowing : error : ', error)
            });
    }

    async function follow() {
        await domainName
            .serverAPICall(
                domainName.debugAPI + "/user/userFollowing",
                "POST",
                JSON.stringify({
                    "UserId": UserId,
                    "TargetUserId": targetUserId
                }),
                // userState.UserId, // user id, wait binglun settle backend
                //userState.latitude, // latitude
                //userState.longitude // longitude
            )
            .then(domainName.handleServerAPICallErrors)
            .then((responseJson => {
                //console.log('/user/userFollowing : response : ', responseJson)
                //setCanFollowMa
                if (responseJson.ReturnCode == 200) {
                    setCanFollowMa(false)
                    setFollowers(prevState => prevState + 1);
                }
            }))
            .catch((error) => {
                //console.log('/user/userFollowing : error : ', error)
            });
    }



    async function getUserInfo(otherUserId) {
        await domainName
            .serverAPICall(
                domainName.debugAPI + "/user/selectUserInfoById",
                "POST",
                JSON.stringify({
                    "UserId": UserId,
                    "TargetUserId": otherUserId != undefined ? otherUserId : UserId
                })
            )
            .then(domainName.handleServerAPICallErrors)
            .then((responseJson => {
                //console.log('/user/selectUserInfoById responseJson here', responseJson)
                setProfileImageDisplay(responseJson.Data.UserImage)
                setFollowers(responseJson.Data.TotalFollower)
                setFollowing(responseJson.Data.TotalFollowing)
                setReviews(responseJson.Data.UserReviewListCount)
                setUserAwards(responseJson.Data.UserAwards)
                setVerified(responseJson.Data.IsVerify)
                setProfileName(responseJson.Data.UserName)
                setCanFollow(responseJson.Data.CanFollow)
                setCanFollowMa(responseJson.Data.CanFollow)
                setIsFollow(responseJson.Data.IsFollow)
                setCanWhatsApp(responseJson.Data.CanWhatsappsUser)
                if (responseJson.Data.CanWhatsappsUser) {
                    setWhatsAppLink(responseJson.Data.WhatappsUser)
                }
                setServiceNameDisplayList(responseJson.Data.ServiceNameDisplayList)
                setServiceLevel(responseJson.Data.ServiceLevel)
            }))
            .catch((error) => {
                //console.log('/user/selectUserInfoById error here', error)
            });
    }

    function renderReviewCard(reviewitem) {
        return (
            <div>
                <Row className="my-3">
                    <Col xs={1} md={1} lg={1}> <Image src={reviewitem.ReviewerImage} width={50} height={50} style={{ borderRadius: 50 }} /></Col>

                    <Col xs={2} md={2} lg={2}>{reviewitem.ReviewerName}</Col>
                    <Col className="text-end">{reviewitem.CreatedTimestampDisplay}</Col>
                </Row>
                <Row className="my-3">
                    <Col>
                        <ReactStarRatings
                            rating={reviewitem.Likes}  // Set the initial rating value
                            starRatedColor={COLORS.orange}  // Customize the color of the filled-in stars
                            numberOfStars={5}  // Set the total number of stars to display
                            starDimension="20px"  // Set the size of each star
                            starSpacing="1px"  // Set the spacing between each sta
                        />
                    </Col>
                </Row>
                <Row className="my-3">
                    <Col>{reviewitem.ReviewMessage}</Col>
                </Row>
            </div>
        )
    }


    function renderPostCard(postItem) {
        return (
            <div>
                <Row>
                    <Col xs={1} md={1} lg={1}>
                        {/* <Image src={postItem.UserImage} width={50} height={50} style={{ borderRadius: 50 }} /> */}
                        <img
                            src={postItem.UserImage || 'https://d2x9bdk6cd5di1.cloudfront.net/profile/no_image_1.png'}
                            onError={(e) => {
                                e.target.onerror = null; // Prevent infinite loop in case fallback also fails
                                e.target.src = 'https://d2x9bdk6cd5di1.cloudfront.net/profile/no_image_1.png'; // Fallback image
                            }}
                            style={{
                                width: '50px',
                                height: '50px',
                                borderRadius: 50,
                            }}
                        />
                    </Col>

                    <Col xs={8} md={3} lg={3}>
                        <Row>
                            <Col xs={12} md={12} lg={12} style={{ marginLeft: "10px" }} >
                                {postItem.UserName}
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} md={12} lg={12} style={{ marginLeft: "10px" }}>
                                {postItem.DisplayCreatedAt}
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} md={12} lg={12}>
                        {postItem.title}
                    </Col>
                </Row>
            </div>
        )
    }

    function renderTabContent() {
        return (
            <div>
                {tabPost ?
                    <div style={{ marginTop: '5%' }}>
                        {
                            postListing.length >= 1 ? postListing.map((item, index) => (
                                renderPostCard(item)
                            )) 
                            : <div style={{ backgroundColor: COLORS.lightGray4, textAlign: 'center', marginTop: '2%'}}>
                                <div style={{ paddingTop: '3%' }}>
                                    <img 
                                        src={require("../../img/icons/description.png")} 
                                        alt="My Review Icon" 
                                        style={{ mixBlendMode: 'multiply', width: '80px', height: 'auto' }}
                                    />
                                </div>
                                <div style={{ paddingTop: '2%', paddingBottom: '3%', color: COLORS.black }}>{t("MyProfile.Don't have any posts yet")}</div>
                              </div>  
                        }
                    </div>
                    :
                    <div style={{ marginTop: '5%' }}>
                        {
                            receivedReviewListing == "" ?
                                <div style={{ backgroundColor: COLORS.lightGray4, textAlign: 'center', marginTop: '2%'}}>
                                    <div style={{ paddingTop: '3%' }}>
                                        <img 
                                            src={require("../../img/icons/myreview.png")} 
                                            alt="My Review Icon" 
                                            style={{ mixBlendMode: 'multiply', width: '80px', height: 'auto' }}
                                        />
                                    </div>
                                    <div style={{ paddingTop: '2%', paddingBottom: '3%', color: COLORS.black }}>{t("MyProfile.Don't have any reviews yet")}</div>
                                </div>  
                                : receivedReviewListing.map((item, index) => (
                                    renderReviewCard(item)
                                ))
                        }
                    </div>
                }
            </div>
        )
    }

    function renderTabSelection() {
        return (
            <Row className='mt-3'>
                <Col xs={6} md={4} lg={3} className="d-flex">
                    <button type="button" class="input-group btn" style={{
                        backgroundColor: COLORS.white,
                        color: tabPost ? COLORS.orange : COLORS.black,
                        borderBottomWidth: tabPost ? 1 : 0,
                        borderBottomColor: COLORS.orange,
                        borderRadius: 0
                    }} onClick={() => { setTabPost(true); setTabReviews(false) }}>
                        {t("MyProfile.Post")}
                    </button>
                </Col>
                <Col xs={6} md={4} lg={3} className="d-flex">
                    <button type="button" class="input-group btn" style={{
                        backgroundColor: COLORS.white,
                        color: tabReviews ? COLORS.orange : COLORS.black,
                        borderBottomWidth: tabReviews ? 1 : 0,
                        borderBottomColor: COLORS.orange,
                        borderRadius: 0
                    }} onClick={() => { setTabPost(false); setTabReviews(true) }}>
                        {t("MyProfile.Reviews")}
                    </button>
                </Col>
            </Row>
        )
    }

    function greyLine() {
        return (
            <div
                style={{
                    borderBottom: '1px solid black',
                    borderColor: '#D9D9D9',
                    // margin: '10px 0',
                }}
            ></div>
        )
    }

    function renderFollowChatButton() {
        return (
            <Row className="mt-2">
                {
                    targetUserId &&
                    <Col xs={5} md={3} lg={4} className="d-flex justify-content-center">
                        <button type="button" class="input-group btn  w-100" style={{ backgroundColor: canFollowMa ? COLORS.orange : COLORS.lightGray2, color: canFollowMa ? COLORS.white : COLORS.black }} onClick={() => {
                            if (canFollowMa) {
                                follow()
                            } else {
                                unfollow()
                            }
                        }}>
                            {canFollowMa ? t("MyProfile.+ Follow") : t("MyProfile.Following")}
                        </button>
                    </Col>
                }

                {
                    targetUserId &&
                    <Col xs={5} md={3} lg={4} className="d-flex justify-content-center">
                        <button type="button" class="input-group btn  w-100" style={{ backgroundColor: COLORS.lightGray1, color: COLORS.black }} onClick={async () => {
                            let currentTime = new Date().getTime(); //epoch timestamp now
                            const resultEncrypted = SHA256(UserId + process.env.REACT_APP_COMMUNITY_APP_KEY + "Adviser2u" + currentTime)
                            navigate(`/message?userId=${UserId}&messageId=${targetUserId}&uid=${resultEncrypted}&tid=${currentTime}`)
                        }}>
                            {t("MyProfile.Chat")}
                        </button>
                    </Col>
                }
                {canWhatsapp && targetUserId &&

                    <Col xs={2} md={3} lg={2} className="d-flex justify-content-center">
                        <a href={whatsappLink}>
                            <Image src={require("../../img/icons/whatsapp.png")} width={30} height={30} />
                        </a>
                    </Col>


                }
            </Row>
        )
    }

    function renderNameAndInfo() {
        return (
            <div>
                <Row className="mt-2">
                    <h3>{profileName}</h3>
                </Row>
                <Row className="mt-2">

                    <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>

                        {
                            serviceLevel &&
                            <div style={{ marginRight: '2.5%' }}>
                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                    <Image src={require("../../img/icons/premier.png")} width={22} height={22} style={{ marginRight: '5%' }} />
                                    <span style={{ fontSize: 17 }}>{serviceLevel}</span>
                                </div>
                            </div>
                        }

                        {
                            verified &&
                            <div style={{ marginRight: '2.5%' }}>
                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                    <Image src={require("../../img/icons/verified2.png")} width={22} height={22} style={{ marginRight: '5%' }} />
                                    <span>{verified}</span>
                                </div>
                            </div>
                        }

                        {
                            UserAwards &&
                            <div style={{ marginRight: '2.5%' }}>
                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                    <Image src={require("../../img/icons/gold.png")} width={22} height={22} style={{ marginRight: '5%' }} />
                                    <span>{UserAwards}</span>
                                </div>
                            </div>
                        }




                    </div>
                </Row>
            </div>

        )
    }


    function renderTopHeader() {
        return (
            <Row className="mt-2">
                <Col xs={12} md={3} lg={2}>
                    {/* <Image src={profileImageDisplay} width={100} height={100} style={{ alignSelf: 'center', borderRadius: 100 }} /> */}
                    <img
                        src={profileImageDisplay || 'https://d2x9bdk6cd5di1.cloudfront.net/profile/no_image_1.png'}
                        onError={(e) => {
                            e.target.onerror = null; // Prevent infinite loop in case fallback also fails
                            e.target.src = 'https://d2x9bdk6cd5di1.cloudfront.net/profile/no_image_1.png'; // Fallback image
                        }}
                        style={{
                            width: '100px',
                            height: '100px',
                            borderRadius: 100,
                            alignSelf: 'center'
                        }}
                    />
                </Col>
                <Col xs={4} md={3} lg={2}>
                    <div>{followers}</div>
                    <div>{t("MyProfile.Followers")}</div>
                </Col>
                <Col xs={4} md={3} lg={2}>
                    <div>{following}</div>
                    <div>{t("MyProfile.Following")}</div>
                </Col>
                <Col xs={4} md={3} lg={2}>
                    <div>{reviews}</div>
                    <div>{t("MyProfile.Reviews")}</div>
                </Col>
            </Row>
        )
    }

    useEffect(() => {

        // Get the URLSearchParams object from the current URL
        const searchParams = new URLSearchParams(window.location.search);

        // Get the value of the 'serviceid' parameter
        const userId = searchParams.get('userId');

        // Do something with the serviceId value
        //console.log("userid in myprofile page", userId);

        if (userId != UserId) {
            setTargetUserId(userId)
        }

        getUserInfo(userId)
        getCommunityPosts(userId)
        getReviewsListings(userId)

    }, []);

    return (
        <div className="greybk-div p-lg-5" style={{ minheight: '100vh', height: 'auto' }}>
            <Container>
                <Row className="justify-content-center align-items-center">
                    <Col className="col-lg-12">
                        <Container className="box-container p-5">
                            <Row>
                                <Col>
                                    <h5 className="fw-bold">{t("MyProfile.My Profile")}</h5>
                                </Col>
                            </Row>

                            {renderTopHeader()}
                            {renderNameAndInfo()}
                            {renderFollowChatButton()}
                            <Row className='mt-5'>
                                {greyLine()}
                            </Row>
                            {renderTabSelection()}
                            {renderTabContent()}
                        </Container>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default MyProfile