import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useTranslation } from "react-i18next";
import queryString from 'query-string';
import { useLocation } from "react-router-dom";

const Terms = () => {
  const { pathname, search } = useLocation();
  const [t, i18n] = useTranslation("global");
  const [htmlContent, setHtmlContent] = useState('');

  useEffect(() => {
    console.log('useEffect triggered');
    let queryParam = queryString.parse(search);
    let _lang = queryParam.lang ? queryParam.lang : localStorage.getItem('selectedValue') || 'en';
    // let _lang = 'en';
    console.log('Selected language:', _lang);
    i18n.changeLanguage(_lang);

    // Load the HTML file based on the selected language
    fetch(`/lang/terms/${_lang}.html`)
      .then(response => {
        console.log('Fetch response:', response);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.text();
      })
      .then(data => {
        console.log('Fetched HTML content:', data);
        setHtmlContent(data);
      })
      .catch(error => console.error('Error loading HTML:', error));

    console.log('End of useEffect');
  }, [search, i18n]);

  return (
    <div className='greybk-div p-lg-5'>
      <Container className='box-container p-5' style={{height:"auto"}}>
        <Row>
          <Col>
            <div dangerouslySetInnerHTML={{ __html: htmlContent }}></div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Terms;
