import React, { useEffect, useState } from 'react';
import domainName from '../../domainName';
import { COLORS } from '../../colorTheme/colorTheme';
import { Container, Row, Col, Button, Modal, Tabs, Tab } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useTranslation } from "react-i18next";
import { useLocation } from 'react-router-dom';
import './myRequest.css';
import ConsumerPanel from '../../components/ConsumerPanel';

const MyRequest = () => {
    const { UserId } = useSelector((state) => state.login);
    const location = useLocation();
    const [t] = useTranslation("global");
    const [loading, setLoading] = useState(true);
    const [displayListing, setDisplayListing] = useState([]);
    const [totalListings, setTotalListings] = useState(0);
    const [wantDeleteItem, setWantDeleteItem] = useState(null);
    const [showDeleteRequest, setShowDeleteRequest] = useState(false);
    const [activeTab, setActiveTab] = useState('request');

    const statusMapping = {
        ACTIVE: { text: t('myRequest.Active'), backgroundColor: COLORS.lightGreen, textColor: COLORS.green2 },
        DELETE: { text: t('myRequest.Deleted'), backgroundColor: COLORS.lightOrange4, textColor: COLORS.orange2 },
        USER_WITHDRAWAL: { text: t('myRequest.UserWithdrawal'), backgroundColor: COLORS.lightOrange4, textColor: COLORS.orange2 },
        EXPIRED: { text: t('myRequest.Expired'), backgroundColor: COLORS.lightGray4, textColor: COLORS.gray4 },
    };

    const [showFullScreen, setShowFullScreen] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);

    const openFullScreen = (file) => {
        setSelectedImage(file);
        setShowFullScreen(true);
    };

    const closeFullScreen = () => {
        setSelectedImage(null);
        setShowFullScreen(false);
    };

    const fetchListings = async (tab) => {
        setLoading(true);
        const endpoint = tab === 'jobRequest'
            ? '/user_request/selectUserJobRequestListing'
            : '/user_request/selectUserRequestListing';

        await domainName
            .serverAPICall(
                domainName.debugAPI + endpoint,
                "POST",
                JSON.stringify({
                    "UserId": UserId,
                    "PageNum": 1,
                    "ResultNum": 10,
                })
            )
            .then(domainName.handleServerAPICallErrors)
            .then((responseJson) => {

                if (responseJson.Data != null) {
                    const newData = responseJson.Data || [];

                    setDisplayListing(newData);
                    setTotalListings(newData.length);
                } else {
                    alert(responseJson.ReturnDesc);
                }
            })
            .catch((error) => {
                console.error("Error fetching data:", error);
                alert(t("error.An error occurred. Please try again later."));
            })
            .finally(() => {
                setLoading(false);
            });
    };

    function greyLine() {
        return (
            <div
                style={{
                    borderBottom: '1px solid #D8D8D8',
                    marginTop: '5%',
                }}
            />
        );
    }

    const handleDeleteRequest = async () => {
        try {
            const endpoint = activeTab === 'jobRequest'
                ? '/user_request/deleteUserJobRequest'
                : '/user_request/deleteUserRequest';

            // console.log('endpoint', endpoint)
            // console.log('wantDeleteItem', wantDeleteItem)

            await domainName.serverAPICall(
                domainName.debugAPI + endpoint,
                "POST",
                JSON.stringify({
                    "UserId": wantDeleteItem.PosterUserId,
                    "RequestId": wantDeleteItem._id,
                    "DeleteInfo": "test"
                })
            );

            setShowDeleteRequest(false);
            fetchListings(activeTab); // Refresh listing
        } catch (error) {
            console.error("Error deleting request:", error);
        }
    };

    const renderNoBookings = () => (
        <div style={{ backgroundColor: COLORS.lightGray4, textAlign: 'center', marginTop: '2%' }}>
            <div style={{ paddingTop: '5%' }}>
                <img
                    src={require("../../img/icons/myrequest.png")}
                    alt="My Request Icon"
                    style={{ mixBlendMode: 'multiply', width: '70px', height: 'auto' }}
                />
            </div>
            <div style={{ fontSize: '1.2em', fontWeight: 'bold', color: COLORS.black, paddingTop: '3%', }}>
                {t("myRequest.No Requests Yet")}
            </div>
            <div style={{ fontSize: '1em', color: COLORS.gray, padding: '1%', paddingBottom: '5%' }}>
                {t("myRequest.No Requests Message")}
            </div>
        </div>
    );

    const renderRequestListings = (item) => {
        const currentStatus = statusMapping[item.Status] || { text: t('myRequest.Unknown'), backgroundColor: COLORS.lightGray4, textColor: COLORS.gray4 };

        return (
            <div key={item._id} style={{ marginTop: '2%', marginBottom: '1%', paddingLeft: "20px", paddingRight: "20px", paddingTop: "10px" }}>
                <Row className="my-3">

                    <Col>
                        <div style={{ fontSize: '1.1em', fontWeight: 'bold' }}>{item.RequestIdNo}</div>
                    </Col>
                    <Col className="d-flex justify-content-end">
                        <div
                            style={{
                                backgroundColor: currentStatus.backgroundColor,
                                color: currentStatus.textColor,
                                padding: '5px 10px',
                                borderRadius: '5px',
                                display: 'inline-block',
                                fontSize: '0.9em',
                                fontWeight: 'bold',
                            }}
                        >
                            {currentStatus.text}
                        </div>
                    </Col>
                </Row>

                <Row className="my-2">
                    <Col>
                        <div style={{ color: COLORS.gray, fontSize: '1em' }}>{item.DisplayDateTime}</div>
                    </Col>
                </Row>

                <Row className="my-3">
                    <Col>
                        <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                            <img
                                src={require('../../img/icons/categories.png')}
                                style={{ width: 25, height: 25, marginRight: 5 }}
                                alt="categories Icon"
                            />
                            <span style={{ color: COLORS.gray, fontSize: '1em' }}>
                                {item.CategoryDisplayList?.[0]?.CategoryName || 'N/A'} : {item.CategoryDisplayList?.[0]?.SubCateogrynameDisplay || 'N/A'}
                            </span>
                        </div>
                    </Col>
                </Row>

                <Row className="my-3">
                    <Col>
                        <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                            <img
                                src={require('../../img/icons/location.png')}
                                style={{ width: 25, height: 25, marginRight: 5 }}
                                alt="Location Icon"
                            />
                            <span style={{ color: COLORS.gray, fontSize: '1em' }}>
                                {item.DisplayLocation || 'N/A'}
                            </span>
                        </div>
                    </Col>
                </Row>

                <Row className="my-3">
                    <Col>
                        <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                            <img
                                src={require('../../img/icons/description.png')}
                                style={{ width: 25, height: 25, marginRight: 5 }}
                                alt="Description Icon"
                            />
                            <span style={{ color: COLORS.gray, fontSize: '1em' }}>
                                {item.ExtraInfo || 'N/A'}
                            </span>
                        </div>
                    </Col>
                </Row>

                <Row
                    style={{
                        paddingLeft: "10px",
                        paddingRight: "10px",
                        paddingTop: "4px",
                    }}
                >
                    <Button
                        variant="outline-danger"
                        onClick={() => {
                            setWantDeleteItem(item);
                            setShowDeleteRequest(true);
                        }}
                    >
                        {t('myRequest.Delete')}
                    </Button>
                </Row>

                {greyLine()}
            </div>
        );
    }

    const renderJobRequestListings = (item) => {
        const currentStatus = statusMapping[item.Status] || { text: t('myRequest.Unknown'), backgroundColor: COLORS.lightGray4, textColor: COLORS.gray4 };

        return (
            <div key={item._id} style={{ marginTop: '2%', marginBottom: '1%', paddingLeft: "20px", paddingRight: "20px", paddingTop: "10px" }}>

                <Row className="my-3">
                    <Col>
                        <div style={{ fontSize: '1.1em', fontWeight: 'bold' }}>{item.RequestIdNo}</div>
                    </Col>
                    <Col className="d-flex justify-content-end">
                        <div
                            style={{
                                backgroundColor: currentStatus.backgroundColor,
                                color: currentStatus.textColor,
                                padding: '5px 10px',
                                borderRadius: '5px',
                                display: 'inline-block',
                                fontSize: '0.9em',
                                fontWeight: 'bold',
                            }}
                        >
                            {currentStatus.text}
                        </div>
                    </Col>
                </Row>

                <Row className="my-2">
                    <Col>
                        <div style={{ color: COLORS.gray, fontSize: '1em' }}>{item.DisplayDateTime}</div>
                    </Col>
                </Row>

                <Row className="my-3">
                    <Col>
                        <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                            <img
                                src={require('../../img/icons/categories.png')}
                                style={{ width: 25, height: 25, marginRight: 5 }}
                                alt="Category Icon"
                            />
                            <span style={{ color: COLORS.gray, fontSize: '1em' }}>
                                {item.IndustryCategoryName || 'N/A'}
                            </span>
                        </div>
                    </Col>
                </Row>

                <Row className="my-3">
                    <Col>
                        <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                            <img
                                src={require('../../img/icons/location.png')}
                                style={{ width: 25, height: 25, marginRight: 5 }}
                                alt="Location Icon"
                            />
                            <span style={{ color: COLORS.gray, fontSize: '1em' }}>
                                {item.DisplayLocation || 'N/A'}
                            </span>
                        </div>
                    </Col>
                </Row>

                <Row className="my-3">
                    <Col>
                        <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                            <img
                                src={require('../../img/icons/description.png')}
                                style={{ width: 25, height: 25, marginRight: 5 }}
                                alt="Description Icon"
                            />
                            <span style={{ color: COLORS.gray, fontSize: '1em' }}>
                                {item.ExtraInfo || 'N/A'}
                            </span>
                        </div>
                    </Col>
                </Row>

                <Row className="my-3">
                    <Col>
                        {Array.isArray(item.ListingFileURIs) && item.ListingFileURIs.length > 0 ? (
                            <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                                <img
                                    src={require('../../img/icons/attachment.png')}
                                    style={{ width: 25, height: 25, marginRight: 5, filter: 'brightness(0.9)' }}
                                    alt="Attachment Icon"
                                />
                                <div style={{ display: 'flex', gap: '10px', flexWrap: 'wrap', marginTop: '10px' }}>
                                    {item.ListingFileURIs.map((file, index) => (
                                        <div key={index} className="fileCardStyle">
                                            {file.endsWith('.pdf') ? (
                                                <img
                                                    src={require('../../img/icons/pdf-icon.jpeg')}
                                                    alt="PDF Icon"
                                                    style={{ width: '100%', height: '100%', objectFit: 'cover', borderRadius: '5px' }}
                                                    onClick={() => window.open(file, '_blank')}
                                                />
                                            ) : (
                                                <img
                                                    src={file}
                                                    alt="Uploaded image"
                                                    style={{ width: '100%', height: '100%', objectFit: 'cover', borderRadius: '5px' }}
                                                    onClick={() => openFullScreen(file)}
                                                />
                                            )}
                                        </div>
                                    ))}
                                </div>
                            </div>
                        ) : (
                            null
                        )}
                    </Col>

                    {/* Fullscreen Modal */}
                    <Modal show={showFullScreen} onHide={closeFullScreen} centered>
                        <Modal.Body>
                            {selectedImage && (
                                <img
                                    src={selectedImage}
                                    alt="Image"
                                    style={{ width: '100%', maxHeight: '80vh' }}
                                />
                            )}
                        </Modal.Body>
                    </Modal>
                </Row>


                <Row
                    style={{
                        paddingLeft: "10px",
                        paddingRight: "10px",
                        paddingTop: "4px",
                    }}
                >
                    <Button
                        variant="outline-danger"
                        onClick={() => {
                            setWantDeleteItem(item);
                            setShowDeleteRequest(true);
                        }}
                    >
                        {t('myRequest.Delete')}
                    </Button>
                </Row>

                {greyLine()}
            </div>
        );
    }

    const DeleteRequestModal = () => (
        <Modal show={showDeleteRequest} onHide={() => setShowDeleteRequest(false)}>
            <Modal.Header closeButton>
                <Modal.Title>{t('myRequest.Delete Request')} {wantDeleteItem?.RequestIdNo}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row style={{ paddingLeft: '2%' }}>{t('myRequest.Are you sure you want to delete this request?')}</Row>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => setShowDeleteRequest(false)}>
                    {t('myRequest.Cancel')}
                </Button>
                <Button variant="primary" onClick={handleDeleteRequest}>
                    {t('myRequest.Delete')}
                </Button>
            </Modal.Footer>
        </Modal>
    );

    // useEffect(() => {
    //     // Set active tab based on passed state
    //     const initialTab = location.state?.activeTab || 'request';

    //     setActiveTab(initialTab);
    //     fetchListings(initialTab);
    // }, [location.state]);

    useEffect(() => {
        // Check if activeTab is passed via navigate state
        const stateTab = location.state?.activeTab;

        // console.log("stateTab:", stateTab);

        // Check if activeTab is present in the URL query params
        const searchParams = new URLSearchParams(window.location.search);
        const urlTab = searchParams.get('activeTab');

        // console.log("urlTab:", urlTab);

        // Determine the final activeTab value
        const finalTab = stateTab || urlTab || 'request';

        // console.log("Detected activeTab:", finalTab);

        // Set the active tab
        setActiveTab(finalTab);
        fetchListings(finalTab);
    }, []);

    return (
        <Container fluid style={{ backgroundColor: COLORS.lightGray4, padding: "20px" }}>
            <Row className="justify-content-center">
                {/* Left Panel */}
                <Col xs={12} lg={3} style={{ paddingTop: "20px" }} className="d-none d-lg-block" >
                    <div
                        style={{
                            backgroundColor: COLORS.white,
                            borderRadius: "8px",
                            padding: "20px",
                            boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
                            minHeight: "80vh",
                        }}
                    >
                        <ConsumerPanel
                            userId={UserId}
                            activePage="myRequest"
                        />
                    </div>
                </Col>

                {/* Right Panel */}
                <Col xs={12} lg={8} style={{ paddingTop: "20px" }}>
                    <div
                        style={{
                            backgroundColor: COLORS.white,
                            borderRadius: "5px",
                            boxShadow: "0 2px 5px rgba(0,0,0,0.1)",
                            minHeight: "90vh",
                            height: 'auto',
                            paddingTop: "5px",
                            paddingLeft: "15px",
                            paddingRight: "15px"
                        }}
                    >
                        <Row>
                            <Col className="p-2">
                                <div style={{ paddingLeft: "10px", paddingTop: "15px", paddingBottom: "10px", fontSize: "1.2em", fontWeight: 'bold' }}>{t('myRequest.My Requests')}</div>
                            </Col>
                        </Row>

                        <Row className="mt-3 g-0">
                            <Col xs={6} md={6} lg={6} className="d-flex">
                                <button
                                    type="button"
                                    className="input-group btn"
                                    style={{
                                        backgroundColor: COLORS.white,
                                        color: activeTab === 'request' ? COLORS.orange : COLORS.black,
                                        borderBottomWidth: activeTab === 'request' ? '2px' : '1px',
                                        borderBottomColor: activeTab === 'request' ? COLORS.orange : COLORS.lightGray1,
                                        borderStyle: 'solid',
                                        borderRadius: 0
                                    }}
                                    onClick={() => {
                                        setActiveTab('request');
                                        fetchListings('request');
                                    }}
                                >
                                    {t('myRequest.Request')}
                                </button>
                            </Col>
                            <Col xs={6} md={6} lg={6} className="d-flex">
                                <button
                                    type="button"
                                    className="input-group btn"
                                    style={{
                                        backgroundColor: COLORS.white,
                                        color: activeTab === 'jobRequest' ? COLORS.orange : COLORS.black,
                                        borderBottomWidth: activeTab === 'jobRequest' ? '2px' : '1px',
                                        borderBottomColor: activeTab === 'jobRequest' ? COLORS.orange : COLORS.lightGray1,
                                        borderStyle: 'solid',
                                        borderRadius: 0
                                    }}
                                    onClick={() => {
                                        setActiveTab('jobRequest');
                                        fetchListings('jobRequest');
                                    }}
                                >
                                    {t('myRequest.Job Request')}
                                </button>
                            </Col>
                        </Row>

                        {loading ? (
                            <div className="text-center my-5">
                                <div className="spinner-border text-danger" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </div>
                            </div>
                        ) : activeTab === 'request' ? (
                            displayListing.length === 0 ? renderNoBookings() : displayListing.map((item) => renderRequestListings(item))
                        ) : (
                            displayListing.length === 0 ? renderNoBookings() : displayListing.map((item) => renderJobRequestListings(item))
                        )}

                        <DeleteRequestModal />
                    </div>
                </Col>
            </Row>
        </Container>
    );
};

export default MyRequest;
