import React, { useEffect, useState } from 'react'
import domainName from '../../domainName'
import { useSelector } from 'react-redux';

import { Container, Row, Col, Image, Button } from 'react-bootstrap';
import { COLORS, SIZES, FONTS } from "../../colorTheme/colorTheme";
import { Link, useNavigate } from "react-router-dom";
import { SHA256 } from 'crypto-js';
import { useTranslation } from "react-i18next";
import AdvisorPanel from '../../components/AdvisorPanel';

const BookingsAdvisor = () => {

    const { UserData, UserId, latitude, longitude } = useSelector((state) => state.login);
    const advisorState = JSON.parse(localStorage.getItem('advisorState'));

    const navigate = useNavigate();

    const [displayListing, setDisplayListing] = useState();

    const [tabSelectionUpcoming, setTabSelectionUpcoming] = useState(true);
    const [tabSelectionCompleted, setTabSelectionCompleted] = useState(false)
    const [tabSelectionCancelled, setTabSelectionCancelled] = useState(false)

    const [showNothingMessage, setShowNothingMessage] = useState("upcoming")
    const [colorStatus, setColorStatus] = useState(COLORS.lightOrange1)
    const [t] = useTranslation("global");

    async function getBookingListing(bookinglistType) {
        await domainName
            .serverAPICall(
                domainName.debugAPI + "/user/selectUserBookingListing",
                "POST",
                JSON.stringify({
                    "UserId": UserId,
                    // "UserId":"6406ac6ad3ab42d2395e625b",
                    "BookingListType": bookinglistType,
                    "UserRole": "Advisor",
                    "PageNum": 1,
                    "ResultNum": 100
                }),
                // undefined, // user id, wait binglun settle backend
                // userState.latitude, // latitude
                // userState.longitude // longitude
            )
            .then(domainName.handleServerAPICallErrors)
            .then((responseJson => {

                //return data here
                // console.log("responseJson /user/selectUserBookingListing ->", responseJson);
                setDisplayListing(responseJson.Data)


            }))
            .catch((error) => {
                // console.log("error /user/selectUserBookingListing ->", error);
                // Alert.alert(
                //   "Oops!",
                //   "An error occurred. Please try again later.",
                //   [{ text: "OK", onPress: () => { } }],
                //   { cancelable: false }
                // );
            });
    }

    function renderBookingsAdvisor(item) {
        return (
            <div style={{
                flexDirection: 'column',
                // ...containerStyle
            }}>

                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    marginTop: '0%'
                }}>
                    <span style={{ color: COLORS.black, fontSize: '16px', fontWeight: 'bold' }}>{t('BookingsAdvisor.Booking No')}: {item.Booking_No}</span>
                    <span style={{ color: colorStatus }}>{item.DisplayStatus}</span>
                </div>

                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginTop: '2%'
                }}>
                    {/* Image */}
                    {/* <img
                        src={item.AdvisorData.ImageDisplay}
                        style={{ height: 35, width: 35 }}
                        alt="Booking Image"
                    /> */}

                    <div style={{
                        flex: 1,
                        flexDirection: 'column',
                        marginLeft: '2%'
                    }}>
                        <div onClick={() => { navigate(`/bookingDetailsAdvisor?bookingid=${item._id}`) }} style={{ cursor: 'pointer' }}>
                            <span style={{ color: COLORS.black, fontSize: '14px' }}>{item.ServiceName}</span>
                            <div style={{ display: 'flex', flexDirection: 'row', marginTop: '1%' }}>
                                <img
                                    src={require("../../img/icons/user.png")}
                                    style={{ width: 20, height: 20 }}
                                    alt="User Icon"
                                />
                                <span style={{ marginLeft: '2%', color: COLORS.black, fontSize: '14px' }}>{item.UserData.Name}</span>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'row', marginTop: '1%' }}>
                                <img
                                    src={require("../../img/icons/dateTime.png")}
                                    style={{ width: 20, height: 20 }}
                                    alt="Date and Time Icon"
                                />
                                <span style={{ marginLeft: '2%', color: COLORS.black, fontSize: '14px' }}>{item.DisplayDateTime}</span>
                            </div>
                            {item.DisplayLocation != undefined &&
                                <div style={{ display: 'flex', flexDirection: 'row', marginTop: '1%' }}>
                                    <img
                                        src={require("../../img/icons/location.png")}
                                        style={{ width: 20, height: 20 }}
                                        alt="Location Icon"
                                    />
                                    <span style={{ marginLeft: '2%', color: COLORS.black, fontSize: '14px' }}>{item.DisplayLocation}</span>
                                </div>
                            }
                        </div>

                        {/* Chat and WhatsApp button */}
                        <div style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            marginTop: '2%',
                            paddingBottom: '1%'
                        }}>
                            <button onClick={(e) => {
                                e.stopPropagation();
                                let currentTime = new Date().getTime(); //epoch timestamp now
                                const resultEncrypted = SHA256(UserId + process.env.REACT_APP_COMMUNITY_APP_KEY + "Adviser2u" + currentTime)
                                navigate(`/message?userId=${UserId}&messageId=${item.UserData._id}&uid=${resultEncrypted}&tid=${currentTime}`)

                            }} className="btn-white text-dark  fw-bold btn"
                                style={{ borderColor: COLORS.gray, width: '45%' }}><span style={{ color: '#444' }}>{t('BookingsAdvisor.Chat')}</span></button>
                            <button onClick={(e) => {
                                e.stopPropagation();
                                if (item.AdvisorData.CanWhatsapps) {
                                    window.open(item.AdvisorData.WhatappsLink);
                                } else {
                                    alert(t("error.Oops! This service provider didn't register WhatsApp"));
                                }
                            }} className="btn-white text-dark  fw-bold btn"
                                style={{ borderColor: COLORS.gray, width: '45%' }}><span style={{ color: '#444' }}>{t('BookingsAdvisor.WhatsApp')}</span></button>
                        </div>
                    </div>
                </div>

                {greyLine()}
            </div>
        )
    }

    function greyLine() {
        return (
            <div
                style={{
                    borderBottom: '1px solid #D8D8D8',
                    marginTop: '2%',
                }}
            />
        );
    }

    function renderNoBookings(title) {
        return (
            <div style={{
                marginHorizontal: SIZES.padding * 0.8,
                borderRadius: 10,
                backgroundColor: COLORS.lightGray4,
                marginTop: '2%',
                marginBottom: '2%',
                padding: '5%',
                // paddingTop: hp('5%'),
                // paddingBottom: hp('5%'),
                // paddingRight: wp('2%'),
                // paddingLeft: wp('2%'),
                justifyContent: 'center',
                alignItems: 'center',
                alignSelf: 'center',
                alignContent: 'center',
                textAlign: 'center'
            }}>
                <div><img src={require("../../img/icons/bookingImage.png")} /></div>
                <div style={{ paddingTop: '2%'}}> 
                    <span>{t("BookingsAdvisor.You don't have any")} {title} {t("BookingsAdvisor.bookings yet")}</span>
                </div>
            </div>
        )
    }

    function renderTabSelection() {
        return (
            <Row className='mt-3'>
                <Col xs={4} md={4} lg={4} className="d-flex">
                    <button
                        type='button'
                        class="input-group btn"
                        style={{
                            backgroundColor: COLORS.white,
                            color: tabSelectionUpcoming ? COLORS.orange : COLORS.black,
                            borderBottomWidth: tabSelectionUpcoming ? 1 : 0,
                            borderBottomColor: COLORS.orange,
                            borderRadius: 0
                        }}
                        onClick={() => {
                            getBookingListing("Upcoming")

                            setTabSelectionUpcoming(true)
                            setTabSelectionCompleted(false)
                            setTabSelectionCancelled(false)

                            setShowNothingMessage("upcoming")
                            setColorStatus(COLORS.lightOrange1)
                        }}> {t('BookingsAdvisor.Upcoming')}
                    </button>
                </Col>
                <Col xs={4} md={4} lg={4} className="d-flex">
                    <button
                        type='button'
                        class="input-group btn"
                        style={{
                            backgroundColor: COLORS.white,
                            color: tabSelectionCompleted ? COLORS.orange : COLORS.black,
                            borderBottomWidth: tabSelectionCompleted ? 1 : 0,
                            borderBottomColor: COLORS.orange,
                            borderRadius: 0
                        }}
                        onClick={() => {
                            getBookingListing("Completed")

                            setTabSelectionUpcoming(false)
                            setTabSelectionCompleted(true)
                            setTabSelectionCancelled(false)

                            setShowNothingMessage("completed")
                            setColorStatus(COLORS.green)
                        }}>
                        {t('BookingsAdvisor.Completed')}
                    </button>
                </Col>
                <Col xs={4} md={4} lg={4} className="d-flex">
                    <button
                        type='button'
                        class="input-group btn"
                        style={{
                            backgroundColor: COLORS.white,
                            color: tabSelectionCancelled ? COLORS.orange : COLORS.black,
                            borderBottomWidth: tabSelectionCancelled ? 1 : 0,
                            borderBottomColor: COLORS.orange,
                            borderRadius: 0
                        }}
                        onClick={() => {
                            getBookingListing("Cancelled")

                            setTabSelectionUpcoming(false)
                            setTabSelectionCompleted(false)
                            setTabSelectionCancelled(true)

                            setShowNothingMessage("cancelled")
                            setColorStatus(COLORS.gray)
                        }}>
                       {t('BookingsAdvisor.Cancelled')}
                    </button>
                </Col>
            </Row>
        )
    }

    useEffect(() => {
        getBookingListing("Upcoming");
    }, [])

    return (
        <Container fluid style={{ backgroundColor: COLORS.lightGray4, padding: "20px" }}>
            <Row className="justify-content-center">
                {/* Left Panel */}
                <Col xs={12} lg={3} style={{ paddingTop: "20px" }} className="d-none d-lg-block" >
                    <div
                        style={{
                            backgroundColor: COLORS.white,
                            borderRadius: "8px",
                            padding: "20px",
                            boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
                            minHeight: "80vh",
                        }}
                    >
                        {advisorState.advisor == true && (
                            <AdvisorPanel
                                userId={UserId}
                                activePage="bookingsAdvisor"
                            />
                        )}
                        {advisorState.advisor == false && (
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "center", 
                                    alignItems: "center",   
                                    height: "80vh",      
                                    textAlign: "center",   
                                }}
                            >
                                {t("subscriptionStepOne.Sign up now to become an Adviser!")}
                            </div>
                        )}
                    </div>
                </Col>
    
                {/* Right Panel */}
                <Col xs={12} lg={8} style={{ paddingTop: "20px" }}>
                    <div
                        style={{
                            backgroundColor: COLORS.white,
                            borderRadius: "5px",
                            boxShadow: "0 2px 5px rgba(0,0,0,0.1)",
                            minHeight: "90vh",
                        }}
                    >
                        <Row>
                            <Col className="p-2">
                                <div style={{ paddingLeft: "1.2em", paddingTop: "1em", fontSize: "1.2em", fontWeight: 'bold'}}>{t('BookingsAdvisor.Bookings')}</div>
                            </Col>
                        </Row>
                        {renderTabSelection()}
                        {displayListing == "" ? <div>{renderNoBookings(showNothingMessage)}</div>
                            : displayListing?.map((item, index) => (
                                <div style={{ paddingLeft: '2%', paddingRight: '2%', paddingTop: '2%' }}>
                                    {/* <BookingListCard 
                                    item={item} 
                                    imageStyle={{
                                        backgroundColor: COLORS.white,
                                            borderRadius: 10,
                                            height: 100,
                                            width: 100,
                                            resizeMode: 'contain'
                                        }} 
                                        statusColor={colorStatus}
                                        navigation={`/bookingDetailsUser?bookingid=${item._id}`}
                                        /> */}
                                        
                                    {renderBookingsAdvisor(item)}

                                </div>
                            ))}
                    </div>
                </Col>
            </Row>
        </Container>
    );
}

export default BookingsAdvisor