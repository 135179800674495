import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import domainName from '../../domainName';
import downloadIcon from '../../img/download.png';
import { useTranslation } from "react-i18next";

import 'datatables.net-dt/css/jquery.dataTables.css';
import 'datatables.net';
import $ from 'jquery';



const PaymentHistory = () => {

  const { token, UserData, UserId } = useSelector((state) => state.login);
  const [subscribeHistoryData, setSubscribeHistoryData] = useState([])
  const [t] = useTranslation("global");

  const getPaymentHistory = async () => {

    // Get the URLSearchParams object from the current URL
    const searchParams = new URLSearchParams(window.location.search);

    // check if have url querystring
    const userid = searchParams.get('userId');

    let uid = UserId

    if (userid != null && userid != '') {
      uid = userid
    }

    await domainName
      .serverAPICall(
        domainName.debugAPI + `/adviser_plan/getThePaymentHisotryByUserId?UserId=${uid}`,
        "GET"
      )
      .then(domainName.handleServerAPICallErrors)
      .then((responseJson => {
        // console.log('responseJson:: ', responseJson)
        setSubscribeHistoryData(responseJson.Data)
      }))
      .catch((error) => {
        alert(
          t("error.Oops!"),
          t("error.An error occurred. Please try again later."),
          error
        );
      });

  }

  useEffect(() => {
    getPaymentHistory();
  }, []);

  useEffect(() => {
    if (subscribeHistoryData.length > 0) {
      if ($.fn.DataTable.isDataTable('#paymentTable')) {
        $('#paymentTable').DataTable().destroy(); // Destroy existing DataTable instance if it exists
      }
      $('#paymentTable').DataTable({
        data: subscribeHistoryData.map(item => [item.SubscribeHistory.DisplayCreatedAt, item.SubscribeHistory.DisplayDesc, item.SubscribeHistory.TotalSubscribeCost, item.SubscribeHistory.DisplayStatus, item.SubscribeHistory.DisplayInvoiceUrl]),
        columnDefs: [
          {
            targets: 3, // Index of the 'displayStatus' column
            render: function (data) {
              // Conditionally set the background color to green for 'Successful' and red for other values
              if (data === 'Successful') {
                return '<div style="color: green;">' + data + '</div>';
              } else {
                return '<div style="color: red;">' + data + '</div>';
              }
            },
          },

          {
            targets: 4, // Index of the 'invoice' column
            render: function (data) {
              if (data != null) {
                return `<a href=${data}><img
                src=${downloadIcon}
                alt="Download Invoice"
              />  </a>`;
              } else {
                return '-'
              }

            },
          },
        ],
        language: {
          paginate: {
            previous: '<',
            next: '>',
            first: '<<',
            last: '>>',
          },
          // Custom styles for pagination
          oClasses: {
            sPageButton: 'paginate_button orange',
            sPageButtonActive: 'paginate_button_active',
            sPageButtonDisabled: 'paginate_button_disabled',
          },
        },
      });
      $('.paginate_button.current').css({
        backgroundColor: 'white', //'orange',
        color: 'grey', //'white',
        // Add any other desired styles
      });

    }
  }, [subscribeHistoryData]);


  return (
    <div style={{ overflowX: 'auto' }}>

      {(subscribeHistoryData != [] && subscribeHistoryData != null) && (
        <table id="paymentTable" className="table" style={{ width: '100%', maxWidth: '100%', paddingTop: '1em' }}>
          <thead>
            <tr>
              <th style={{ background: '#F0F1F3', color: '#5B5D6B' }}>{t('subscription.DATE')}</th>
              <th style={{ background: '#F0F1F3', color: '#5B5D6B' }}>{t('subscription.DESCRIPTION')}</th>
              <th style={{ background: '#F0F1F3', color: '#5B5D6B' }}>{t('subscription.AMOUNT')}</th>
              <th style={{ background: '#F0F1F3', color: '#5B5D6B' }}>{t('subscription.STATUS')}</th>
              <th style={{ background: '#F0F1F3', color: '#5B5D6B', textAlign: 'center' }}>{t('subscription.INVOICE')}</th>
            </tr>
          </thead>

          <tbody>
            {/* 
            {subscribeHistoryData != null && subscribeHistoryData.map((item, index) => (
              item.SubscribeHistory.SubscribeCost > 0 &&
              <tr key={index}>
                <td>{item.SubscribeHistory.DisplayCreatedAt}</td>
                <td>{item.SubscribeHistory.DisplayDesc}</td>
                <td>{item.SubscribeHistory.TotalSubscribeCost}</td>
                <td style={{ color: item.SubscribeHistory.DisplayStatus === 'Successful' ? 'green' : 'red' }}>
                  {item.SubscribeHistory.DisplayStatus}
                </td>
                <td style={{ textAlign: 'center' }}>
                  
                  
                  <a href={item.SubscribeHistory.DisplayInvoiceUrl}><img
                  src={downloadIcon}
                  alt="Download Invoice"
                />  </a>
                
                
                </td>
              </tr>
            ))} */}

          </tbody>


        </table>
      )}



    </div>
  );
};



export default PaymentHistory;
