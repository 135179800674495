import React from 'react'
import domainName from '../domainName'
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Container, Row, Col, Button, NavDropdown, Modal, Image } from 'react-bootstrap';
import { COLORS, FONTS, SIZES } from '../colorTheme/colorTheme';
import BookingListCard from '../components/BookingListCard';
import { useTranslation } from "react-i18next";
import ConsumerPanel from '../components/ConsumerPanel';

const MyBookings = () => {

    const { token, UserData, UserId } = useSelector((state) => state.login);

    const [displayListing, setDisplayListing] = useState([])

    const [tabSelectionUpcoming, setTabSelectionUpcoming] = useState(true);
    const [tabSelectionCompleted, setTabSelectionCompleted] = useState(false)
    const [tabSelectionCancelled, setTabSelectionCancelled] = useState(false)

    const [showNothingMessage, setShowNothingMessage] = useState("upcoming")
    const [colorStatus, setColorStatus] = useState(COLORS.lightOrange1)

    const [t] = useTranslation("global");


    async function getBookingListing(bookinglistType) {
        await domainName
            .serverAPICall(
                domainName.debugAPI + "/user/selectUserBookingListing",
                "POST",
                JSON.stringify({
                    // "UserId": userState.UserId,
                    // "BookingListType": bookinglistType,
                    // "UserRole": "Advisor",
                    // "PageNum": 1,
                    // "ResultNum": 10
                    "UserId": UserId,
                    "BookingListType": bookinglistType,
                    "UserRole": "User",
                    "PageNum": 1,
                    "ResultNum": 100
                }),
                // undefined, // user id, wait binglun settle backend
                // userState.latitude, // latitude
                // userState.longitude // longitude
            )
            .then(domainName.handleServerAPICallErrors)
            .then((responseJson => {

                //return data here
                //console.log("responseJson /user/selectUserBookingListing ->", responseJson);
                setDisplayListing(responseJson.Data)


            }))
            .catch((error) => {
                //console.log("error /user/selectUserBookingListing ->", error);
                // Alert.alert(
                //   "Oops!",
                //   "An error occurred. Please try again later.",
                //   [{ text: "OK", onPress: () => { } }],
                //   { cancelable: false }
                // );
            });
    }

    function renderNoBookings(title) {
        return (
            <div
                style={{
                    backgroundColor: COLORS.lightGray4,
                    textAlign: 'center',
                    marginTop: '2%',
                    minHeight: '30vh',
                    display: 'flex', 
                    flexDirection: 'column', 
                    justifyContent: 'center', 
                    alignItems: 'center', 
                }}
            >
                <div>
                    <img
                        src={require("../img/icons/bookingImage.png")}
                        alt="Booking"
                    />
                </div>
                <div style={{ paddingTop: '2%' }}>
                    <span>{t("MyBookings.You don't have any")} {title} {t('MyBookings.bookings yet')}</span>
                </div>
            </div>         
        )
    }


    function renderList(item) {
        return (
            <Row className='mt-3'>
                <div style={{
                    backgroundColor: COLORS.lightOrange,
                    padding: '3%'
                }}>
                    {JSON.stringify(item)}
                </div>
            </Row>
        )

    }

    function renderTabSelection() {
        return (
            <Row className='mt-3'>
                <Col xs={4} md={4} lg={4} className="d-flex">
                    <button
                        type='button'
                        class="input-group btn"
                        style={{
                            backgroundColor: COLORS.white,
                            color: tabSelectionUpcoming ? COLORS.orange : COLORS.black,
                            borderBottomWidth: tabSelectionUpcoming ? 1 : 0,
                            borderBottomColor: COLORS.orange,
                            borderRadius: 0
                        }}
                        onClick={() => {
                            getBookingListing("Upcoming")

                            setTabSelectionUpcoming(true)
                            setTabSelectionCompleted(false)
                            setTabSelectionCancelled(false)

                            setShowNothingMessage("upcoming")
                            setColorStatus(COLORS.lightOrange1)
                        }}> {t('MyBookings.Upcoming')}
                    </button>
                </Col>
                <Col xs={4} md={4} lg={4} className="d-flex">
                    <button
                        type='button'
                        class="input-group btn"
                        style={{
                            backgroundColor: COLORS.white,
                            color: tabSelectionCompleted ? COLORS.orange : COLORS.black,
                            borderBottomWidth: tabSelectionCompleted ? 1 : 0,
                            borderBottomColor: COLORS.orange,
                            borderRadius: 0
                        }}
                        onClick={() => {
                            getBookingListing("Completed")

                            setTabSelectionUpcoming(false)
                            setTabSelectionCompleted(true)
                            setTabSelectionCancelled(false)

                            setShowNothingMessage("completed")
                            setColorStatus(COLORS.green)
                        }}>
                       {t('MyBookings.Completed')}
                    </button>
                </Col>
                <Col xs={4} md={4} lg={4} className="d-flex">
                    <button
                        type='button'
                        class="input-group btn"
                        style={{
                            backgroundColor: COLORS.white,
                            color: tabSelectionCancelled ? COLORS.orange : COLORS.black,
                            borderBottomWidth: tabSelectionCancelled ? 1 : 0,
                            borderBottomColor: COLORS.orange,
                            borderRadius: 0
                        }}
                        onClick={() => {
                            getBookingListing("Cancelled")

                            setTabSelectionUpcoming(false)
                            setTabSelectionCompleted(false)
                            setTabSelectionCancelled(true)

                            setShowNothingMessage("cancelled")
                            setColorStatus(COLORS.gray)
                        }}>
                        {t('MyBookings.Cancelled')}
                    </button>
                </Col>
            </Row>
        )
    }



    useEffect(() => {
        getBookingListing("Upcoming")
    }, [])

    return (
        <Container fluid style={{ backgroundColor: COLORS.lightGray4, padding: "20px" }}>
            <Row className="justify-content-center">
                {/* Left Panel */}
                <Col xs={12} lg={3} style={{ paddingTop: "20px" }} className="d-none d-lg-block" >
                    <div
                        style={{
                            backgroundColor: COLORS.white,
                            borderRadius: "8px",
                            padding: "20px",
                            boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
                            minHeight: "80vh",
                        }}
                    >
                            <ConsumerPanel
                                userId={UserId}
                                activePage="mybookings"
                            />
                    </div>
                </Col>
    
                {/* Right Panel */}
                <Col xs={12} lg={8} style={{ paddingTop: "20px" }}>
                    <div
                        style={{
                            backgroundColor: COLORS.white,
                            borderRadius: "5px",
                            boxShadow: "0 2px 5px rgba(0,0,0,0.1)",
                            minHeight: "90vh",
                            height: 'auto',
                            paddingTop: "5px",
                            paddingLeft: "15px",
                            paddingRight: "15px"  
                        }}
                    >
                        <Row>
                            <Col className="p-2">
                                <div style={{ paddingLeft: "10px", paddingTop: "15px", paddingBottom: "10px", fontSize: "1.2em", fontWeight: 'bold'}}>{t('MyBookings.My Bookings')}</div>
                            </Col>
                        </Row>

                        {renderTabSelection()}
                        {displayListing == "" ? <div>{renderNoBookings(showNothingMessage)}</div>
                            : displayListing.map((item, index) => (
                                <div style={{paddingLeft: '2%', paddingRight: '2%', paddingTop: '2%' }}>
                                    <BookingListCard 
                                        item={item} 
                                        imageStyle={{
                                            backgroundColor: COLORS.white,
                                            borderRadius: 10,
                                            height: 100,
                                            width: 100,
                                            resizeMode: 'contain'
                                        }} 
                                        statusColor={colorStatus}
                                        navigation={`/bookingDetailsUser?bookingid=${item._id}`}
                                    />
                                </div>
                            ))}
                    </div>
                </Col>
            </Row>
        </Container>
    );
}

export default MyBookings